import React, { useEffect, useState } from 'react';
import { iRoleData } from 'types';
import { getUserRoles } from 'apis';
import styles from './rolesTable.module.scss';

interface iProps {
  className?: string;
}

function RolesTable(props: iProps): JSX.Element {
  const { className } = props;
  const [roles, setRoles] = useState<Array<iRoleData>>();

  useEffect(() => {
    (async () => {
      const response = await getUserRoles();
      if (response) {
        setRoles(response);
      }
    })();
  }, []);
  return (
    <div className={`${styles.rolesTable} ${className}`}>
      <div className={styles.tableHead}>
        <p className={styles.roleName}>Name</p>
        <p className={styles.lastUpdated}>Last Updated</p>
      </div>
      <div className={styles.tableBody}>
        {roles &&
          roles.map((role) => (
            <div className={styles.row}>
              <p className={styles.nameValue}>
                {role.role_name.split('_').join(' ')}
              </p>
              <p className={styles.lastUpdatedValue}>Last Updated</p>
            </div>
          ))}
      </div>
    </div>
  );
}

RolesTable.defaultProps = {
  className: '',
};

export default RolesTable;
