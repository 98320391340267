import AWS from 'aws-sdk';
// import { toaster } from 'helpers';

const AWS_ACCESS_KEY_ID_ENV = 'AKIASMEQVXCPDFOZ2VAQ';
const AWS_SECRET_ACCESS_KEY_ENV = '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';
const REGION = 'eu-west-1';
const BUCKET_NAME = 'screenz-io-dev';
// const REGION = 'eu-west-1 I.e ireland';

AWS.config.update({
  region: REGION,
  secretAccessKey: AWS_SECRET_ACCESS_KEY_ENV,
  accessKeyId: AWS_ACCESS_KEY_ID_ENV,
});

const s3 = new AWS.S3();

export const s3Operations = {
  getObject: (params?: any) => {
    return new Promise((resolve, reject) => {
      s3.getObject({ ...params, Bucket: BUCKET_NAME }, (error, data) => {
        if (error) reject(error.stack);
        else {
          resolve(data);
        }
      });
    });
  },
  getObjects: (params?: any) => {
    return new Promise((resolve, reject) => {
      s3.listObjectsV2({ ...params, Bucket: BUCKET_NAME }, (err, data) => {
        if (err) reject(err.stack);
        else {
          resolve(data);
        }
      });
    });
  },
  upload: ({
    params,
    progress,
    companyId,
    userId,
    uploadType,
    contentType,
  }: {
    params: { Key: string; Body: any };
    progress?: (progress: number) => void;
    companyId?: string;
    userId?: string;
    contentType?: string;
    uploadType?:
      | 'profile'
      | 'content'
      | 'profileThumbnail'
      | 'contentThumbnail'
      | 'emptyStatesBannerImg';
  }) => {
    let Key = params.Key.split(' ').join('_').toLocaleLowerCase();
    // if (uploadType === 'content' || uploadType === 'contentThumbnail') {
    //   if (!companyId) {
    //     toaster.error('Company Id required');
    //     return;
    //   }
    // }

    // if (uploadType === 'profile' || uploadType === 'profileThumbnail') {
    //   if (!userId) {
    //     toaster.error('User Id required');
    //     return;
    //   }
    // }
    // if (uploadValidation({ uploadType, userId, companyId })) return;
    if (uploadType === 'profile') Key = `profile/${userId}/profiles/${Key}`;
    if (uploadType === 'profileThumbnail')
      Key = `profile/${userId}/thumbnails/${Key}`;

    if (uploadType === 'content') Key = `contents/${companyId}/${Key}`;
    if (uploadType === 'contentThumbnail')
      Key = `contents/${companyId}/thumbnails${Key}`;
    if (uploadType === 'emptyStatesBannerImg') Key = `app_assets/${Key}`;

    return new Promise((resolve, reject) => {
      s3.upload(
        {
          Bucket: BUCKET_NAME,
          ...params,
          Key,
          // ContentType: 'image/jpeg',
          ContentType: contentType || params.Body.mimetype,
        },
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        }
      ).on('httpUploadProgress', ({ loaded, total }) => {
        if (progress) progress(Math.round((100 * loaded) / total));
        // console.log(`${Math.round((100 * loaded) / total)}`);
      });
    });
  },
  delete: (params: { Key: string }) => {
    const S3URLS = 'https://screenz-io-dev.s3.eu-west-1.amazonaws.com/';
    return new Promise((resolve, reject) => {
      s3.deleteObject(
        {
          Bucket: BUCKET_NAME,
          Key: params.Key.replace(S3URLS, ''),
        },
        (error) => {
          if (error) {
            reject(error);
          } else {
            resolve(true);
          }
        }
      );
    });
  },
};
