import Button, { ButtonVariants } from 'components/button/button';
import { clockIcon, whiteAddCircle } from 'constants/index';
import { LayoutViews, ModalViews } from 'enums';
import { calculateContentRuntime } from 'helpers';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import styles from './subheader.module.scss';

interface iProps {
  className?: string;
}

function Subheader(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { chosenView } = state.layout || {};
  const { playlistData } = state.playlistsPage || {};

  const changeView = useCallback(
    (view: LayoutViews) => {
      dispatch(layoutActions.setHomePageView(view));
    },
    [dispatch]
  );
  const contents = playlistData?.playListContents || [];

  return (
    <div className={`${className} ${styles.screenDetailsSubHeader}`}>
      <div className={styles.colOne}>
        <Button
          btnName="Content"
          isActive={chosenView === LayoutViews.Playlist}
          className={styles.changeViewBtn}
          variant={ButtonVariants.ChangeView}
          onClick={() => changeView(LayoutViews.Playlist)}
        />
        <Button
          btnName="Details"
          isActive={chosenView === LayoutViews.PlaylistDetails}
          className={styles.changeViewBtn}
          variant={ButtonVariants.ChangeView}
          onClick={() => changeView(LayoutViews.PlaylistDetails)}
        />
        <img className={styles.clockIcon} src={clockIcon} alt="" />
        <span className={styles.playLength}>Play length:</span>
        <span className={styles.duration}>
          {/* {playlistData?.duration || `00:${minutes || ''}:${seconds || '00'}`} */}
          {calculateContentRuntime(contents)}
        </span>
      </div>
      <div className={styles.colTwo}>
        <Button
          btnName="Content"
          icon={whiteAddCircle}
          iconPosition="left"
          className={`${styles.addContentBtn} ${styles[chosenView]}`}
          variant={ButtonVariants.SmallStd}
          // onClick={() => dispatch(layoutActions.toggleAddContentToPlaylist())}
          onClick={() =>
            dispatch(
              layoutActions.toggleModal(
                ModalViews.isShowAssignContentOrPlaylist
              )
            )
          }
        />
      </div>
    </div>
  );
}

Subheader.defaultProps = {
  className: '',
};

export default Subheader;
