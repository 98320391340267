import RunningStatus from 'components/assign-content-modal/running-state/runningState';
import Checkbox from 'components/checkbox/checkbox';
import Screen from 'components/svg/screenz';
import {
  playlistsIcon,
  dustbinIcon,
  sortIcon,
  timerIcon /* activeFolderIcon */,
} from 'constants/index';
import More from 'components/svg/more';
import Popover from 'components/popover/popover';
import { getDurationInTimeFormat } from 'helpers';
import React, { useCallback } from 'react';
import { useSortBy, useTable } from 'react-table';
import { useStore } from 'store';
import { homePageActions, layoutActions } from 'store/actions';
import { ModalViews } from 'enums';
// import { playlistPageActions } from 'store/actions';
import { iPlaylistData, iState } from 'types';
import styles from './playlistTable.module.scss';

interface iProps {
  className?: string;
  isShowCheckBox?: boolean;
  // fullHeight?: boolean;
  columns?: any[];
  data: iPlaylistData[];
  onClick?: (data: iPlaylistData) => void;
  setDataToDelete?: (data: iPlaylistData) => void;
}

function PlaylistsTable(props: iProps): JSX.Element {
  const {
    className,
    isShowCheckBox = true,
    // fullHeight,
    columns,
    data,
    onClick,
    setDataToDelete,
  } = props;

  // const windowHeight = window.innerHeight;
  const [state, dispatch]: [iState, any] = useStore();
  const { contentToAddToPlaylist } = state.playlistsPage || {};
  const { playlistToAssignScreen } = state.homePage || {};
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  const firstPageRows = rows.slice(0, (data && data?.length) || 20);

  const onClickRow = useCallback(
    (row) => {
      dispatch(homePageActions.setPlaylistToAssignToScreen(row.id));
      dispatch(homePageActions.setContentToAssignToScreen(''));
      if (onClick) onClick(row);
    },
    [onClick, dispatch]
  );

  const onClickDelete = useCallback(
    (cell) => {
      dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteModal));
      if (setDataToDelete) setDataToDelete(cell.row.original);
    },
    [setDataToDelete, dispatch]
  );

  return (
    <table {...getTableProps()} className={`${styles.tableHead} ${className}`}>
      <thead>
        {headerGroups.map((headerGroup) => {
          return (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const { id } = column || {};
                const showTimerIcon = id === 'duration';
                const showSortIcon = id !== 'name';
                const isMore = id === 'more';
                console.log('column____', column);

                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={isMore && styles.hideSort}
                  >
                    {showTimerIcon && (
                      <img
                        className={styles.timerIcon}
                        src={timerIcon}
                        alt=""
                      />
                    )}
                    {column.render('Header')}
                    {showSortIcon && (
                      <img className={styles.sortIcon} src={sortIcon} alt="" />
                    )}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody
        {...getTableBodyProps()}
        // style={
        //   (fullHeight && {
        //     maxHeight: windowHeight - 295,
        //   }) ||
        //   {}
        // }
      >
        {firstPageRows.map((row) => {
          prepareRow(row);
          console.log(row);
          const { original } = row || {};
          const isSelected =
            contentToAddToPlaylist?.includes(original.id) ||
            original.id === playlistToAssignScreen;
          return (
            <tr
              {...row.getRowProps()}
              className={`
              ${
                isShowCheckBox ? styles.gridFiveColumns : styles.gridFourColumns
              } 
              ${isSelected && styles.active}
              `}
              onClick={() => onClickRow(original)}
            >
              {isShowCheckBox && (
                <Checkbox className={styles.checkBox} isChecked={isSelected} />
              )}
              {row.cells.map((cell) => {
                const { id } = cell.column || {};
                const showPlayIcon = id === 'name';
                const showScreenIcon = id === 'screenType';
                const showRunningComp = id === 'status';
                const runTime = id === 'duration';
                const isMore = id === 'more';

                return (
                  <td
                    {...cell.getCellProps()}
                    className={`${id === 'name' && styles.name}`}
                  >
                    {showPlayIcon && (
                      <img
                        className={styles.playlistsIcon}
                        src={playlistsIcon}
                        alt=""
                      />
                    )}
                    {showScreenIcon && <Screen className={styles.screenIcon} />}
                    {showRunningComp && <RunningStatus />}
                    {(runTime && getDurationInTimeFormat(cell.value)) ||
                      cell.value}

                    {isMore && (
                      <Popover
                        className={styles.morePopupContainer}
                        popoverClassName={styles.popover}
                        popoverComponent={
                          <div
                            className={styles.morePopup}
                            onClick={(event) => {
                              event.stopPropagation();
                              onClickDelete(cell);
                            }}
                          >
                            <img src={dustbinIcon} alt="" />
                            <p>Delete</p>
                          </div>
                        }
                      >
                        <div className={styles.moreBtn}>
                          <More className={styles.icon} />
                        </div>
                      </Popover>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

PlaylistsTable.defaultProps = {
  className: '',
  isShowCheckBox: false,
  // fullHeight: false,
  columns: [],
  onClick: () => {},
  setDataToDelete: () => {},
};

export default PlaylistsTable;
