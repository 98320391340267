import { Mutation } from 'config';
import { toaster } from 'helpers';
import { captureException } from 'helpers/sentry';
import {
  iGraphqlResponse,
  iLoginApiArgs,
  iLoginData,
  iLoginResponse,
  iResetUserPasswordArgs,
  iResetUserPasswordData,
  iResetUserPasswordResponse,
  iSetUp2FAArgs,
  iSetUp2FAData,
  iSetUp2FAResponse,
  iVerify2FATokenResponse,
  iVerify2FATokenData,
  iVerify2FATokenArgs,
} from 'types';
import {
  loginMutation,
  resetUserPasswordMutation,
  setUp2FAMutation,
  verify2FATokenMutation,
} from '../graphql';

export const login = async (
  args: iLoginApiArgs,
  isLoading,
  setLoginInfo
): Promise<iLoginData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iLoginResponse>>(
      await Mutation(loginMutation, args)
    );
    isLoading(false);
    if (response.data.login.statusCode === 401) {
      setLoginInfo('401');
    }
    return response.data.login.data;
  } catch (error: any) {
    setLoginInfo(error.message);
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const resetPassword = async (
  args: iResetUserPasswordArgs,
  isLoading
): Promise<iResetUserPasswordData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iResetUserPasswordResponse>>(
      await Mutation(resetUserPasswordMutation, args)
    );
    isLoading(false);
    return response.data.resetuserpassword.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const setUp2FA = async (
  args: iSetUp2FAArgs,
  isLoading?: (_: boolean) => void
): Promise<iSetUp2FAData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iSetUp2FAResponse>>(
      await Mutation(setUp2FAMutation, args)
    );
    if (isLoading) isLoading(false);
    return response.data.updateUserSettings.data;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    return null;
  }
};

export const verify2FAToken = async (
  args: iVerify2FATokenArgs,
  isLoading?: (_: boolean) => void
): Promise<iVerify2FATokenData | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iVerify2FATokenResponse>>(
      await Mutation(verify2FATokenMutation, args)
    );
    if (isLoading) isLoading(false);
    if (response.data.verify2FAToken.data.verified) {
      return response.data.verify2FAToken.data;
    }
    toaster.error('You may entered wrong code');
    return null;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    return null;
  }
};
