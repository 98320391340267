import AddClientModal from 'components/add-client-modal/addClientModal';
import AddFolderModal from 'components/add-folder-modal/addFolderModal';
import AddScreenModal from 'components/add-screen-modal/addScreenModal';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import { LayoutViews, UserRoles } from 'enums';
import React, { useEffect } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import Client from './components/client/client';
import ResellerAdmin from './components/reseller-admin/resellerAdmin';
import SuperUserAdmin from './components/super-user-admin/superUserAdmin';
import styles from './home.module.scss';

function Home(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { layout, user } = state || {};
  const { role } = user || {};
  const { isShowingClientInstance } = layout || {};

  useEffect(() => {
    if (layout.windowWidth > 800 && role === UserRoles.Client)
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));

    if (role === UserRoles.SuperAdmin && isShowingClientInstance)
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));

    if (!isShowingClientInstance && role === UserRoles.SuperAdmin)
      dispatch(layoutActions.setHomePageView(LayoutViews.SuperAdminClients));
  }, [dispatch, layout.windowWidth, role, isShowingClientInstance]);

  const isClient = role === UserRoles.Client;
  const isSuperUserAdmin = role === UserRoles.SuperAdmin;
  const isResellerAdmin = role === UserRoles.Reseller;

  return (
    <Layout variant={LayoutVariants.layoutTwo}>
      <section className={styles.homeWrapper}>
        {isClient && <Client />}
        {isShowingClientInstance && isSuperUserAdmin && <Client />}
        {!isShowingClientInstance && isSuperUserAdmin && <SuperUserAdmin />}
        {!isShowingClientInstance && isResellerAdmin && <ResellerAdmin />}
        <AddClientModal />
        <AddScreenModal />
        <AddFolderModal />
      </section>
    </Layout>
  );
}

export default Home;
