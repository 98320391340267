import { LayoutViews, UserSettingsView } from 'enums';
import {
  iLoginData,
  iRoleData,
  iUpdateLoggedInUserData,
  iUserData,
  iUserSettings,
} from 'types';
import { USER } from '../constants';

export const userActions = {
  setLoginData: ({
    data,
    roles,
  }: {
    data: iLoginData;
    roles?: iRoleData[];
  }): any => ({
    type: USER.setLoginData,
    payload: { data, roles },
  }),
  set2FAData: (data: iUserSettings): any => ({
    type: USER.set2FAData,
    payload: data,
  }),
  changeView: (data: LayoutViews | UserSettingsView) => ({
    type: USER.changeView,
    payload: data,
  }),
  setResetPasswordData: (data: { userId: string; email: string } | null) => ({
    type: USER.setResetPasswordData,
    payload: data,
  }),
  updateLoggedInUserData: (data: iUpdateLoggedInUserData | null) => ({
    type: USER.updateLoggedInUserData,
    payload: data,
  }),
  setRemoveTeammate: (data: iUserData | null) => ({
    type: USER.setRemoveTeammate,
    payload: data,
  }),
};
