import React from 'react';
import styles from './avatar.module.scss';

function InvitedAvatar({ data }): JSX.Element {
  return (
    <div className={styles.invitedAvatarContainer}>
      <div className={styles.invitedAvatarCircle}>
        <div className={styles.invitedAvatarText}>
          {data.profilePic ? (
            <img src={data.profilePic} alt="" />
          ) : (
            <span>
              {(data.name && data.name[0]) || ''}
              {(data.lastName && data.lastName[0]) || ''}
            </span>
          )}
        </div>
      </div>
      <div className={styles.invitedNameContainer}>
        <p className={styles.invitedNameDetails}>
          {data.name}&nbsp; {data.lastName}
        </p>
        <p className={styles.invitedEmailDetails}>{data.email}</p>
      </div>
    </div>
  );
}

export default InvitedAvatar;
