import Logo from 'components/logo/logo';
import More from 'components/svg/more';
import { ROUTES } from 'constants/index';
import { LayoutViews, UserRoles } from 'enums';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import { SIDEBAR_ICONS } from './constants';
import MoreDrawer from './more-drawer/moreDrawer';
import Profile from './profile-popover/profilePopover';
import styles from './sidebar.module.scss';

interface iProps {
  className?: string;
}

function Sidebar(props: iProps): JSX.Element {
  const { className } = props;
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const [state, dispatch]: [iState, any] = useStore();
  const {
    chosenView,
    isShowingClientInstance,
    initialSidebarState,
    isShowAddContent,
    isShowAddWebContent,
    isShowContentPreview,
    isShowPlaylistPreview,
    isShowAddPlaylist,
    isShowAddFolderForPlaylists,
    isShowAddContentToPlaylist,
    isShowAddTeammate,
    isShowAddClient,
    isShowAddScreen,
    isShowAddFolder,
    isShowShareAccess,
    isShowDeleteModal,
    invokeGetAllFolders,
    invokeGetFolderById,
    isShowAssignContentOrPlaylist,
  } = state.layout || {};

  const isModalOpen =
    isShowAddContent ||
    isShowAddWebContent ||
    isShowContentPreview ||
    isShowPlaylistPreview ||
    isShowAddPlaylist ||
    isShowAddFolderForPlaylists ||
    isShowAddContentToPlaylist ||
    isShowAddTeammate ||
    isShowAddClient ||
    isShowAddScreen ||
    isShowAddFolder ||
    isShowShareAccess ||
    isShowDeleteModal ||
    invokeGetAllFolders ||
    invokeGetFolderById ||
    isShowAssignContentOrPlaylist;

  const { companyData } = state.homePage || {};
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selectedSettingsBtn = pathname === ROUTES.userSettings || isMoreOpen;

  useEffect(() => {
    if (state?.user?.role === UserRoles.Client)
      dispatch(layoutActions.initialSidebarState(true));
  }, []);

  const onClick = useCallback(
    (icon: any): void => {
      navigate(icon.path);
      if (icon.path === ROUTES.home)
        if (chosenView === LayoutViews.SuperAdminClients) {
          dispatch(
            layoutActions.setHomePageView(LayoutViews.SuperAdminClients)
          );
        }
      // dispatch(layoutActions.initialSidebarState());
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      dispatch(layoutActions.initialSidebarState(true));
      if (icon.path === ROUTES.content)
        dispatch(layoutActions.setHomePageView(LayoutViews.Content));
      if (icon.path === ROUTES.playlists)
        dispatch(layoutActions.setHomePageView(LayoutViews.Playlists));
    },
    [navigate, dispatch, chosenView]
  );

  const isActivePath = useCallback(
    (icon) => {
      const { path } = icon || {};
      return (
        pathname === path ||
        (path === ROUTES.home && pathname === ROUTES.screenDetails) ||
        (path === ROUTES.playlists && pathname === ROUTES.playlist)
      );
    },
    [pathname]
  );

  const onClickSideBarHandler = (icon: any): void => {
    if (!companyData) {
      toaster.error('Please choose a client to proceed');
      return;
    }
    if (isShowingClientInstance && pathname !== ROUTES.emptyStates)
      onClick(icon);
    if (state.user.role === UserRoles.Client) onClick(icon);
    if (pathname === ROUTES.userSettings) {
      navigate('/');
    }
  };
  const hideSidebar = pathname === ROUTES.playlist && true;

  return (
    <div
      className={`${styles.wrapper} ${
        (hideSidebar || isModalOpen) && styles.hide
      }`}
    >
      <MoreDrawer isOpen={isMoreOpen} />
      <div className={`${className} ${styles.sidebar}`}>
        <Logo className={styles.logo} variant="sidebar" />
        {SIDEBAR_ICONS.map((icon) => {
          const isActive = initialSidebarState ? isActivePath(icon) : false;

          return (
            <div
              key={icon.id}
              className={`${styles.icon} ${
                isShowingClientInstance &&
                pathname !== ROUTES.emptyStates &&
                styles.active
              }
                ${
                  state.user.role === UserRoles.Client &&
                  pathname !== ROUTES.emptyStates &&
                  styles.active
                }`}
              onClick={() => onClickSideBarHandler(icon)}
            >
              <div
                className={`${styles.iconHolder} ${isActive && styles.active}`}
              >
                <icon.icon
                  className={`${styles.svgStyle} ${isActive && styles.active}`}
                />
              </div>
              <span className={`${styles.name} ${isActive && styles.active}`}>
                {icon.iconName}
              </span>
            </div>
          );
        })}

        <div
          className={`${styles.icon} ${styles.moreIcon}`}
          onClick={() => setIsMoreOpen(!isMoreOpen)}
        >
          <div
            className={`${styles.iconHolder} ${
              selectedSettingsBtn && styles.active
            } `}
          >
            <More className={`${styles.svgStyle}`} />
          </div>
          <span
            className={`${styles.name} ${selectedSettingsBtn && styles.active}`}
          >
            More
          </span>
        </div>
        <Profile />
      </div>
    </div>
  );
}

Sidebar.defaultProps = {
  className: '',
};

export default Sidebar;
