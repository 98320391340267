import Button, { ButtonVariants } from 'components/button/button';
import React from 'react';
import styles from './footer.module.scss';

interface iProps {
  className?: string;
  onClickCancel: () => void;
}

function Index(props: iProps): JSX.Element {
  const { className, onClickCancel } = props;
  return (
    <footer className={`${styles.footer} ${className}`}>
      <Button
        className={styles.btns}
        variant={ButtonVariants.MediumTransp}
        btnName="Cancel"
        onClick={onClickCancel}
      />

      <Button
        className={styles.btns}
        variant={ButtonVariants.MediumStd}
        btnName="Save and close"
      />
    </footer>
  );
}

Index.defaultProps = {
  className: '',
};

export default Index;
