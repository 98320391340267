import Button, { ButtonVariants } from 'components/button/button';
import Popover from 'components/popover/popover';
import {
  caretFilledSmallDown,
  delete2Icon,
  duplicateIcon,
  eyeIcon,
  eyeCrossed,
  ROUTES,
} from 'constants/index';
import { ModalViews, SideDrawers } from 'enums';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { layoutActions, playlistPageActions } from 'store/actions';
import { iState } from 'types';
import styles from './header.module.scss';

interface iProps {
  className?: string;
  latePlaylistName?: boolean;
}

function Header(props: iProps): JSX.Element {
  const { className, latePlaylistName } = props;
  const navigate = useNavigate();
  const [popOverEffect, setPopoverEffect] = useState(false);
  const [state, dispatch]: [iState, any] = useStore();
  const { playlistData, isPlaylistSaveAndCloseBtnDisabled } =
    state.playlistsPage || {};
  const { isShowPlaylistPreview } = state.layout || {};
  const [playlistName] = useState<string>(playlistData?.name || '');

  const updatePlaylistHandler = useCallback(() => {
    dispatch(playlistPageActions.invokeUpdatePlaylist(true));
  }, [dispatch]);

  const cancel = useCallback(() => {
    navigate(ROUTES.playlists);
  }, [navigate]);

  const togglePlaylist = useCallback(() => {
    if (!playlistData) return;
    const { playListContents } = playlistData || {};

    if (isShowPlaylistPreview) {
      dispatch(layoutActions.toggleSideDrawer());
      dispatch(playlistPageActions.setPlaylistPreview(null));
    } else {
      dispatch(
        layoutActions.toggleSideDrawer(SideDrawers.isShowPlaylistPreview)
      );
      if (Array.isArray(playListContents))
        dispatch(playlistPageActions.setPlaylistPreview(playListContents));
    }
  }, [dispatch, playlistData, isShowPlaylistPreview]);

  return (
    <div className={`${styles.playlistViewHeader} ${className}`}>
      <div className={styles.colOne}>
        <p className={styles.playlistName}>{playlistName}</p>
      </div>
      <Button
        className={styles.saveBtn}
        btnName="Save"
        // isDisabled
        isActive={latePlaylistName}
        variant={ButtonVariants.ChangeView}
        onClick={updatePlaylistHandler}
      />
      {/* <Button
        className={styles.saveBtn}
        variant={ButtonVariants.ChangeView}
        btnName="Save"
        // isActive
        onClick={updatePlaylistHandler}
      /> */}
      <div className={styles.colTwo}>
        {/* <ColorPicker /> */}
        <Button
          className={`${styles.eyeBtn} ${styles.headerBtns}`}
          variant={ButtonVariants.SmallTransp}
          icon={isShowPlaylistPreview ? eyeCrossed : eyeIcon}
          onClick={togglePlaylist}
        />
        <Popover
          popoverClassName={styles.morePopover}
          popoverComponent={
            <div className={styles.moreWrapper}>
              <div className={styles.mutateOption}>
                <img src={duplicateIcon} alt="" />
                <p
                  onClick={() =>
                    dispatch(
                      layoutActions.toggleModal(ModalViews.isShowAddPlaylist)
                    )
                  }
                >
                  Duplicate playlist
                </p>
              </div>
              <div className={styles.mutateOption}>
                <img src={delete2Icon} alt="" />
                <p>Delete playlist</p>
              </div>
            </div>
          }
          setPopoverEffect={setPopoverEffect}
          popOverEffect={popOverEffect}
        >
          <Button
            className={`${styles.saveAndCloseBtn} ${styles.moreBtn} ${
              styles.headerBtns
            } ${popOverEffect && styles.activeMore}`}
            variant={ButtonVariants.SmallTransp}
            btnName="More"
            icon={caretFilledSmallDown}
            iconPosition="right"
          />
        </Popover>
        <Button
          className={`${styles.saveAndCloseBtn} ${styles.headerBtns}`}
          variant={ButtonVariants.SmallTransp}
          btnName="Cancel"
          onClick={cancel}
        />
        <Button
          className={`${styles.saveAndCloseBtn}`}
          variant={ButtonVariants.SmallStd}
          isActive={false}
          btnName="Save and close"
          onClick={updatePlaylistHandler}
          isDisabled={isPlaylistSaveAndCloseBtnDisabled}
        />
      </div>
    </div>
  );
}

Header.defaultProps = {
  className: '',
  latePlaylistName: false,
};

export default Header;
