import React from 'react';
import {
  caretLeft,
  greyedFolder,
  folder2Icon,
  activeFolderIcon,
} from 'constants/index';
import CheckBox from 'components/checkbox/checkbox';
import styles from './accordion.module.scss';

interface iProps {
  className?: string;
  heading: string;
  children: JSX.Element;
}

function Accordion(props: iProps): JSX.Element {
  const { className, heading, children } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isChecked, setSsChecked] = React.useState(false);
  console.log(activeFolderIcon);

  return (
    <div className={`${styles.accordion} ${className}`}>
      <div
        className={styles.accordionHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.dropdownIcon}>
          <img
            src={caretLeft}
            alt=""
            className={`${isOpen && styles.opened}`}
          />
        </div>
        <div className={styles.folderIconAndName}>
          <img
            className={styles.folderIcon}
            src={
              (isChecked && activeFolderIcon) ||
              (isOpen && greyedFolder) ||
              folder2Icon
            }
            alt=""
          />
          <p className={styles.folderName}>{heading}</p>
        </div>
        <CheckBox
          checkmarkStyle
          className={styles.checkbox}
          isChecked={isChecked}
          onClick={() => setSsChecked(!isChecked)}
        />
      </div>
      <div className={`${styles.dropdownContainer} ${isOpen && styles.opened}`}>
        {children}
      </div>
    </div>
  );
}

Accordion.defaultProps = {
  className: '',
};

export default Accordion;
