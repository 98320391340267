import Popover from 'components/popover/popover';
import { caretFilledSmallDown } from 'constants/index';
import { LayoutViews } from 'enums';
import React, { useCallback, useState } from 'react';
import { useStore } from 'store';
import { homePageActions, layoutActions } from 'store/actions';
import { iCompanyData, iState } from 'types';
import styles from './clientsListDropdown.module.scss';

interface iProps {
  className?: string;
}

function ClientsListDropdown(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const { companies = [], companyData } = state.homePage || {};
  const { isShowingClientInstance } = state.layout || {};
  // console.log({ state });

  const activeClient =
    companyData || (companies?.length > 0 && companies[0]) || null;

  const onClickClient = useCallback(
    (company: iCompanyData) => {
      dispatch(layoutActions.setShowClientsInstance(true));
      dispatch(homePageActions.setCompanyData(company));
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      dispatch(layoutActions.initialSidebarState(true));
    },
    [dispatch]
  );

  return (
    <Popover
      setIsShowing={setIsShowing}
      popoverClassName={styles.dropdown}
      popoverComponent={
        <ul className={styles.listContainer}>
          {companies.map((i: iCompanyData) => (
            <li
              className={`${styles.listItem} ${
                activeClient && i.id === activeClient.id && styles.active
              }`}
              onClick={() => onClickClient(i)}
            >
              {i.name}
              {/* {activeClient && i.id === activeClient.id && (
                  <img
                    className={styles.activeClientIcon}
                    src={checkCircleIcon}
                    alt=""
                  />
                )} */}
            </li>
          ))}
        </ul>
      }
    >
      <div
        className={`${className} ${styles.clientsDropdown} ${
          isShowing && styles.shown
        }`}
        onClick={() => setIsShowing(!isShowing)}
      >
        {!isShowingClientInstance && (
          <span className={styles.clientName}>All Clients</span>
        )}
        {isShowingClientInstance && (
          <span className={styles.clientName}>{companyData?.name}</span>
        )}
        <img
          className={`${styles.expandArrowDown}`}
          src={caretFilledSmallDown}
          alt=""
        />
      </div>
    </Popover>
  );
}

ClientsListDropdown.defaultProps = {
  className: '',
};

export default ClientsListDropdown;
