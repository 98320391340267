export enum TempEnum {
  Value = 'value',
}

export enum UserRoles {
  Client = 'Client',
  ClientInstance = 'ClientInstance',
  SuperAdmin = 'SuperAdmin',
  Reseller = 'Reseller',
}

export enum LayoutViews {
  Screens = 'Screens',
  Screen = 'Screen',
  Client = 'Client',
  ScreensDetails = 'ScreensDetails',
  SuperAdminClients = 'SuperAdminClients',
  SuperAdminResellers = 'SuperAdminResellers',
  ResellerClients = 'ResellerClients',
  Content = 'Content',
  ContentDetails = 'ContentDetails',
  Playlists = 'Playlists',
  PlaylistsDetails = 'PlaylistsDetails',
  Playlist = 'Playlist',
  PlaylistDetails = 'PlaylistDetails',
}

export enum ModalViews {
  isShowAddContent = 'isShowAddContent',
  isShowAddWebContent = 'isShowAddWebContent',
  isShowContentPreview = 'isShowContentPreview',
  isShowAddPlaylist = 'isShowAddPlaylist',
  isShowAddFolderForPlaylists = 'isShowAddFolderForPlaylists',
  isShowAddContentToPlaylist = 'isShowAddContentToPlaylist',
  isShowAddTeammate = 'isShowAddTeammate',
  isShowingClientInstance = 'isShowingClientInstance',
  isShowAddClient = 'isShowAddClient',
  isShowAddScreen = 'isShowAddScreen',
  isShowAddFolder = 'isShowAddFolder',
  isShowDeleteModal = 'isShowDeleteModal',
  isShowAssignContentOrPlaylist = 'isShowAssignContentOrPlaylist',
  isShowShareAccess = 'isShowShareAccess',
  isShowSetUp2FA = 'isShowSetUp2FA',
  isShowDisable2FA = 'isShowDisable2FA',
}

export enum SideDrawers {
  isShowContentPreview = 'isShowContentPreview',
  isShowPlaylistPreview = 'isShowPlaylistPreview',
}

export enum EmptyScreens {
  Screens = 'screens_empty_state',
  Content = 'content_empty_state',
  Playlists = 'playlists_empty_state',
  Clients = 'clients_empty_state',
}

export enum ScreenType {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum FolderType {
  SCREENZ = 'SCREENZ',
  CONTENT = 'CONTENT',
  PLAYLIST = 'PLAYLIST',
}

export enum RemoteActions {
  Reboot = 'reboot',
  Brightness = 'brightness',
  Volume = 'volume',
  RestartApp = 'restartApp',
  DisplayOn = 'displayOn',
  DisplayOff = 'displayOff',
}

export enum DevicePowerAction {
  APP_RESTART = 'APP_RESTART',
  SYSTEM_REBOOT = 'SYSTEM_REBOOT',
  DISPLAY_POWER_ON = 'DISPLAY_POWER_ON',
  DISPLAY_POWER_OFF = 'DISPLAY_POWER_OFF',
}

export enum TwoFAType {
  DISABLED = 'DISABLED',
  EMAIL = 'EMAIL',
  AUTHAPP = 'AUTHAPP',
}
