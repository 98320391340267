import { COMMON, CONTENT_PAGE } from 'store/constants';
import { iContentPageState } from 'types';
import { initialState } from '../initialState/initialState';

export const contentPageReducer = (
  state: iContentPageState,
  action
): iContentPageState => {
  switch (action.type) {
    case CONTENT_PAGE.setContents: {
      return {
        ...state,
        contents: action.payload,
      };
    }
    case CONTENT_PAGE.setUploadingFiles: {
      const uploadingFiles = [...state.uploadingFiles];
      return {
        ...state,
        uploadingFiles: [...uploadingFiles, ...action.payload],
      };
    }
    case CONTENT_PAGE.updateUploadingStatus: {
      const index = state.uploadingFiles.findIndex(
        (i) => i.id === action.payload.id
      );
      const files = [...state.uploadingFiles];
      files[index] = action.payload;
      return {
        ...state,
        uploadingFiles: files,
      };
    }
    case CONTENT_PAGE.removeFile: {
      const uploadingFiles = [...state.uploadingFiles];

      return {
        ...state,
        uploadingFiles: uploadingFiles.filter((i: any, idx): any => {
          if (action.payload !== idx) return true;
          return false;
        }),
      };
    }
    case CONTENT_PAGE.clearUploadingFiles: {
      return {
        ...state,
        uploadingFiles: [],
      };
    }
    case CONTENT_PAGE.setContentFolders: {
      return {
        ...state,
        contentFolders: action.payload,
      };
    }
    case CONTENT_PAGE.setAddWebContentData: {
      return {
        ...state,
        addWebContentData: action.payload,
      };
    }
    case CONTENT_PAGE.setContentActiveFolder: {
      return {
        ...state,
        contentActiveFolder: action.payload,
      };
    }
    case CONTENT_PAGE.setContentFolderHierarchy: {
      return {
        ...state,
        contentsFolderHierarchy: action.payload,
      };
    }
    case CONTENT_PAGE.emptyState: {
      return {
        ...state,
        emptyState: action.payload,
      };
    }
    case CONTENT_PAGE.showCancelAndSaveBtns: {
      return {
        ...state,
        showCancelAndSaveBtns: !state.showCancelAndSaveBtns,
      };
    }
    case CONTENT_PAGE.resetEmptyAndBtns: {
      return {
        ...state,
        showCancelAndSaveBtns: false,
        emptyState: '',
      };
    }
    case CONTENT_PAGE.setContentView: {
      return {
        ...state,
        contentView: action.payload,
      };
    }
    case CONTENT_PAGE.setSearchContentKey: {
      return {
        ...state,
        searchContentKey: action.payload,
      };
    }
    case CONTENT_PAGE.fetchContentsData: {
      return {
        ...state,
        canFetchContentsData: action.payload,
      };
    }
    case COMMON.reset: {
      return initialState.contentPage;
    }

    default:
      return state;
  }
};
