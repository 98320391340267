import React, { useCallback } from 'react';
import { useStore } from 'store';
// import { homePageActions } from 'store/actions';
import { iFolderData, iState } from 'types';
import Folder from './folder/folder';
import styles from './folders.module.scss';

interface iProps {
  className?: string;
  data?: iFolderData[] | null;
  openFolder?: (data: iFolderData) => void;
  setChecked?: () => void;
}

function Folders(props: iProps): JSX.Element {
  const { className, data, openFolder, setChecked } = props;
  const [state]: [iState, any] = useStore();
  const { openedFolders = [] } = state.homePage || {};

  const openFolderHandler = useCallback(
    (folder) => {
      if (openFolder) openFolder(folder);
      if (setChecked) setChecked();
      // dispatch(homePageActions.setActiveFolder(folder));
    },
    [openFolder]
  );

  return (
    <div
      className={`${styles.foldersWrapper} ${
        openedFolders?.length > 0 && styles.withList
      } ${className}`}
    >
      {data &&
        data.map((i) => (
          <Folder folderName={i.name} onClick={() => openFolderHandler(i)} />
        ))}
    </div>
  );
}

Folders.defaultProps = {
  className: '',
  data: [],
  openFolder: () => {},
  setChecked: () => {},
};

export default Folders;
