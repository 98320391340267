import EmptyState, {
  EmptyStateVariants,
} from 'components/empty-state/emptyState';
import Folders from 'components/folders/folders';
import ScreenTile from 'components/screen-tile/screenTile';
import SubFolderEmptyState from 'components/sub-folder-empty-state/subFolderEmptyState';
import React, { useCallback, useEffect, useState } from 'react';
import { updateDragAndDropList } from 'apis';
import { ListManager } from 'react-beautiful-dnd-grid';
import { useStore } from 'store';
import { homePageActions } from 'store/actions';
import { iScreenData, iState } from 'types';
import ScreensPrimaryDetails from '../screen-primary-details/screenPrimaryDetails';
import styles from './screensList.module.scss';

interface iProps {
  className?: string;
  data: iScreenData[];
  onReorder: (a: iScreenData[]) => void;
}

function ScreensList(props: iProps): JSX.Element {
  const { className, data, onReorder } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { screensFolders, screensActiveFolder } = state.homePage || {};
  const { chosenView } = state.layout || {};
  const [gridItems, setGridItems] = useState<number>();

  const onDrop = useCallback(
    async (destinationIndex, sourceIndex) => {
      if (destinationIndex === sourceIndex) return;

      const list = [...data];
      const source = { ...data[sourceIndex] };
      const destination = { ...data[destinationIndex] };
      list[sourceIndex] = destination;
      list[destinationIndex] = source;
      const orderedList = list.map((screen, index) => ({
        id: screen.id,
        sortOrder: index,
      }));
      onReorder([...list]);
      updateDragAndDropList(orderedList);
    },
    [data, onReorder]
  );

  useEffect(() => {
    const container = document.getElementById('screesDragAndDropContainer');
    if (container) {
      const { clientWidth } = container || {};
      console.log('section_width___', clientWidth);
      if (clientWidth < 700) {
        setGridItems(2);
      }
      if (clientWidth > 700 && clientWidth < 1023) {
        setGridItems(3);
      }
      if (clientWidth > 1024 && clientWidth < 1400) {
        setGridItems(4);
      }
      if (clientWidth > 1401 && clientWidth < 2000) {
        setGridItems(4);
      }
      if (clientWidth > 2000) {
        setGridItems(5);
      }
    }
  });

  useEffect(() => {
    // window.location.reload();
  }, []);

  return (
    <div
      className={`${className} ${styles.listAndDetails} ${
        data?.length > 0 && styles.displayboth
      }`}
    >
      <div
        className={`${styles.foldersAndScreensWrapper} ${styles[chosenView]}`}
      >
        {screensFolders?.length > 0 && (
          <Folders
            className={styles.foldersWrapper}
            data={screensFolders}
            openFolder={(folder) => {
              dispatch(homePageActions.setScreensActiveFolder(folder));
            }}
          />
        )}
        {data?.length === 0 &&
          screensFolders?.length === 0 &&
          !screensActiveFolder && (
            <EmptyState variant={EmptyStateVariants.Screenz} />
          )}

        {((data?.length === 0 && screensFolders?.length > 0) ||
          screensActiveFolder) && <SubFolderEmptyState />}
        {data.length > 0 && (
          <div className={`${className} ${styles.screenList}`}>
            {data.map((i) => (
              <ScreenTile data={i} />
            ))}
          </div>
        )}

        <div id="screesDragAndDropContainer" className={styles.gridContainer}>
          {gridItems && (
            <ListManager
              items={data}
              direction="horizontal"
              maxItems={gridItems}
              render={(item) => <ScreenTile data={item} />}
              onDragEnd={onDrop}
            />
          )}
        </div>
      </div>
      {data?.length > 0 && (
        <ScreensPrimaryDetails
          className={`${styles.detailView} ${styles[chosenView]}`}
          data={data}
        />
      )}
    </div>
  );
}

ScreensList.defaultProps = {
  className: '',
};

export default ScreensList;
