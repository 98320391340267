import LabelAndInput from 'components/label-and-input/labelAndInput';
import Modal from 'components/modal/modal';
import React, { useCallback, useState } from 'react';
import { useStore } from 'store';
import { contentIcon2, videoIcon, WebIcon } from 'constants/index';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import { DeleteModalVariants, Variants } from './variants';
import styles from './deleteModal.module.scss';

type iProps = {
  deleteHandler: () => void;
  isUsedSomewhere?: boolean;
  name: string;
  message?: string;
  isLoading?: boolean;
  contentType?: string;
  variant: DeleteModalVariants;
};

const CONTENT_ICONS = {
  image: contentIcon2,
  video: videoIcon,
  website: WebIcon,
};

function DeleteModal(props: iProps): JSX.Element {
  const {
    deleteHandler,
    name,
    message,
    isLoading,
    variant,
    contentType,
    isUsedSomewhere,
  } = props;

  const [state, dispatch]: [iState, any] = useStore();
  const [deletingItemName, setDeletingItemName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { isShowDeleteModal = false } = state.layout || {};

  const { heading, description, icon, deletingTo } = Variants[variant] || {};

  console.log('deletingItemName___', { deletingItemName, name });

  const onChange = useCallback((event): void => {
    event.stopPropagation();
    setDeletingItemName(event.target.value);
  }, []);

  const verifyAndDeleteHandler = useCallback((): void => {
    console.log(
      'verifyAndDeleteHandler____',
      deletingItemName.replace(/\s+/g, ' '),
      name.replace(/\s+/g, ' ')
    );

    if (!deletingItemName) {
      setErrorMessage(`Please enter ${deletingTo} name`);
      return;
    }

    if (deletingItemName.replace(/\s+/g, ' ') !== name.replace(/\s+/g, ' ')) {
      setErrorMessage(`Please enter a valid ${deletingTo} name`);
      return;
    }
    setErrorMessage('');
    deleteHandler();
  }, [deleteHandler, deletingItemName, deletingTo, name]);

  const closeModalHandler = useCallback(() => {
    dispatch(layoutActions.toggleModal());
    setErrorMessage('');
    setDeletingItemName('');
  }, [dispatch]);

  console.log('isUsedSomewhere___', { isUsedSomewhere, name });

  return (
    <Modal
      isOpen={isShowDeleteModal}
      headingIcon={contentType ? CONTENT_ICONS[contentType] : icon}
      heading={`${heading}  "${name || deletingTo}"`}
      btnName="Delete"
      isLoading={isLoading}
      onClose={closeModalHandler}
      submit={verifyAndDeleteHandler}
      isBtnDisabled={deletingItemName === ''}
      deleteModal
    >
      <div>
        {message !== '' && <p className={styles.delayMessage}>{message}</p>}
        {message === '' && (
          <>
            {isUsedSomewhere && (
              <div className={styles.deleteConfirmationMsg}>
                Playlist is currently used on some screens, Are you sure want to
                delete
              </div>
            )}
            {!isUsedSomewhere && (
              <>
                <p className={styles.labelParagraph}>
                  {contentType
                    ? description.replace('content?', `${contentType}?`)
                    : description}
                </p>
                <div className={styles.deleteModal}>
                  <div className={styles.labelAndInput}>
                    <LabelAndInput
                      className={styles.input}
                      name="name"
                      label={`${deletingTo} Name*`}
                      placeholder="Enter Name"
                      value={deletingItemName}
                      onChange={onChange}
                      errorMessage={errorMessage}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

DeleteModal.defaultProps = {
  message: '',
  isLoading: false,
  contentType: '',
  isUsedSomewhere: false,
};
export { DeleteModalVariants };
export default DeleteModal;
