import { folderIcon } from 'constants/index';
import React from 'react';
import { useStore } from 'store';
import PrimaryDetailsSkeleton from 'components/skeletons/primary-details/primaryDetails';
import ShareAccess from 'components/share-access/shareAccess';
import { iState } from 'types';
import styles from './contentDetails.module.scss';

interface iProps {
  className?: string;
  isLoading: boolean;
}

function ContentDetails(props: iProps): JSX.Element {
  const { className, isLoading } = props;
  const [state]: [iState] = useStore();
  const { homePage } = state || {};
  const { contentActiveFolder } = state.contentPage || {};
  const { activeFolder } = homePage || {};

  return (
    <div className={`${className} ${styles.contentDetails}`}>
      {isLoading ? (
        <PrimaryDetailsSkeleton />
      ) : (
        <>
          <div className={styles.headingSection}>
            <button type="button">Details</button>
          </div>
          <img className={styles.folderIcon} src={folderIcon} alt="" />
          <p className={styles.paragraphOne}>
            {activeFolder?.name || 'Your Content'}
          </p>
          <ShareAccess
            className={styles.shareAccess}
            shareType="content"
            heading={
              contentActiveFolder
                ? 'Has access to the folder'
                : 'Has access to all contents'
            }
          />
        </>
      )}
    </div>
  );
}

ContentDetails.defaultProps = {
  className: '',
};

export default ContentDetails;
