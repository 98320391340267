import Button, { ButtonVariants } from 'components/button/button';
import { caretLeftIcon } from 'constants/index';
import { currentPage } from 'helpers';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import {
  contentPageActions,
  homePageActions,
  layoutActions,
  playlistPageActions,
} from 'store/actions';
import { iState } from 'types';
import styles from './clientNameAndFolderPath.module.scss';

interface iProps {
  className?: string;
  username: string;
  setChecked?: () => void;
}

function Index(props: iProps): JSX.Element {
  const { className, username, setChecked } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { screensActiveFolder, screensFolderHierarchy } = state.homePage || {};
  const { contentActiveFolder, contentsFolderHierarchy } =
    state.contentPage || {};
  const { playlistActiveFolder, playlistsFolderHierarchy } =
    state.playlistsPage || {};

  const {
    assignModalContentsFolderHierarchy,
    assignModalContentActiveFolder,
    isShowAssignContentOrPlaylist,
  } = state.layout || {};

  // const isScreensPage = pathname === ROUTES.home || false;
  // const isContentsPage = pathname === ROUTES.content || false;
  // const isPlaylistPage = pathname === ROUTES.playlists || false;
  const { isScreensPage, isContentsPage, isPlaylistPage } = currentPage();

  const clear = useCallback(() => {
    dispatch(homePageActions.setScreensActiveFolder(null));
    dispatch(homePageActions.setScreensFolderHierarchy([]));
    dispatch(contentPageActions.setContentActiveFolder(null));
    dispatch(contentPageActions.setContentFolderHierarchy([]));
    dispatch(playlistPageActions.setPlaylistsActiveFolder(null));
    dispatch(playlistPageActions.setPlaylistsFolderHierarchy([]));
    dispatch(layoutActions.setAssignModalContentActiveFolders(null));
    dispatch(layoutActions.setAssignModalContentsFolderHierarchy([]));
  }, [dispatch]);

  // useEffect(() => {
  //   clear();
  // }, [clear]);

  const openFolder = useCallback(
    (folder) => {
      if (folder === null) {
        clear();
        dispatch(layoutActions.invokeGetAllFolders(true));
      } else {
        if (isScreensPage)
          dispatch(homePageActions.setScreensActiveFolder(folder));
        if (isContentsPage)
          dispatch(contentPageActions.setContentActiveFolder(folder));
        if (isPlaylistPage)
          dispatch(playlistPageActions.setPlaylistsActiveFolder(folder));
        if (isShowAssignContentOrPlaylist)
          dispatch(layoutActions.setAssignModalContentActiveFolders(folder));
      }
      if (setChecked) setChecked();
    },
    [
      dispatch,
      isContentsPage,
      isPlaylistPage,
      isScreensPage,
      isShowAssignContentOrPlaylist,
      clear,
      setChecked,
    ]
  );

  let folderHierarchy;
  if (isScreensPage) folderHierarchy = screensFolderHierarchy;
  if (isContentsPage) folderHierarchy = contentsFolderHierarchy;
  if (isPlaylistPage) folderHierarchy = playlistsFolderHierarchy;
  if (isShowAssignContentOrPlaylist)
    folderHierarchy = assignModalContentsFolderHierarchy;

  const activeFolder =
    (isScreensPage && screensActiveFolder) ||
    (isContentsPage && contentActiveFolder) ||
    (isPlaylistPage && playlistActiveFolder) ||
    (isShowAssignContentOrPlaylist && assignModalContentActiveFolder) ||
    null;

  return (
    <>
      <p className={`${styles.clientName} ${className}`}>
        <span
          className={
            folderHierarchy &&
            folderHierarchy.length === 0 &&
            !activeFolder &&
            styles.active
          }
          onClick={() => openFolder(null)}
        >
          {username}
        </span>
        {folderHierarchy &&
          folderHierarchy.map(
            (i) =>
              i && (
                <>
                  <span
                    className={`${
                      i.id === activeFolder?.id && styles.active
                    }  ${styles.separator}`}
                  >
                    /
                  </span>{' '}
                  <span
                    className={`${i.id === activeFolder?.id && styles.active}`}
                    onClick={() => openFolder(i)}
                  >
                    {i?.name}
                  </span>
                </>
              )
          )}
        {activeFolder && (
          <>
            <span
              className={`${
                activeFolder.id === activeFolder?.id && styles.active
              }  ${styles.separator}`}
            >
              /
            </span>{' '}
            <span
              className={`${
                activeFolder.id === activeFolder?.id && styles.active
              }`}
              // onClick={() => openFolder(activeFolder)}
            >
              {activeFolder?.name}
            </span>
          </>
        )}
      </p>

      {activeFolder && (
        <div
          className={`${styles.folderName} ${className}`}
          onClick={() => {
            let folder: any = null;

            if (folderHierarchy?.length) {
              folder =
                (folderHierarchy?.length === 0 && null) ||
                (folderHierarchy?.length > 0 &&
                  folderHierarchy[folderHierarchy.length - 1]) ||
                null;
            }
            if (isShowAssignContentOrPlaylist) {
              folder =
                (assignModalContentsFolderHierarchy?.length === 0 && null) ||
                (assignModalContentsFolderHierarchy?.length > 0 &&
                  assignModalContentsFolderHierarchy[0]) ||
                null;
            }

            openFolder(folder);
          }}
        >
          <Button
            className={`${styles.mobileDropdownBtn}`}
            variant={ButtonVariants.SmallStd}
            btnName="Screen"
            icon={caretLeftIcon}
            isSupportResponsive
          />
          <p>{activeFolder && activeFolder.name}</p>
        </div>
      )}
    </>
  );
}

Index.defaultProps = {
  className: '',
  setChecked: () => {},
};

export default Index;
