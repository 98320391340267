import { /* getScreens, */ getScreensByCompany, searchScreens } from 'apis';
// import EmptyState, {
//   EmptyStateVariants,
// } from 'components/empty-state/emptyState';
import Loader from 'components/loader/loader';
import { toaster } from 'helpers';
import React, { useEffect, useState, useCallback } from 'react';
import { useStore } from 'store';
import { homePageActions } from 'store/actions';
import { iScreenData, iState } from 'types';
import ScreenList from './screens-list/screensList';
import styles from './client.module.scss';

interface iProps {
  className?: string;
}

function Client(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { homePage } = state || {};
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [screens, setScreens] = useState<Array<iScreenData>>([]);
  const [searchedScreens, setSearchedScreens] =
    useState<Array<iScreenData> | null>(null);

  const {
    /* foldersAndSubFolders, */ companyData,
    canFetchScreensData,
    searchScreenKey,
    screensActiveFolder,
  } = homePage || {};

  const getScreens = useCallback(async () => {
    if (!companyData) {
      toaster.error('Company details not found');
      return;
    }
    const response = await getScreensByCompany(
      { companyId: companyData?.id, folderId: screensActiveFolder?.id || null },
      setIsLoading
    );
    if (response) {
      setScreens(response);
      dispatch(homePageActions.fetchScreensData(false));
    }
  }, [companyData, dispatch, screensActiveFolder?.id]);

  useEffect(() => {
    getScreens();
  }, [getScreens]);

  useEffect(() => {
    if (canFetchScreensData) getScreens();
  }, [canFetchScreensData, getScreens]);

  const searchScreenHandler = useCallback(async () => {
    const { id: companyId = '' } = companyData || {};
    if (!companyId) {
      toaster.error('company data not found');
      return;
    }
    const response = await searchScreens(
      {
        name: searchScreenKey,
        company: companyId,
        folder: screensActiveFolder?.id || null,
      },
      setIsLoading
    );
    if (response) {
      setSearchedScreens(response);
    } else {
      setSearchedScreens(null);
    }
  }, [companyData, searchScreenKey, screensActiveFolder?.id]);

  useEffect(() => {
    if (searchScreenKey !== '') searchScreenHandler();
    else setSearchedScreens(null);
  }, [searchScreenKey, searchScreenHandler]);

  const data =
    Array.isArray(searchedScreens) && searchedScreens.length > 0
      ? searchedScreens
      : screens;

  return (
    <div className={`${className} ${styles.clientWrapper}`}>
      {isLoading && <Loader />}
      {!isLoading &&
      searchScreenKey !== '' &&
      !Array.isArray(searchedScreens) ? (
        <p className={styles.notFound}>Result not found</p>
      ) : (
        data.length > 0 && <ScreenList data={data} onReorder={setScreens} />
      )}
    </div>
  );
}

Client.defaultProps = {
  className: '',
};

export default Client;
