import { gql } from 'apollo-boost';

export enum Playlist {
  AddPlaylist = 'addPlaylist',
  GetPlayListByCompany = 'getPlayListByCompany',
  GetPlaylistById = 'getplaylistbyid',
  UpdatePlaylist = 'updatePlaylist',
  SearchPlaylist = 'searchPlaylistbyname',
  DeletePlaylist = 'deletePlaylist',
}

export const addPlaylistMutation = gql`
  mutation ${Playlist.AddPlaylist} (
    $name: String!
    $companyId: String!
    $folderId: String
    $contents: [playlistContents]
    $duration: String
    $screenType: ScreenType
    $dimention: String
    $status: String
  ){
    ${Playlist.AddPlaylist} (
      name: $name
      companyId: $companyId
      folderId: $folderId
      contents: $contents
      duration: $duration
      screenType: $screenType
      dimention: $dimention
      status: $status
    ){
      statusCode
      message
      data {
        id
        companyId
        name
        playListContents {
          id
          name
          url
          contentType
          size
          dimension
          folder
          thumbnailPath
        }
      }
    }
  }
`;

export const getPlayListByCompanyQuery = gql`
  query ${Playlist.GetPlayListByCompany}(
    $companyId: String!
    $folderId: String
  ){
    ${Playlist.GetPlayListByCompany}
    (
      companyId: $companyId
      folderId: $folderId
    ){
      statusCode
      message
    data {
      id
      companyId
      name
      duration
      dimention
      screenType
      status
      playListContents {
        id
        name
        url
        contentType
        size
        dimension
        duration
        thumbnailPath
      }
    }
    }
  }
`;

export const getPlaylistByIdQuery = gql`
  query ${Playlist.GetPlaylistById}($id: String){
    ${Playlist.GetPlaylistById}(id: $id){
      statusCode
      message
      data {
        id
        companyId
        name
        duration
        dimention
        screenType
        status
        playListContents {
          id
          name
          url
          contentType
          size
          dimension
          duration
          thumbnailPath
          sortOrder
          playlistContentId
        }
      }
    }
  }
`;

export const updatePlaylistMutation = gql`
  mutation ${Playlist.UpdatePlaylist} (
    $id: String!
    $name: String!
    $companyId: String!
    $folderId: String
    $contents: [playlistContents]
    $duration: String
    $dimention: String
    $status: String
  ){
    ${Playlist.UpdatePlaylist} (
      id: $id
      name: $name
      companyId: $companyId
      folderId: $folderId
      contents: $contents
      duration: $duration
      dimention: $dimention
      status: $status
    ){
      statusCode
      message
      data {
        id
      }
    }
  }
`;

export const searchPlaylistMutation = gql`
  mutation ${Playlist.SearchPlaylist} (
    $name: String
    $company: String!
    $folderId: String
  ){
    ${Playlist.SearchPlaylist} (
      name: $name
      company: $company
      folderId: $folderId
    ){
      message
      statusCode
      data {
        id
        companyId
        name
        duration
        dimention
        screenType
        status
      }
    }
  }
`;

export const deletePlaylistMutation = gql`
  mutation ${Playlist.DeletePlaylist} (
    $id: String!
    $is_data: Boolean!
  ){
    ${Playlist.DeletePlaylist} (
      id: $id
      is_data: $is_data
    ){
      message
      statusCode
    }
  }
`;
