import { getMinus, getSeconds } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './time.module.scss';
import TimePicker from './timePicker';

interface iProps {
  className?: string;
  duration: string;
  onChange: (event, time: string) => void;
  videoSrc?: string;
}

function TimePick(props: iProps): JSX.Element {
  const { className, duration, onChange, videoSrc } = props;
  // const videoRef: any = useRef(null);
  const [time, setTime] = useState({
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    setTime({
      minutes: Number(getMinus(duration)),
      seconds: Number(getSeconds(duration)),
    });
  }, [duration]);

  const { minutes, seconds } = time;

  const onChangeHandler = useCallback(
    (event, pickedTime) => {
      setTime(pickedTime);
      onChange(event, `${pickedTime.minutes * 60 + pickedTime.seconds}`);
    },
    [onChange]
  );

  return (
    <div className={`${styles.timePickers} ${className}`}>
      <TimePicker
        isDisabled={videoSrc !== ''}
        className={styles.timePicker}
        value={minutes}
        onClick={(event, pickedTime) => {
          onChangeHandler(event, { ...time, minutes: pickedTime });
        }}
      />
      :
      <TimePicker
        isDisabled={videoSrc !== ''}
        className={styles.timePicker}
        value={seconds}
        onClick={(event, pickedTime) => {
          onChangeHandler(event, { ...time, seconds: pickedTime });
        }}
      />
    </div>
  );
}

TimePick.defaultProps = {
  className: '',
  videoSrc: '',
};

export default TimePick;
