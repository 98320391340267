import Connect from 'components/svg/connect';
import Disconnect from 'components/svg/disconnect';
import Information from 'components/svg/information';
import React from 'react';
import styles from './screenshot.module.scss';

interface iProps {
  className?: string;
  screenshot: string;
  isConnectedScreen: boolean;
  isOnline: boolean;
}

function Screenshot(props: iProps): JSX.Element {
  const { className, isConnectedScreen, isOnline, screenshot } = props;

  const StatusIcon =
    (isConnectedScreen && (isOnline ? Connect : Disconnect)) || Information;

  const statusMessage =
    (isConnectedScreen && (isOnline ? 'Online' : 'Offline')) ||
    "You haven't connected this to a live screen yet.";

  return (
    <div
      className={`${className} ${styles.screen} ${
        !screenshot && styles.noScreenShot
      }`}
    >
      {screenshot ? (
        <img className={styles.screenshot} src={screenshot} alt="" />
      ) : (
        <div className={styles.connectionStatus}>
          <StatusIcon className={styles.svg} />
          {statusMessage}
        </div>
      )}
    </div>
  );
}

Screenshot.defaultProps = {
  className: '',
};

export default Screenshot;
