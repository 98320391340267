import React, { useEffect } from 'react';
import { useStore } from 'store';
import { iState } from 'types';
// import axios from 'axios';
import { refreshIcon, playlistEmptyStateImage } from 'constants/index';
import { contentPageActions } from 'store/actions';
import Buttons from './header/buttons/buttons';
import Header from './header/header';
import Sidebar from './sidebar/sidebar';
import styles from './addWebContent.module.scss';

interface iProps {
  className?: string;
}

function AddWebContent(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  // const [urlToVerify, setUrlToVerify] = React.useState<string>('');
  const { addWebContentData } = state.contentPage || {};
  const { url } = addWebContentData || {};

  useEffect(() => {
    // (async () => {
    //   const res = await axios.get(url || '', {
    //     headers: {
    //       'Test-Header': 'test-value',
    //     },
    //   });
    //   console.log('res______', res);
    // })();
    //   // const request = (HttpWebRequest)HttpWebRequest.Create("http://www.facebook.com/");
    //   const doc: any = document;
    //   console.log(
    //     'URL_LOAD_TEST',
    //     doc?.getElementById('addWebContentIframe')?.contentWindow?.location
    //   );
  }, [url]);

  const openWebsite = React.useCallback(() => {
    try {
      const iframe: any = document.getElementById('addWebContentIframe');
      if (iframe) {
        const innerDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        console.log('ifrm_contentDocument', innerDoc);
      }
      // if (!location) throw new Error('');
      if (addWebContentData)
        dispatch(
          contentPageActions.setAddWebContentData({
            ...addWebContentData,
            isLoadableInIFrame: true,
          })
        );
    } catch (error) {
      console.log('error__', error);

      if (addWebContentData)
        dispatch(
          contentPageActions.setAddWebContentData({
            ...addWebContentData,
            isLoadableInIFrame: false,
          })
        );
    }
  }, [addWebContentData, dispatch]);

  const onClickRefresh = React.useCallback(() => {
    const iframe: any = document.getElementById('addWebContentIframe');
    if (iframe) {
      iframe.contentWindow.location.reload();
    }
  }, []);

  return (
    <div className={`${styles.addWebContent} ${className}`}>
      <Header />
      <div className={styles.mainSection}>
        <div className={styles.previewContainer}>
          <div className={styles.previewHeader}>
            <div className={styles.leftOne}>
              <span className={styles.browserBtns} />
              <span className={styles.browserBtns} />
              <span className={styles.browserBtns} />
            </div>
            <div className={styles.rightOne}>
              <button
                className={styles.refreshBtn}
                type="button"
                onClick={onClickRefresh}
              >
                <img className={styles.refreshIcon} src={refreshIcon} alt="" />
                <span>Refresh</span>
              </button>
            </div>
          </div>
          <div className={styles.mainSection}>
            {url ? (
              <iframe
                className={styles.previewIFrame}
                id="addWebContentIframe"
                title="Web page preview"
                src={url}
                frameBorder="0"
                // onLoad={(event: any) => {
                //   // const doc: any = document?.getElementById(
                //   //   'addWebContentIframe'
                //   // );
                //   console.log('URL_LOAD_TEST', event.contentWindow);
                // }}
              />
            ) : (
              <>
                <img src={playlistEmptyStateImage} alt="" />
                <p className={styles.addUrl}>Add URL</p>
                <p className={styles.addUrlDescription}>
                  The website URL will preview here. Your screen uses iFrame to
                  dispaly websites, not all websites allow this.
                </p>
              </>
            )}
          </div>
        </div>
        <Sidebar openWebSite={openWebsite} />
        <Buttons className={styles.respnsiveBtns} />
      </div>
    </div>
  );
}

AddWebContent.defaultProps = {
  className: '',
};

export default AddWebContent;
