import { Mutation, Query } from 'config';
import { toaster } from 'helpers';
import { captureException } from 'helpers/sentry';
import {
  iAddFolderArgs,
  iAddFolderResponse,
  iFolderData,
  iGraphqlResponse,
  iGetAllFoldersResponse,
  iGetAllFolderArgs,
  iGetFolderByIdArgs,
  iGetFolderByIdResponse,
} from 'types';
import {
  addFolderMutation,
  getAllFoldersQuery,
  getFolderByIdQuery,
} from '../graphql';

export const addFolder = async (
  args: iAddFolderArgs,
  isLoading
): Promise<iFolderData | null> => {
  try {
    isLoading(true);
    const response = await (<iGraphqlResponse<iAddFolderResponse>>(
      await Mutation(addFolderMutation, args)
    ));
    isLoading(false);
    return response.data.addFolder.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    toaster.error(error.message);
    return null;
  }
};

export const getAllFolders = async (
  args: iGetAllFolderArgs,
  isLoading
): Promise<Array<iFolderData> | null> => {
  try {
    isLoading(true);
    const response = await (<iGraphqlResponse<iGetAllFoldersResponse>>(
      await Query(getAllFoldersQuery, args)
    ));
    isLoading(false);
    return response.data.getallFolderByCompany.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    toaster.error(error.message);
    return null;
  }
};

export const getFolderById = async (
  args: iGetFolderByIdArgs,
  isLoading
): Promise<iFolderData | null> => {
  try {
    isLoading(true);
    const response = await (<iGraphqlResponse<iGetFolderByIdResponse>>(
      await Query(getFolderByIdQuery, args)
    ));
    isLoading(false);
    return response.data.getFolderbyid.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    toaster.error(error.message);
    return null;
  }
};
