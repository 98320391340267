import { UserRoles, UserSettingsView } from 'enums';
import { toaster } from 'helpers';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { userActions } from 'store/actions';
import { iState } from 'types';
import { SETTING_NAV } from '../../constants';
import styles from './navigators.module.scss';

interface iProps {
  className?: string;
}

function Navigators(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { activeView } = state.user.userSettings || {};
  const { role } = state.user || {};
  const { companyData } = state.homePage || {};

  const changeView = useCallback(
    (view) => {
      console.log('changeView', view);
      if (
        (view === UserSettingsView.General && !companyData) ||
        (view === UserSettingsView.TeamMembers && !companyData)
      ) {
        toaster.error('Please choose a client to proceed');
        return;
      }
      dispatch(userActions.changeView(view));
    },
    [dispatch, companyData]
  );

  return (
    <>
      {SETTING_NAV.filter((item) => {
        if (role === UserRoles.Client) {
          return item.heading !== 'Your Company';
        }
        return true;
      }).map((item) => (
        <div className={`${styles.container} ${className}`} key={item.id}>
          <div className={styles.heading}>
            <img src={item.icon} alt="" />
            <span>{item.heading}</span>
          </div>
          {item.options.map((c) => (
            <ul className={styles.optionsList} key={c.id}>
              <li
                className={`${styles.list} ${
                  activeView === c.view && styles.active
                }`}
                onClick={() => changeView(c.view)}
              >
                {c.option}
              </li>
            </ul>
          ))}
        </div>
      ))}
    </>
  );
}

Navigators.defaultProps = {
  className: '',
};

export default Navigators;
