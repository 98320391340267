import { ROLES } from 'constants/index';
import { UserRoles } from 'enums';
import { iUserState } from 'types';
import { COMMON, USER } from '../constants/index';
import { initialState } from '../initialState/initialState';

export const userReducer = (
  state: iUserState = initialState.user,
  action
): iUserState => {
  switch (action.type) {
    case USER.setLoginData: {
      const { roleId } = action.payload.data;

      return {
        ...state,
        loggedInData: action.payload.data,
        allRoles: action.payload.roles,
        isLoggedIn: true,
        role:
          (roleId === ROLES.super_admin && UserRoles.SuperAdmin) ||
          UserRoles.Client,
      };
    }
    case USER.changeView: {
      const userSettings = { ...state.userSettings };
      userSettings.activeView = action.payload;
      return { ...state, userSettings };
    }
    case USER.set2FAData: {
      return { ...state, twoFAVerificationData: action.payload };
    }
    case USER.setResetPasswordData: {
      return { ...state, resetPasswordData: action.payload };
    }
    case USER.setRemoveTeammate: {
      return { ...state, removeTeammateData: action.payload };
    }
    case USER.updateLoggedInUserData: {
      return { ...state, loggedInData: action.payload };
    }
    case COMMON.reset: {
      return initialState.user;
    }
    default:
      return state;
  }
};
