import React from 'react';
import Subheader from '../subheader/subheader';
import Permissions, { PermissionsVariants } from './permissions/permissions';
import styles from './teamMembersPermissions.module.scss';

interface iProps {
  className?: string;
}

function TeamMembersPermissions(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.teamMembersPermissions} ${className}`}>
      <Subheader />
      <div className={styles.section}>
        <p className={styles.sectionHeading}>
          Change Frode Ingebretsen’s permissions
        </p>
        <Permissions variant={PermissionsVariants.ScreensAccess} />

        <Permissions variant={PermissionsVariants.ContentAccess} />
        <Permissions variant={PermissionsVariants.PlaylistAccess} />
      </div>
    </div>
  );
}

TeamMembersPermissions.defaultProps = {
  className: '',
};

export default TeamMembersPermissions;
