import { gql } from 'apollo-boost';

export enum OnBoarding {
  Login = 'login',
  ResetUserPassword = 'resetuserpassword',
  SetUp2FA = 'updateUserSettings',
  Verify2FAToken = 'verify2FAToken',
}

export const loginMutation = gql`
    mutation ${OnBoarding.Login}(
      $email: String
      $password: String
      ){
        ${OnBoarding.Login}(
          email: $email
          password: $password
          ){
            message
            statusCode
            data {
              settings {
                twoFA
                id
              }
              id
              firstName
              lastName
              email
              password
              profile_pic
              roleId
              jwtToken
              companyData {
                email
                address
                city
                name
                postal_code
                org_no
                phone_number
                country_code
                id
                created_at
              }
            }
        }
    }
`;

export const resetUserPasswordMutation = gql`
    mutation ${OnBoarding.ResetUserPassword}(
      $email: String
      ){
        ${OnBoarding.ResetUserPassword}(
          email: $email
          ){
            message
            statusCode
            data {
              jwtToken
            }
        }
    }
`;

export const setUp2FAMutation = gql`
    mutation ${OnBoarding.SetUp2FA}(
      $userId: String!
      $isDarkMode: Boolean
      $twoFAType: TwoFAType!
      ){
        ${OnBoarding.SetUp2FA}(
          userId: $userId
          isDarkMode: $isDarkMode
          twoFAType: $twoFAType
          ){
            message
            statusCode
            data {
              id
              isDarkMode
              twoFA
              authQR
            }
        }
    }
`;

export const verify2FATokenMutation = gql`
    mutation ${OnBoarding.Verify2FAToken}(
      $userId: String!
      $twoFAType: TwoFAType!
      $verificationCode: String!
      ){
        ${OnBoarding.Verify2FAToken}(
          userId: $userId
          twoFAType: $twoFAType
          verificationCode: $verificationCode
          ){
            message
            statusCode
            data {
              verified
              user {
                id
                firstName
                lastName
                email
                password
                profile_pic
                roleId
                rollName
                jwtToken
                invitedStatus
                password_token
                settings {
                  id
                  isDarkMode
                  twoFA
                  authQR
                }
                companyData { 
                  email
                  address
                  city
                  name
                  postal_code
                  org_no
                  phone_number
                  country_code
                  id
                  created_at
                }
              }
            }
        }
    }
`;
