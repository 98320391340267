import React, { useState, useCallback, useEffect } from 'react';

interface iProps {
  className?: string;
  onChange: (value: string) => void;
  value: string;
}

function ContentEditable(props: iProps): JSX.Element {
  const { onChange, value, className } = props;

  const [rawValue, setRawValue] = useState<string>('');

  useEffect(() => {
    if (rawValue === '') setRawValue(value);
  }, [value]);

  const onBlurHandler = useCallback(
    (event) => {
      const { textContent } = event.currentTarget;
      setRawValue(textContent);
      onChange(textContent);
    },
    [onChange]
  );

  return (
    <div
      className={className}
      contentEditable
      onBlur={onBlurHandler}
      dangerouslySetInnerHTML={{ __html: rawValue }}
    />
  );
}

ContentEditable.defaultProps = {
  className: '',
};

export default ContentEditable;
