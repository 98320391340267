import React, { useCallback } from 'react';
// import { caretFilledSmallDown } from 'constants/index';
import Avatar, { AvatarVariants } from 'components/avatar/avatar';
import InviteUser from '../invite-user/inviteUser';
import { UsersListVariants, Variants } from './variant';
import styles from './usersList.module.scss';

interface iProps {
  className?: string;
  data: any;
  variant: UsersListVariants;
  isShowInviteUser?: boolean;
  onClick?: (_: any) => void;
  onClickInvite?: () => void;
}

function UsersList(props: iProps): JSX.Element {
  const {
    className,
    data,
    variant,
    isShowInviteUser = false,
    onClick,
    onClickInvite,
  } = props;
  const { showEmail, showOwnerTag, showInviteUser, showEdit } =
    Variants[variant] || {};

  const USER_ID = 1;

  const onClickInviteHandler = useCallback(() => {
    if (onClickInvite) onClickInvite();
  }, [onClickInvite]);

  return (
    <div
      className={`${styles.usersList} ${
        !isShowInviteUser && styles[variant]
      } ${className}`}
    >
      {isShowInviteUser && showInviteUser && (
        <InviteUser onClickInvite={onClickInviteHandler} />
      )}

      {showInviteUser &&
        data.map((user) => (
          <div
            className={styles.avatarAndName}
            onClick={() => onClick && onClick(user)}
          >
            {showEmail ? (
              <div className={styles.avatarWithEmail}>
                <div>
                  <Avatar
                    variant={AvatarVariants.Small}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    profilePic=""
                  />
                </div>
                <div>
                  <p className={styles.name}>
                    {user.firstName} {user.lastName}
                  </p>
                  <p className={styles.email}>{user.email}</p>
                </div>
              </div>
            ) : (
              <>
                <Avatar
                  variant={AvatarVariants.Small}
                  firstName={user.firstName}
                  lastName={user.lastName}
                  profilePic=""
                />
                <p className={styles.name}>
                  {user.firstName} {user.lastName} {user.id === 1 && '(You)'}
                </p>
              </>
            )}

            {user.id === USER_ID && showOwnerTag && (
              <p className={styles.owner}>Owner</p>
            )}
            {user.id !== USER_ID && showEdit && (
              <div className={styles.canEdit}>
                <span>Can edit</span>
                {/* <img src={caretFilledSmallDown} alt="" /> */}
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

UsersList.defaultProps = {
  className: '',
  isShowInviteUser: false,
  onClick: () => {},
  onClickInvite: () => {},
};

export { UsersListVariants };
export default UsersList;
