import React from 'react';
import Logo from 'components/logo/logo';
import { uuid } from 'helpers';
import { arrowRightIcon } from 'constants/index';
import Pattern from '../components/pattern/pattern';
import styles from './chooseClient.module.scss';

interface iProps {
  className?: string;
}

const CLIENTS_LIST = [
  {
    id: uuid(),
    name: 'Client Name 1',
    lastLogin: 'Last login [date.month.year]',
  },
  {
    id: uuid(),
    name: 'Client Name 2',
    lastLogin: 'Last login [date.month.year]',
  },
  {
    id: uuid(),
    name: 'Client Name 3',
    lastLogin: 'Last login [date.month.year]',
  },
];

function ChooseClient(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.chooseClient} ${className}`}>
      <Pattern position="top" />
      <Pattern position="bottom" />
      <section className={`${styles.section}`}>
        <div className={styles.chooseClientContainer}>
          <Logo className={styles.logo} variant="onboarding" />
          <p className={styles.chooseClientHeading}>Log into existing client</p>
          <p className={styles.chooseClientDescription}>
            You’re already a member of the following clients.
          </p>

          <div className={styles.clientsList}>
            {CLIENTS_LIST.map((client) => (
              <div className={styles.client}>
                <Logo className={styles.logo} variant="onboarding" />
                <div className={styles.details}>
                  <p className={styles.name}>{client.name}</p>
                  <p className={styles.lastLogin}>{client.lastLogin}</p>
                </div>
                <img className={styles.arrow} src={arrowRightIcon} alt="" />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

ChooseClient.defaultProps = {
  className: '',
};

export default ChooseClient;
