import React, { useState, useCallback } from 'react';
import OTPInput from 'otp-input-react';
import { LayoutViews, TwoFAType } from 'enums';
import { ROLES, ROUTES } from 'constants/index';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariants } from 'components/button/button';
import Logo from 'components/logo/logo';
import { toaster } from 'helpers';
import { homePageActions, layoutActions, userActions } from 'store/actions';
import { useStore } from 'store';
import { iState } from 'types';
import { verify2FAToken, getUserRoles, getAllSystemConfigs } from 'apis';
import Pattern from '../components/pattern/pattern';
import styles from './verify2fa.module.scss';

interface iProps {
  className?: string;
}

function ChooseClient(props: iProps): JSX.Element {
  const { className } = props;
  const [verificationCode, setVerificationCode] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [state, dispatch]: [iState, any] = useStore();
  const { twoFAVerificationData } = state?.user || {};
  console.log(setIsLoading);
  const navigate = useNavigate();
  const verifyCodeHandler = useCallback(async () => {
    const { id, twoFA = '' } = twoFAVerificationData || {};
    if (!verificationCode) {
      toaster.error('Please enter the verification code to proceed');
      return;
    }

    if (!id && !twoFA) return;
    const response = await verify2FAToken({
      userId: id,
      twoFAType: TwoFAType[twoFA],
      verificationCode: `${verificationCode}`,
    });
    if (response) {
      console.log('response____', response);
      const roles = (await getUserRoles()) || [];
      const systemConfigs = await getAllSystemConfigs();
      console.log(systemConfigs, 'systemConfigs');
      if (systemConfigs) {
        dispatch(layoutActions.setEmptyStates(systemConfigs));
      }
      dispatch(userActions.setLoginData({ data: response.user, roles }));
      if (Number(response.user.roleId) !== ROLES.super_admin)
        dispatch(homePageActions.setCompanyData(response.user.companyData));
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      navigate(ROUTES.home, { replace: true });
    }
  }, [verificationCode, twoFAVerificationData, dispatch, navigate]);

  return (
    <div className={`${styles.verify2fa} ${className}`}>
      <Pattern position="top" />
      <Pattern position="bottom" />
      <section className={`${styles.section}`}>
        <div className={styles.verify2faContainer}>
          <Logo className={styles.logo} variant="onboarding" />
          <p className={styles.verify2faHeading}>Verify 2FA code</p>
          <p className={styles.verify2faDescription}>
            Please enter the verification code.
          </p>

          {/* <div className={styles.clientsList}> */}
          <OTPInput
            className={styles.otpInputs}
            value={verificationCode}
            onChange={setVerificationCode}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
            secure
          />
          {/* </div> */}
          <Button
            className={styles.verifyBtn}
            btnName="Verify"
            variant={ButtonVariants.MediumStd}
            onClick={verifyCodeHandler}
            isLoading={isLoading}
          />
        </div>
      </section>
    </div>
  );
}

ChooseClient.defaultProps = {
  className: '',
};

export default ChooseClient;
