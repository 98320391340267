import Button, { ButtonVariants } from 'components/button/button';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import {
  arrowRightIcon,
  clockIcon,
  CONTENT_TYPE,
  playlistsIcon,
  ROUTES,
  videoIcon,
  WebIcon,
} from 'constants/index';
import ShareAccess from 'components/share-access/shareAccess';
import { ModalViews } from 'enums';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  homePageActions,
  layoutActions,
  playlistPageActions,
} from 'store/actions';
import { iState } from 'types';
import styles from './details.module.scss';

interface iProps {
  className?: string;
  variant?: 'desktop' | 'mobile';
}

function Index(props: iProps): JSX.Element {
  const { className, variant = 'desktop' } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { homePage } = state || {};
  const { screenData } = homePage || {};
  const navigate = useNavigate();
  const { hashCode, name } = screenData || {};

  const onChange = useCallback(
    (event) => {
      const temp = { ...screenData };
      temp[event.target.name] = event.target.value;
      dispatch(homePageActions.updateScreenData(temp));
    },
    [dispatch, screenData]
  );

  const { currentContent, currentPlayList } = screenData || {};

  return (
    <div className={`${className} ${styles.details}`}>
      {variant === 'desktop' && (
        <div className={styles.header}>
          <Button
            className={styles.screenBtn}
            variant={ButtonVariants.ChangeView}
            btnName="Screen"
            isActive
          />
        </div>
      )}
      <div className={styles.screenNameAndHashCode}>
        <LabelAndInput
          className={styles.screenName}
          value={name || ''}
          name="name"
          placeholder="Screen Name"
          label="Screen Name"
          onChange={onChange}
        />
        <LabelAndInput
          className={styles.disabled}
          value={hashCode || ''}
          name="hashCode"
          label="Screen Hash"
          placeholder="Screen Hash"
          disabled
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className={styles.contentSection}>
        <p className={styles.sectionHeading}>Content</p>
        {currentContent && (
          <div className={styles.content}>
            <div className={styles.nameSection}>
              <div className={styles.contentPreview}>
                {currentContent.contentType === CONTENT_TYPE.Image && (
                  <img src={currentContent.url} alt="" />
                )}
                {currentContent.contentType === CONTENT_TYPE.Video && (
                  <img src={videoIcon} alt="" />
                )}
                {currentContent.contentType === CONTENT_TYPE.Website && (
                  <img src={WebIcon} alt="" />
                )}
              </div>
              <p className={styles.contentName}>{currentContent.name}</p>
            </div>
            <div className={styles.timeSection}>
              {/* <img className={styles.clockIcon} src={clockIcon} alt="" /> */}
              {/* <span className={styles.duration}>
                {currentContent. || '00:00'}
              </span> */}
              <img
                className={styles.arrowRightIcon}
                src={arrowRightIcon}
                alt=""
                onClick={() => navigate(ROUTES.content)}
              />
            </div>
          </div>
        )}
        {currentPlayList && (
          <div className={styles.content}>
            <div className={styles.nameSection}>
              <div className={styles.contentPreview}>
                <img src={playlistsIcon} alt="" />
              </div>

              <p className={styles.contentName}>{currentPlayList.name}</p>
            </div>
            <div className={styles.timeSection}>
              <img className={styles.clockIcon} src={clockIcon} alt="" />
              <span className={styles.duration}>
                {currentPlayList.duration || '00:00'}
              </span>
              <img
                className={styles.arrowRightIcon}
                src={arrowRightIcon}
                alt=""
                onClick={() => {
                  dispatch(
                    playlistPageActions.setPlaylistData(currentPlayList)
                  );
                  navigate(ROUTES.playlist);
                }}
              />
            </div>
          </div>
        )}
        <Button
          className={styles.changeContentBtn}
          btnName="Change Content"
          variant={ButtonVariants.ChangeView}
          onClick={() =>
            dispatch(
              layoutActions.toggleModal(
                ModalViews.isShowAssignContentOrPlaylist
              )
            )
          }
        />
      </div>
      <ShareAccess
        className={styles.shareAccess}
        shareType="screen"
        heading=""
      />
    </div>
  );
}

Index.defaultProps = {
  className: '',
  variant: 'desktop',
};

export default Index;
