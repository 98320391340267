import { gql } from 'apollo-boost';

export enum Common {
  AddFolder = 'addFolder',
  GetAllFolderByCompany = 'getallFolderByCompany',
  GetFolderById = 'getFolderbyid',
}

export const addFolderMutation = gql`
    mutation ${Common.AddFolder}(
      $parentId: String
      $companyId: String!
      $name: String!
      $folderType: FolderType
      ){
        ${Common.AddFolder}(
          parentId: $parentId
          companyId: $companyId
          name: $name
          folderType: $folderType
          ){
            message
            statusCode
            data {
              id
              url
              parentFolders {
                id
                name
              }
              company
              folder_path
              name
              folderType
              created_at
              updated_at
              subFolders {
                id
                url
                parentFolders {
                  id
                  name
                }
                company
                folder_path
                name
                folderType
                created_at
                updated_at
              }
            }
        }
    }
`;

export const getAllFoldersQuery = gql`
    query ${Common.GetAllFolderByCompany}(
        $companyId: String
        $folderType: FolderType
    ){
      ${Common.GetAllFolderByCompany}(
        companyId: $companyId
        folderType: $folderType
      ){
        statusCode
        message
        data {
          id
          url
          parentFolders {
            id
                name
          }
          company
          folder_path
          name
          folderType
          created_at
          updated_at
        }
      }
    }
`;

export const getFolderByIdQuery = gql`
    query ${Common.GetFolderById}(
        $id: String
    ){
      ${Common.GetFolderById}(
        id: $id
      ){
        statusCode
        message
        data {
          id
          url
          parentFolders {
            id
                name
          }
          company
          folder_path
          name
          folderType
          created_at
          updated_at
          subFolders {
            id
            url
            parentFolders {
              id
                name
            }
            company
            folder_path
            name
            folderType
            created_at
            updated_at
          }
        }
      }
    }
`;
