/* eslint-disable */
import Content from 'components/content/content';
import EmptyState, {
  EmptyStateVariants,
} from 'components/empty-state/emptyState';
import FileUploadProgress, {
  FileUploadProgressVariants,
} from 'components/file-upload-progress/fileUploadProgress';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import FoldersSkeleton from 'components/skeletons/folders-skeleton/foldersSkeleton';
import ListingSkeleton from 'components/skeletons/listing-skeleton/listingSkeleton';
import { CONTENT_TYPE } from 'constants/index';
import Folders from 'components/folders/folders';
import { ModalViews } from 'enums';
import SubFolderEmptyState from 'components/sub-folder-empty-state/subFolderEmptyState';
import React, { useRef, useCallback, useState } from 'react';
import { useStore } from 'store';
import { s3Operations, toaster } from 'helpers';
import { contentPageActions, layoutActions } from 'store/actions';
// import { contentPageActions } from 'store/actions';
import { ContentType, iContentData, iState } from 'types';
import ContentDetails from '../content-details/contentDetails';
// import SubFolderEmptyState from '../sub-folder-empty-state/subFolderEmptyState';
import styles from './contentList.module.scss';
import { deleteContent } from 'apis';

interface iProps {
  className?: string;
  data: iContentData[];
  isLoading: boolean;
}
function ContentList(props: iProps): JSX.Element {
  const { className, data, isLoading } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { contentPage, layout } = state || {};
  const [contentToDelete, setContentToDelete] = useState<iContentData>();
  const [isContentUsedSomeWhere, setIsContentUsedSomeWhere] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { uploadingFiles, contentFolders, contents, contentActiveFolder } =
    contentPage || {};
  const { chosenView, isShowAddContent } = layout || {};

  // const removeFileFromUpload = useCallback(
  //   (index) => {
  //     dispatch(contentPageActions.removeFile(index));
  //   },
  //   [dispatch]
  // );

  const containerRef: any = useRef(null);

  // const clickOutSide = useCallback(
  //   (event: MouseEvent) => {
  //     if (
  //       containerRef.current &&
  //       !containerRef.current.contains(event.target)
  //     ) {
  //       // dispatch(layoutActions.toggleSideDrawer(false));
  //     }
  //   },
  //   [dispatch]
  // );

  // useEffect(() => {
  //   document.addEventListener('click', clickOutSide);
  //   return () => document.removeEventListener('click', clickOutSide);
  // }, [clickOutSide]);

  const openDeleteContentModal = useCallback(
    (content) => {
      setContentToDelete(content);
      dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteModal));
    },
    [dispatch]
  );

  const confirmToDelete = useCallback(async (content: iContentData) => {
    const isWebPage = content.contentType === CONTENT_TYPE.Website;
    const isDeleted = isWebPage
      ? true
      : await s3Operations.delete({
          Key: content.url,
        });

    if (isDeleted) {
      const deletedPermanently = await deleteContent(
        {
          id: content.id,
          deleteContent: true,
        },
        setIsDeleting
      );
      if (deletedPermanently === 'DELETED') {
        const newContents = contents.filter((i) => i.id !== content.id);
        dispatch(contentPageActions.setContents(newContents));
        dispatch(layoutActions.toggleModal());
        toaster.success('Content has deleted successfully');
      }
    }
  }, []);

  const deleteContentHandler = useCallback(async () => {
    if (!contentToDelete) return;

    if (isContentUsedSomeWhere) {
      confirmToDelete(contentToDelete);
    }
    const verification = await deleteContent(
      {
        id: contentToDelete.id,
        deleteContent: false,
      },
      setIsDeleting
    );
    if (verification === 'USED_SOMEWHERE') {
      setIsContentUsedSomeWhere(true);
      return;
    }
    if (verification === 'CAN_DELETE') confirmToDelete(contentToDelete);
  }, [contentToDelete]);

  return (
    <div
      className={`${className} ${styles.listAndDetails} 
      ${
        // data?.length > 0 &&
        styles.displayboth
      }`}
    >
      <div
        className={`${styles.foldersAndScreensWrapper} ${styles[chosenView]}`}
      >
        {isLoading ? (
          <FoldersSkeleton />
        ) : (
          <Folders
            className={styles.foldersWrapper}
            data={contentFolders}
            openFolder={(folder) => {
              dispatch(contentPageActions.setContentActiveFolder(folder));
            }}
          />
        )}

        {/* {contentFolders && contentFolders?.length > 0 && (
          <Folders
            className={styles.foldersWrapper}
            data={contentFolders}
            openFolder={(folder) => {
              dispatch(contentPageActions.setContentActiveFolder(folder));
            }}
          />
        )} */}
        {contentFolders &&
          contentFolders?.length === 0 &&
          !contentActiveFolder &&
          data?.length === 0 && (
            <EmptyState variant={EmptyStateVariants.Content} />
          )}
        {((contentFolders && contentFolders?.length > 0) ||
          contentActiveFolder) &&
          data?.length === 0 &&
          !isLoading && <SubFolderEmptyState />}
        {isLoading ? (
          <ListingSkeleton />
        ) : (
          data?.length > 0 && (
            <div className={styles.listContainer} ref={containerRef}>
              <div className={styles.listHeader}>
                <p>Name</p>
                <p>Type</p>
                <p>Owner</p>
                <p>Modified</p>
              </div>
              {data.map(
                (i) =>
                  i && (
                    <Content
                      data={i}
                      deleteContent={() => openDeleteContentModal(i)}
                    />
                  )
              )}
              {uploadingFiles?.length > 0 && !isShowAddContent && (
                <div className={styles.uploadingFilesContainer}>
                  {uploadingFiles.map((i) => (
                    <FileUploadProgress
                      variant={FileUploadProgressVariants.OffModal}
                      data={i}
                      // cancel={() => removeFileFromUpload(index)}
                    />
                  ))}
                </div>
              )}
            </div>
          )
        )}
      </div>

      <ContentDetails
        className={`${styles.detailView} ${styles[chosenView]}`}
        isLoading={isLoading}
      />
      {contentToDelete && (
        <DeleteModal
          variant={DeleteModalVariants.Content}
          name={contentToDelete?.name || ''}
          isLoading={isDeleting}
          contentType={contentToDelete.contentType}
          isUsedSomewhere={isContentUsedSomeWhere}
          deleteHandler={deleteContentHandler}
        />
      )}
    </div>
  );
}

ContentList.defaultProps = {
  className: '',
};

export default ContentList;
