import { iLayoutState } from 'types';
import { COMMON, LAYOUT } from '../constants';
import { initialState } from '../initialState/initialState';

export const layoutReducer = (
  state: iLayoutState = initialState.layout,
  action
): iLayoutState => {
  switch (action.type) {
    case LAYOUT.setWindowWidth: {
      const temp = state;
      temp.windowWidth = action.payload;
      return temp;
    }
    case LAYOUT.setHomePageView: {
      const temp = state;
      temp.chosenView = action.payload;
      return temp;
    }
    case LAYOUT.openAddContentModal: {
      const { isShowAddContent } = state;
      return { ...state, isShowAddContent: !isShowAddContent };
    }
    case LAYOUT.openAddWebContentModal: {
      const { isShowAddWebContent } = state;
      return { ...state, isShowAddWebContent: !isShowAddWebContent };
    }
    case LAYOUT.toggleSideDrawer: {
      const temp = {
        isShowPlaylistPreview: false,
        isShowContentPreview: false,
      };
      if (action.payload) temp[action.payload] = !temp[action.payload];

      return {
        ...state,
        ...temp,
      };
    }
    case LAYOUT.setShowClientsInstance: {
      return {
        ...state,
        isShowingClientInstance: action.payload,
      };
    }
    case LAYOUT.invokeGetFolderById: {
      return {
        ...state,
        invokeGetFolderById: action.payload,
      };
    }
    case LAYOUT.setAssignModalContentFolders: {
      return {
        ...state,
        assignModalContentFolders: action.payload,
      };
    }
    case LAYOUT.setAssignModalContentActiveFolders: {
      return {
        ...state,
        assignModalContentActiveFolder: action.payload,
      };
    }
    case LAYOUT.setAssignModalContentsFolderHierarchy: {
      return {
        ...state,
        assignModalContentsFolderHierarchy: action.payload,
      };
    }
    case LAYOUT.setEmptyStates: {
      try {
        const rawEmptyStates = action.payload.filter((i) =>
          [
            'playlists_empty_state',
            'content_empty_state',
            'screens_empty_state',
            'clients_empty_state',
          ].includes(i.sysc_key)
        );
        const emptyStates = rawEmptyStates.map((i) => ({
          id: i.id,
          key: i.sysc_key,
          ...JSON.parse(i.sysc_value),
        }));

        return {
          ...state,
          emptyStates,
        };
      } catch (error) {
        console.log(error);
        return state;
      }
    }

    case LAYOUT.initialSidebarState: {
      return {
        ...state,
        initialSidebarState: action.payload,
      };
    }
    case LAYOUT.invokeGetAllFolders: {
      return {
        ...state,
        invokeGetAllFolders: action.payload,
      };
    }

    case LAYOUT.toggleModal: {
      const temp = {
        ...state,
        isShowAddContent: false,
        isShowAddWebContent: false,
        isShowContentPreview: false,
        isShowAddPlaylist: false,
        isShowAddFolderForPlaylists: false,
        isShowAddContentToPlaylist: false,
        isShowAddTeammate: false,
        isShowAddClient: false,
        isShowAddScreen: false,
        isShowAddFolder: false,
        isShowDeleteModal: false,
        isShowAssignContentOrPlaylist: false,
        isShowShareAccess: false,
        isShowSetUp2FA: false,
        isShowDisable2FA: false,
      };
      if (action.payload) temp[action.payload] = !state[action.payload];
      console.log({ temp });

      return temp;
    }

    case COMMON.reset: {
      return initialState.layout;
    }
    default:
      return state;
  }
};
