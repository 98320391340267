import { Mutation, Query } from 'config';
import { DevicePowerAction, RemoteActions } from 'enums';
import { toaster } from 'helpers';
import { captureException } from 'helpers/sentry';
import { ROUTES } from 'constants/index';
import {
  iAddScreenResponse,
  iAddScreensArgs,
  iDeleteScreen,
  iDeleteScreenByIdArgs,
  iDeleteScreenResponse,
  iGetAllScreensResponse,
  iGetScreensByCompanyIdArgs,
  iGetScreensByCompanyIdResponse,
  iGetScreensByIdArgs,
  iGetScreensByIdResponse,
  iGraphqlResponse,
  iRemoteActionsArgs,
  iScreenData,
  iSearchScreenResponse,
  iSearchScreensArgs,
  iUpdateScreenResponse,
  iUpdateScreensArgs,
  iUpdateScreenListResponse,
  iUpdateScreenOrderArgs,
} from 'types';
import {
  addScreenMutation,
  changeBrightnessMutation,
  changeVolumeMutation,
  deleteScreenByIdMutation,
  devicePowerActionMutation,
  getAllScreensQuery,
  getScreenByIdQuery,
  getScreensByCompanyIdQuery,
  searchScreenMutation,
  updateScreenMutation,
  updateScreenListMutation,
} from '../graphql';

export const addScreen = async (
  variables: iAddScreensArgs,
  isLoading: (type: boolean) => void
): Promise<iScreenData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iAddScreenResponse>>(
      await Mutation(addScreenMutation, variables)
    );
    isLoading(false);
    return response.data.addScreen.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error.message);
    captureException(error);
    return null;
  }
};

export const updateScreen = async (
  variables: iUpdateScreensArgs,
  isLoading: (type: boolean) => void
): Promise<iScreenData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iUpdateScreenResponse>>(
      await Mutation(updateScreenMutation, variables)
    );
    isLoading(false);
    return response.data.updateScreen.data;
  } catch (error: any) {
    isLoading(false);
    toaster.error(error.message);
    captureException(error);
    return null;
  }
};

export const searchScreens = async (
  variables: iSearchScreensArgs,
  isLoading: (type: boolean) => void
): Promise<Array<iScreenData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iSearchScreenResponse>>(
      await Mutation(searchScreenMutation, variables)
    );
    isLoading(false);
    return response.data.searchscreenbyname.data;
  } catch (error) {
    isLoading(false);
    return null;
  }
};

export const getScreens = async (
  isLoading
): Promise<Array<iScreenData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetAllScreensResponse>>(
      await Query(getAllScreensQuery)
    );
    isLoading(false);
    return response.data.getallScreens.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const getScreensByCompany = async (
  args: iGetScreensByCompanyIdArgs,
  isLoading
): Promise<Array<iScreenData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetScreensByCompanyIdResponse>>(
      await Query(getScreensByCompanyIdQuery, args)
    );
    isLoading(false);
    return response.data.getscreensbycompanyid.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const getScreenById = async (
  args: iGetScreensByIdArgs,
  isLoading
): Promise<iScreenData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetScreensByIdResponse>>(
      await Query(getScreenByIdQuery, args)
    );
    isLoading(false);
    return response.data.getscreensbyid.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const deleteScreenById = async (
  args: iDeleteScreenByIdArgs
): Promise<iDeleteScreen | null> => {
  try {
    const response = <iGraphqlResponse<iDeleteScreenResponse>>(
      await Mutation(deleteScreenByIdMutation, args)
    );
    return response.data.deleteScreen;
  } catch (error: any) {
    captureException(error);
    return null;
  }
};

export const updateDragAndDropList = async (
  args: iUpdateScreenOrderArgs[]
): Promise<boolean | null> => {
  try {
    const response = <iGraphqlResponse<iUpdateScreenListResponse>>(
      await Mutation(updateScreenListMutation, { screens: args })
    );
    if (response) {
      return true;
    }
    return false;
  } catch (error: any) {
    captureException(error);
    return null;
  }
};

export const remoteActions = async (
  { action, screenId, volume, brightness }: iRemoteActionsArgs,
  isLoading,
  navigate?: any
): Promise<any | null> => {
  try {
    isLoading(true);
    if (action === RemoteActions.Volume) {
      const response = await Mutation(changeVolumeMutation, {
        screenId,
        volume,
      });
      if (response) {
        isLoading(false);
        return response;
      }
    }
    if (action === RemoteActions.Brightness) {
      const response = await Mutation(changeBrightnessMutation, {
        screenId,
        brightness,
      });
      if (response) {
        isLoading(false);
        return response;
      }
    }
    if (action === RemoteActions.Reboot) {
      const response = await Mutation(devicePowerActionMutation, {
        screenId,
        devicePowerAction: DevicePowerAction.SYSTEM_REBOOT,
      });
      if (response) {
        isLoading(false);
        return response;
      }
    }
    if (action === RemoteActions.DisplayOff) {
      const response = await Mutation(devicePowerActionMutation, {
        screenId,
        devicePowerAction: DevicePowerAction.DISPLAY_POWER_OFF,
      });
      if (response) {
        isLoading(false);
        return response;
      }
    }
    if (action === RemoteActions.DisplayOn) {
      const response = await Mutation(devicePowerActionMutation, {
        screenId,
        devicePowerAction: DevicePowerAction.DISPLAY_POWER_ON,
      });
      if (response) {
        isLoading(false);
        return response;
      }
    }
    if (action === RemoteActions.RestartApp) {
      const response = await Mutation(devicePowerActionMutation, {
        screenId,
        devicePowerAction: DevicePowerAction.APP_RESTART,
      });
      if (response) {
        isLoading(false);
        return response;
      }
    }
    return null;
  } catch (error: any) {
    captureException(error);
    if (error.statusCode === 400) {
      navigate(ROUTES.home);
      toaster.error('Device not found');
    }
    return null;
  }
};
