export const ENVIRONMENT = {
  local: {
    endpoint: 'http://localhost:3000/graphql',
    auth0: {
      domain: '',
      clientId: '',
      realm: '',
    },
  },
  dev: {
    endpoint: 'https://api-dev.screenz.no/graphql',
  },
  staging: {
    endpoint: 'https://api-staging.screenz.no/graphql',
  },
  prod: {
    endpoint: 'https://api-prod.screenz.no/graphql',
  },
};

const { endpoint } = ENVIRONMENT.prod;
export const API_ENDPOINT = endpoint;
