import OnlineStatusPill from 'components/online-status-pill/onlineStatusPill';
import {
  arrowRightIcon,
  layoutsIcon,
  logoBoxIcon,
  ROUTES,
} from 'constants/index';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { getOnlineStatus } from 'helpers';
import { homePageActions } from 'store/actions';
import { iScreenData } from 'types';
import Remote from './remote-wrapper/remoteWrapper';
import Screenshot from './screenshot/screenshot';
import styles from './screenTile.module.scss';

interface iProps {
  className?: string;
  data: iScreenData;
}

function ScreenTile(props: iProps): JSX.Element {
  const { className, data } = props;
  const [, dispatch] = useStore();
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log(data);
  // }, []);
  const {
    // isConnectedScreen,
    // isOnline,

    // screenName,
    // contentName,
    // deviceName,
    // screenshot,
    hashCode,
    name,
    // isOnline = false,
    screenShotUrl,
    // deviceName,
    currentPlayList,
    currentContent,
    lastPing,
    modelName,
  } = data || {};
  const contentName =
    (currentContent && currentContent.name) ||
    (currentPlayList && currentPlayList.name) ||
    'No content assigned';

  const isConnectedScreen = (hashCode !== null && true) || false;

  // const getOnlineStatus = useCallback((lastPingTime) => {
  //   const ONE_MINUT = 60000;
  //   const difference = new Date().getTime() - new Date(lastPingTime).getTime();
  //   if (difference < ONE_MINUT) return true;
  //   return false;
  // }, []);

  return (
    <div
      key={data.id}
      id={data.id}
      className={`${className} ${styles.screenTile}`}
    >
      {isConnectedScreen && (
        // <Remote
        //   className={`${styles.remoteIcon} ${!isOnline && styles.offline}`}
        // />
        <Remote
          className={styles.remoteOnHover}
          isOnline={lastPing ? getOnlineStatus(lastPing) : false}
          screenId={data.id}
        />
      )}
      <div
        className={styles.topSection}
        onClick={() => {
          dispatch(homePageActions.setScreenzId(data.id));
          dispatch(homePageActions.setScreenData(data));
          navigate(ROUTES.screenDetails);
        }}
      >
        <Screenshot
          screenshot={screenShotUrl || ''}
          isConnectedScreen={isConnectedScreen}
          isOnline={lastPing ? getOnlineStatus(lastPing) : false}
        />
        <div className={styles.screenNameHolder} id={`${data.id}`}>
          <p className={styles.clientName}>{name}</p>
          <div className={styles.layoutName}>
            <img src={layoutsIcon} alt="" />
            <span>{contentName}</span>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        {!isConnectedScreen ? (
          <p
            className={styles.assignToScreen}
            onClick={() => {
              dispatch(homePageActions.setScreenzId(data.id));
              dispatch(homePageActions.setScreenData(data));
              navigate(ROUTES.screenDetails);
            }}
          >
            Assign screen to get started
            <img src={arrowRightIcon} alt="" />
          </p>
        ) : (
          <>
            <section className={styles.left}>
              <div className={styles.deviceName}>
                <img src={logoBoxIcon} alt="" />
                <span onClick={() => navigate(ROUTES.screenDetails)}>
                  {modelName}
                </span>
              </div>
            </section>
            <section className={styles.right}>
              <OnlineStatusPill
                isOnline={lastPing ? getOnlineStatus(lastPing) : false}
              />
            </section>
          </>
        )}
      </div>
    </div>
  );
}

ScreenTile.defaultProps = {
  className: '',
  // id: '',
};

export default ScreenTile;
