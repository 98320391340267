import { LayoutViews, ModalViews, SideDrawers } from 'enums';
import { iFolderData, iParentFolders, iSystemCOnfigData } from 'types';
import { LAYOUT } from '../constants';

interface iActions {
  type: string;
  payload?: any;
}

export const layoutActions = {
  setWindowWidth: (width: number): iActions => ({
    type: LAYOUT.setWindowWidth,
    payload: width,
  }),
  invokeGetAllFolders: (data: boolean): iActions => ({
    type: LAYOUT.invokeGetAllFolders,
    payload: data,
  }),
  invokeGetFolderById: (data: boolean): iActions => ({
    type: LAYOUT.invokeGetFolderById,
    payload: data,
  }),
  setHomePageView: (view: LayoutViews): iActions => ({
    type: LAYOUT.setHomePageView,
    payload: view,
  }),
  openAddContentModal: (): iActions => ({
    type: LAYOUT.openAddContentModal,
  }),
  toggleSideDrawer: (sideDrawer?: SideDrawers): iActions => ({
    type: LAYOUT.toggleSideDrawer,
    payload: sideDrawer,
  }),
  setShowClientsInstance: (isShow: boolean): iActions => ({
    type: LAYOUT.setShowClientsInstance,
    payload: isShow,
  }),
  setEmptyStates: (systemConfig: iSystemCOnfigData[]): iActions => ({
    type: LAYOUT.setEmptyStates,
    payload: systemConfig,
  }),
  toggleModal: (modal?: ModalViews) => ({
    type: LAYOUT.toggleModal,
    payload: modal || false,
  }),
  initialSidebarState: (active: boolean): iActions => ({
    type: LAYOUT.initialSidebarState,
    payload: active,
  }),
  setAssignModalContentFolders: (folder: iFolderData[] | []): iActions => ({
    type: LAYOUT.setAssignModalContentFolders,
    payload: folder,
  }),
  setAssignModalContentsFolderHierarchy: (
    data: iParentFolders[] | []
  ): iActions => ({
    type: LAYOUT.setAssignModalContentsFolderHierarchy,
    payload: data,
  }),
  setAssignModalContentActiveFolders: (
    folder: iFolderData | null
  ): iActions => ({
    type: LAYOUT.setAssignModalContentActiveFolders,
    payload: folder,
  }),
};
