import React, { useEffect, useState, useCallback } from 'react';
import { iPlaylistContents } from 'types';
import { closeIcon, CONTENT_TYPE, eyeIcon, refreshIcon } from 'constants/index';
import styles from './playlistPreview.module.scss';

interface iProps {
  className?: string;
  data: iPlaylistContents[];
  close: () => void;
}

function PlaylistPreview(props: iProps): JSX.Element {
  const { className, data, close } = props;
  const [content, setContent] = useState<iPlaylistContents>();

  const preparePlaylistPreview = useCallback(
    async (refresh?: boolean) => {
      if (!Array.isArray(data)) return;

      /* eslint no-restricted-syntax: ["error"] */
      let index = 0;
      for (const element of data) {
        if (refresh) break;
        setContent(element);
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(1);
          }, Number(element.duration) * 1000);
        });
        if (index === data.length - 1) {
          index = 0;
          preparePlaylistPreview();
        } else {
          index += 1;
        }
      }
    },
    [data]
  );

  useEffect(() => {
    if (data) {
      preparePlaylistPreview();
    }
  }, [data, preparePlaylistPreview]);

  return (
    <div className={`${styles.playlistPreview} ${className}`}>
      <div className={styles.drawerHeader}>
        <div className={styles.colOne}>
          <img src={eyeIcon} alt="" />
          <p className={styles.previewHeading}>Preview Playlist</p>
        </div>
        <div className={styles.colTwo}>
          <div
            className={`${styles.refreshIcon} `}
            onClick={() => preparePlaylistPreview(true)}
          >
            <img src={refreshIcon} alt="" />
          </div>

          <img
            className={styles.closeIcon}
            src={closeIcon}
            alt=""
            onClick={close}
          />
        </div>
      </div>
      <div className={`${styles.section} ${styles.playlistPreview}`}>
        {content && (
          <div className={styles.playlistPreview}>
            {content?.contentType === CONTENT_TYPE.Image && (
              <img
                className={styles.contentPreview}
                src={content?.url}
                alt=""
              />
            )}
            {content?.contentType === CONTENT_TYPE.Video && (
              <video preload="none" src={content?.url} controls autoPlay>
                <source src={content?.url} type="video/mp4" />
                <track src="captions_en.vtt" kind="captions" />
              </video>
            )}
            {content?.contentType === CONTENT_TYPE.Website && (
              <iframe
                className={styles.iframe}
                src={content?.url}
                title="web"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

PlaylistPreview.defaultProps = {
  className: '',
};

export default PlaylistPreview;
