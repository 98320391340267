import { getPlaylistsById, updatePlaylist } from 'apis';
import AddModal, { AddModalVariants } from 'components/add-modal/addModal';
import AssignContentModal from 'components/assign-content-modal/assignContentModal';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import Loader from 'components/loader/loader';
import UploadContentModal from 'components/upload-content-modal/uploadContentModal';
import { CONTENT_TYPE, ROUTES } from 'constants/index';
import { LayoutViews } from 'enums';
import { calculateContentRuntime, toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { layoutActions, playlistPageActions } from 'store/actions';
import { iPlaylistContents, iState } from 'types';
import Details from './components/details/details';
import EmptyState from './components/empty-state/emptyState';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import PlaylistContent from './components/playlist-content/playlistContent';
import Subheader from './components/subheader/subheader';
import styles from './playlistView.module.scss';

function PlaylistView(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { chosenView } = state.layout || {};
  const { playlistData, canUpdatePlaylist, contentToAddToPlaylist } =
    state.playlistsPage || {};
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { companyData } = state.homePage || {};
  const [latePlaylistName, setLatePlaylistName] = useState<boolean>(false);
  const { id = '', playListContents = [] } = playlistData || {};

  const navigate = useNavigate();

  const cancel = useCallback(() => {
    navigate(ROUTES.playlists);
  }, [navigate]);

  useEffect(() => {
    dispatch(layoutActions.setHomePageView(LayoutViews.Playlist));
  }, [dispatch]);

  const getPlaylist = useCallback(async () => {
    const response = await getPlaylistsById({ id }, () => {});
    if (response) {
      dispatch(playlistPageActions.setPlaylistData(response));
    }
  }, [dispatch, id]);

  useEffect(() => {
    getPlaylist();
  }, [getPlaylist]);

  const getVideoLength = useCallback((content) => {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = (): void => {
        window.URL.revokeObjectURL(video.src);
        resolve(Math.floor(video.duration));
      };
      video.src = content.url || '';
    });
  }, []);

  const updatePlaylistHandler = useCallback(async () => {
    if (!playlistData) return;
    const { id: companyId = '' } = companyData || {};
    setIsLoading(true);
    const prepareContents: iPlaylistContents[] = [];
    if (playListContents && playListContents?.length > 0)
      playListContents.map(async (i, index) => {
        if (i.contentType === CONTENT_TYPE.Video) {
          prepareContents.push({
            id: i.id,
            duration: i.duration,
            sortOrder: i.sortOrder || index,
          });
        } else {
          const duration = i.duration || '10';
          prepareContents.push({
            id: i.id,
            duration,
            sortOrder: i.sortOrder || index,
          });
        }
        return null;
      });

    if (contentToAddToPlaylist?.length > 0)
      await Promise.all([
        ...contentToAddToPlaylist.map(async (i, index) => {
          if (i.contentType === CONTENT_TYPE.Video) {
            const duration = await getVideoLength(i);
            prepareContents.push({
              id: i.id,
              duration: `${duration}`,
              sortOrder: playListContents.length + index,
            });
          } else {
            const duration = i.duration || '10';
            prepareContents.push({
              id: i.id,
              duration,
              sortOrder: playListContents.length + index,
            });
          }

          return null;
        }),
      ]);
    const playlistDuration = calculateContentRuntime(prepareContents);
    const response = await updatePlaylist(
      {
        ...playlistData,
        duration: playlistDuration,
        companyId,
        contents: prepareContents,
      },
      () => {}
    );
    if (response) {
      toaster.success(
        isDeleting
          ? 'Content deleted successfully'
          : 'Playlist updated successfully'
      );
      getPlaylist();
      dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(true));
      dispatch(playlistPageActions.invokeUpdatePlaylist(false));
      setIsLoading(false);
      dispatch(playlistPageActions.clearAddContentToPlaylist());
      if (!isDeleting && contentToAddToPlaylist.length === 0)
        navigate(ROUTES.playlists);
    } else {
      setIsLoading(false);
      dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(true));
      dispatch(playlistPageActions.invokeUpdatePlaylist(false));
    }
  }, [
    playlistData,
    dispatch,
    companyData,
    contentToAddToPlaylist,
    isDeleting,
    getPlaylist,
    playListContents,
    getVideoLength,
    navigate,
  ]);

  useEffect(() => {
    if (canUpdatePlaylist) updatePlaylistHandler();
  }, [canUpdatePlaylist, updatePlaylistHandler]);

  useEffect(() => {
    return () => {
      dispatch(layoutActions.toggleSideDrawer());
    };
  }, [dispatch]);

  return (
    <Layout variant={LayoutVariants.layoutOne}>
      <div className={styles.playlistView}>
        <Header latePlaylistName={latePlaylistName} />
        <div className={styles.mainContent}>
          <Subheader className={styles.mobileSubheader} />
          <div className={`${styles.colOne} ${styles[chosenView]}`}>
            <Subheader className={styles.desktopSubheader} />
            {isLoading ? (
              <Loader
                message="It is being processed, It may take some
                  time,  please wait!"
              />
            ) : (
              <div className={styles.main}>
                {playListContents && playListContents?.length === 0 && (
                  <EmptyState />
                )}
                {playListContents && playListContents?.length > 0 && (
                  <PlaylistContent
                    data={playListContents}
                    setIsDeleting={setIsDeleting}
                  />
                )}
              </div>
            )}
          </div>
          <Details
            setLatePlaylistName={setLatePlaylistName}
            className={`${styles.detailView} ${styles[chosenView]}`}
          />
          <AssignContentModal />
          <UploadContentModal />
        </div>
        <AddModal variant={AddModalVariants.DuplicatePlaylist} />
        <Footer onClickCancel={cancel} />
      </div>
    </Layout>
  );
}

export default PlaylistView;
