import {
  iUploadingFile,
  iContentData,
  iFolderData,
  iParentFolders,
  iAddWebContentData,
} from 'types';
import { CONTENT_PAGE } from '../constants';

interface iActions {
  type: string;
  payload?: any;
}

export const contentPageActions = {
  uploadFiles: (data: iUploadingFile[] | []): iActions => ({
    type: CONTENT_PAGE.setUploadingFiles,
    payload: data,
  }),
  updateUploadStatus: (data: iUploadingFile): iActions => ({
    type: CONTENT_PAGE.updateUploadingStatus,
    payload: data,
  }),
  setContents: (data: iContentData[] | []): iActions => ({
    type: CONTENT_PAGE.setContents,
    payload: data,
  }),
  fetchContentsData: (data: boolean): iActions => ({
    type: CONTENT_PAGE.fetchContentsData,
    payload: data || false,
  }),
  setContentView: (data: iContentData | null): iActions => ({
    type: CONTENT_PAGE.setContentView,
    payload: data,
  }),
  removeFile: (index: File[]): iActions => ({
    type: CONTENT_PAGE.removeFile,
    payload: index,
  }),
  setAddWebContentData: (data: iAddWebContentData | null): iActions => {
    return {
      type: CONTENT_PAGE.setAddWebContentData,
      payload: data,
    };
  },
  clearUploadingFiles: (): iActions => ({
    type: CONTENT_PAGE.clearUploadingFiles,
  }),
  addEmptyState: (emptyState: string): iActions => ({
    type: CONTENT_PAGE.emptyState,
    payload: emptyState,
  }),
  setContentFolderHierarchy: (data: iParentFolders[] | []): iActions => ({
    type: CONTENT_PAGE.setContentFolderHierarchy,
    payload: data,
  }),
  showCancelAndSaveBtns: (): iActions => ({
    type: CONTENT_PAGE.showCancelAndSaveBtns,
  }),
  setSearchContentKey: (key: string): iActions => ({
    type: CONTENT_PAGE.setSearchContentKey,
    payload: key,
  }),
  setContentFolders: (folders: iFolderData[] | []): iActions => ({
    type: CONTENT_PAGE.setContentFolders,
    payload: folders,
  }),
  setContentActiveFolder: (folder: iFolderData | null): iActions => ({
    type: CONTENT_PAGE.setContentActiveFolder,
    payload: folder,
  }),
  stateReset: (): iActions => ({
    type: CONTENT_PAGE.resetEmptyAndBtns,
  }),
};
