import Logo from 'components/logo/logo';
import More from 'components/svg/more';
import { ROUTES } from 'constants/index';
import { LayoutViews, UserRoles } from 'enums';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import {
  contentPageActions,
  homePageActions,
  layoutActions,
} from 'store/actions';
import { iState } from 'types';
import ClientDropdown from './clients-list-dropdown/clientsListDropdown';
import Profile from './profile/profile';
import styles from './roleBasedHeader.module.scss';

interface iProps {
  className?: string;
}

function RoleBasedHeader(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { role } = state.user || {};
  const { layout } = state || {};
  const { companyData } = state.homePage || {};
  // const [containerActive, setContainerActive] = useState(false);
  // const { companies = [], companyData } = state.homePage;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  // useEffect(() => {
  //   dispatch(layoutActions.initialSidebarState(false));
  // }, []);
  const userRole =
    (role === UserRoles.SuperAdmin && 'Superuser Admin') ||
    (role === UserRoles.Reseller && 'Reseller Admin');

  const activeBtn =
    !layout.isShowingClientInstance && pathname !== ROUTES.emptyStates;
  // const activeClient =
  //   companyData || (companies?.length > 0 && companies[0]) || null;

  const onClickSuperAdmin = useCallback(() => {
    dispatch(contentPageActions.setContents([]));
    dispatch(homePageActions.setCompanyData(null));
    dispatch(layoutActions.setShowClientsInstance(false));
    dispatch(layoutActions.initialSidebarState(false));
    dispatch(layoutActions.setHomePageView(LayoutViews.SuperAdminClients));

    navigate('/');
  }, [navigate, dispatch]);

  useEffect(() => {
    if (pathname === (ROUTES.emptyStates || ROUTES.roles)) return;
    if (!companyData) onClickSuperAdmin();
  }, [companyData, onClickSuperAdmin, pathname]);

  return (
    <div className={`${className} ${styles.roleBasedHeader}`}>
      <section className={styles.left}>
        <Logo className={styles.logo} variant={`${`sidebar`}`} />
        <span
          className={`${styles.userType} ${activeBtn && styles.active}`}
          onClick={onClickSuperAdmin}
        >
          {userRole}
        </span>
        <div
          // onClick={() => setContainerActive(true)}
          // onClick={() => {

          // }}
          className={`${styles.clientsContainer} ${
            layout.isShowingClientInstance &&
            pathname !== ROUTES.emptyStates &&
            styles.active
          }`}
        >
          <ClientDropdown />
        </div>
        <span
          className={`${styles.emptyState} ${
            pathname === ROUTES.emptyStates && styles.active
          }`}
          onClick={() => navigate(ROUTES.emptyStates)}
        >
          Empty States
        </span>
        <span
          className={`${styles.emptyState} ${
            pathname === ROUTES.roles && styles.active
          }`}
          onClick={() => navigate(ROUTES.roles)}
        >
          Roles
        </span>
      </section>
      <section className={styles.right}>
        <Profile />
        <More className={styles.moreIcon} />
      </section>
    </div>
  );
}

RoleBasedHeader.defaultProps = {
  className: '',
};

export default RoleBasedHeader;
