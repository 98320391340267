import { gql } from 'apollo-boost';

export enum Content {
  AddContent = 'addContent',
  GetContentByCompanyId = 'getcontentbyCompanyid',
  SearchContentByName = 'searchContentbyname',
  DeleteContent = 'deleteContent',
}

export const getContentByCompanyQuery = gql`
  query ${Content.GetContentByCompanyId}($company: String, $folder:String){
    ${Content.GetContentByCompanyId}(company: $company, folder: $folder){
      statusCode
      message
      data {
        id
        name
        url
        contentType
        size
        dimension
        thumbnailPath
        updated_at
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export const addContentMutation = gql`
    mutation ${Content.AddContent}(
      $name: String!
      $url: String!
      $contentType: String
      $size: String
      $dimension: String
      $folder: String
      $thumbnailPath: String
      $company: String!
      $user: String!
      ){
        ${Content.AddContent}(
          name: $name
          url: $url
          contentType: $contentType
          size: $size
          dimension: $dimension
          folder: $folder
          thumbnailPath: $thumbnailPath
          company: $company
          user: $user
          ){
            message
            statusCode
            data {
              id
              name
              url
              contentType
              size
              dimension
              folder
              thumbnailPath
            }
        }
    }
`;

export const searchContentMutation = gql`
    mutation ${Content.SearchContentByName}(
      $name: String
      $company: String!
      $folder: String
      ){
        ${Content.SearchContentByName}(
          name: $name
          company: $company
          folder: $folder
          ){
            message
            statusCode
            data {
              id
              name
              url
              contentType
              size
              dimension
              thumbnailPath
            }
        }
    }
`;

export const deleteContentMutation = gql`
  mutation ${Content.DeleteContent}($id: String!, $deleteContent: Boolean!){
    ${Content.DeleteContent}(id: $id, deleteContent: $deleteContent){
      statusCode
      message
      data {
        isDeleted
        screenz {
          name
        }
        playLists {
          name
        }
      }
    }
  }
`;

export const editWebContentMutation = gql`
  mutation updateContent($id: String!, $name: String) {
    updateContent(id: $id, name: $name) {
      statusCode
      message
      data {
        id
        name
      }
    }
  }
`;
