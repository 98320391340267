import Button, { ButtonVariants } from 'components/button/button';
import { resendInvitation, revokeInvitation } from 'apis';
import { toaster } from 'helpers';
// import More from 'components/svg/more';
// import Popover from 'components/popover/popover';
import React, { useCallback, useState } from 'react';
import InvitedMobileView from './components/all-team-members/invited-member-list/components/invited-mobile-view/invitedMobileView';
import Invited from './components/all-team-members/invited-member-list/Invited';
import TeamMembersMobileView from './components/all-team-members/team-member-list/components/team-members-mobile-view/teamMembersMobileView';
import TeamMemberList from './components/all-team-members/team-member-list/TeamMemberList';
import styles from './teamMembers.module.scss';

function TeamMember(): JSX.Element {
  const [toggleState, setToggleState] = useState(1);
  const [toggleMobState, setToggleMobState] = useState(3);
  const [isReSendingInvite, setIsReSendingInvite] = useState<boolean>(false);
  const [isRevokingInvite, setIsRevokingInvite] = useState<boolean>(false);
  const [resendingInviteTo, setResendingInviteTo] = useState<string>('');
  const [revokingInviteTo, setRevokingInviteTo] = useState<string>('');

  const toggleTab = useCallback((index) => {
    setToggleState(index);
  }, []);

  const toggleMobTab = useCallback((index) => {
    setToggleMobState(index);
  }, []);

  const resendInvitationHandler = useCallback(async (data) => {
    // console.log('resendInvitationHandler', data);

    // if ([]?.length === 0) return;
    setResendingInviteTo(data.id);
    const response = await resendInvitation(
      { id: data.id, email: data.email },
      setIsReSendingInvite
    );
    if (response) {
      toaster.success('Successfully re-sent invite');
      setResendingInviteTo('');
    }
  }, []);

  const revokeInvitationHandler = useCallback(async (data) => {
    // console.log('revokeInvitationHandler', data);

    // if ([]?.length === 0) return;
    setRevokingInviteTo(data.id);
    const response = await revokeInvitation(
      { id: data.id, email: data.email },
      setIsRevokingInvite
    );
    if (response) {
      toaster.success('Successfully revoked invite');
      setRevokingInviteTo('');
    }
  }, []);

  console.log(resendInvitationHandler, revokeInvitationHandler);

  return (
    <>
      <div className={styles.teamMembersContainer}>
        <div className={styles.tabList}>
          <Button
            className={
              toggleState === 1 ? styles.activeTabs : styles.notActiveTabs
            }
            onClick={() => toggleTab(1)}
            btnName="Team Members"
            variant={ButtonVariants.SmallStd}
          />
          <Button
            className={
              toggleState === 2 ? styles.activeTabs : styles.notActiveTabs
            }
            onClick={() => toggleTab(2)}
            btnName="Invited"
            variant={ButtonVariants.SmallStd}
          />
        </div>
        <section className={styles.tabDetails}>
          {toggleState === 1 ? (
            <TeamMemberList />
          ) : (
            <Invited
              resendingInviteTo={resendingInviteTo}
              revokingInviteTo={revokingInviteTo}
              isResending={isReSendingInvite}
              isRevoking={isRevokingInvite}
              resend={resendInvitationHandler}
              revoke={revokeInvitationHandler}
            />
          )}
        </section>
      </div>
      <div className={styles.teamMembersMobContainer}>
        <div className={styles.mobTabList}>
          <Button
            className={
              toggleMobState === 3
                ? styles.activeMobTabs
                : styles.notActiveMobTabs
            }
            onClick={() => toggleMobTab(3)}
            btnName="Team Members"
            variant={ButtonVariants.SmallStd}
          />
          <Button
            className={
              toggleMobState === 4
                ? styles.activeMobTabs
                : styles.notActiveMobTabs
            }
            onClick={() => toggleMobTab(4)}
            btnName="Invited"
            variant={ButtonVariants.SmallStd}
          />
        </div>
        <section className={styles.tabMobDetails}>
          {toggleMobState === 3 ? (
            <TeamMembersMobileView className="" />
          ) : (
            <InvitedMobileView
              className=""
              resendingInviteTo={resendingInviteTo}
              revokingInviteTo={revokingInviteTo}
              isResending={isReSendingInvite}
              isRevoking={isRevokingInvite}
              resend={resendInvitationHandler}
              revoke={revokeInvitationHandler}
            />
          )}
        </section>
      </div>
    </>
  );
}

export default TeamMember;
