import React from 'react';
import styles from './authenticateVerifyCode.module.scss';

interface iProps {
  className?: string;
  setCodeToVerify: (code: string) => void;
}

function AuthenticateVerifyCode(props: iProps): JSX.Element {
  const { className, setCodeToVerify } = props;

  return (
    <div className={`${styles.authenticateVerifyCode} ${className}`}>
      <p className={styles.verifyCodeHeading}>We have sent you an email</p>
      <p className={styles.verifyCodeDescription}>
        Check your email for the code, make sure to check your spam folder if
        you cant find it.
      </p>

      <p className={styles.verificationCodeToEmail}>Verification Code</p>
      <input
        className={styles.verifyCodeInput}
        type="text"
        placeholder=""
        onChange={(event) => setCodeToVerify(event.target.value)}
      />

      <p className={styles.sendNewCode}>Send new code?</p>
    </div>
  );
}

AuthenticateVerifyCode.defaultProps = {
  className: '',
};

export default AuthenticateVerifyCode;
