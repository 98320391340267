import ContentPreview from 'components/content-preview/contentPreview';
import { CONTENT_TYPE, dustbinIcon, editIcon } from 'constants/index';
import React, { useCallback } from 'react';

import { useStore } from 'store';
import { SideDrawers } from 'enums';
import More from 'components/svg/more';
import Popover from 'components/popover/popover';
import { contentPageActions, layoutActions } from 'store/actions';
import { iContentData, iState } from 'types';
import styles from './content.module.scss';

interface iProps {
  className?: string;
  data: iContentData;
  deleteContent?: () => void;
}

function Content(props: iProps): JSX.Element {
  const { className, data, deleteContent } = props;
  const [, dispatch]: [iState, any] = useStore();

  const openDetailView = useCallback(() => {
    // if (data.contentType !== CONTENT_TYPE.Website) {
    dispatch(layoutActions.toggleSideDrawer(SideDrawers.isShowContentPreview));
    dispatch(contentPageActions.setContentView(data));
    // }
  }, [dispatch, data]);

  // const editWebContent = useCallback(() => {
  //   dispatch(layoutActions.toggleSideDrawer(SideDrawers.isShowContentPreview));
  //   dispatch(contentPageActions.setContentView(data));
  // }, [dispatch, data]);

  const date = (data.updated_at && new Date(data.updated_at)) || '';
  return (
    <div className={`${styles.content} ${className}`} onClick={openDetailView}>
      <div className={styles.previewAndMeta}>
        <ContentPreview data={data} />
        <div className={styles.contentNameAndLength}>
          <p className={styles.contentName}>{data.name}</p>
          <p className={styles.contentMeta}>
            {data.contentType} /{' '}
            {data.contentType === CONTENT_TYPE.Website ? (
              <p className={styles.url}>{data.url}</p>
            ) : (
              data.dimension
            )}
          </p>
        </div>
      </div>
      <p className={styles.type}>{data.contentType}</p>
      <p className={styles.ownerName}>
        {data?.user?.first_name} {data?.user?.last_name}
      </p>
      <p className={styles.modifiedOn}>
        {date &&
          `${date.getDate()}.${
            date.getMonth() + 1
          }.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()} `}
      </p>

      <Popover
        className={styles.morePopupContainer}
        popoverClassName={styles.popover}
        popoverComponent={
          <div className={styles.morePopup}>
            {data.contentType === CONTENT_TYPE.Website && (
              <div className={styles.option} onClick={openDetailView}>
                <img src={editIcon} alt="" />
                <p>Edit</p>
              </div>
            )}
            <div className={styles.option} onClick={deleteContent}>
              <img src={dustbinIcon} alt="" />
              <p>Delete</p>
            </div>
          </div>
        }
      >
        <div className={styles.moreBtn}>
          <More className={styles.icon} />
        </div>
      </Popover>
    </div>
  );
}

Content.defaultProps = {
  className: '',
  deleteContent: () => {},
};

export default Content;
