import { updateUserPassword } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import LabelWithInput from 'components/label-and-input/labelAndInput';
import { toaster, validators } from 'helpers';
import React, { useCallback, useState } from 'react';
import { useStore } from 'store';
import { iState } from 'types';
import SectionHeading from '../section-heading/sectionHeading';
import styles from './password.module.scss';

interface iProps {
  className?: string;
}

interface iPasswordInterface {
  password: string;
  newPassword: string;
  confirmPassword: string;
}
function Password(props: iProps): JSX.Element {
  const { className } = props;
  const [state]: [iState] = useStore();
  const { id = '' } = state.user.loggedInData || {};
  const [newPasswordData, setPassword] = useState<iPasswordInterface>({
    password: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPasswordError, setCurrentPasswordError] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');

  const { password, newPassword, confirmPassword } = newPasswordData;

  console.log({ setIsLoading });
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const temp = { ...newPasswordData };
      temp[event.target.name] = event.target.value;
      setPassword(temp);
      setCurrentPasswordError('');
      setNewPasswordError('');
      setConfirmPasswordError('');
    },
    [newPasswordData]
  );

  const cancelHandler = useCallback(() => {
    setCurrentPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    setConfirmPasswordError('');
    setPassword({ password: '', newPassword: '', confirmPassword: '' });
  }, []);

  const updatePasswordHandler = useCallback(async (): Promise<void> => {
    if (!password) {
      setCurrentPasswordError('Should enter current password');
      return;
    }
    if (!newPassword) {
      setNewPasswordError('Should enter new password');
      return;
    }
    if (!confirmPassword) {
      setConfirmPasswordError('Should enter confirm password');
      return;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('New Password and confirm password must be same');
      return;
    }

    if (!validators.validatePassword(newPassword)) {
      setNewPasswordError(
        'Password must contain minimum of 8 characters including of atleast, 1 upper letter, 1 small letter, 1 digit, 1 special character'
      );
      setConfirmPasswordError(
        'Password must contain minimum of 8 characters including of atleast, 1 upper letter, 1 small letter, 1 digit, 1 special character'
      );
      return;
    }

    const response = await updateUserPassword(
      { id, password: newPassword, oldpassword: password },
      setIsLoading
    );
    if (response) {
      console.log(response);
      toaster.success('Updated password ');
      cancelHandler();
    }
  }, [confirmPassword, newPassword, password, id, cancelHandler]);

  return (
    <div className={`${styles.passwordWrapper} ${className}`}>
      <SectionHeading className={styles.sectionHeading} heading="Password" />
      <LabelWithInput
        className={styles.labelAndInput}
        type="password"
        label="Current Password"
        name="password"
        placeholder="Enter your current password"
        value={password}
        onChange={onChange}
        errorMessage={currentPasswordError}
      />
      <LabelWithInput
        className={styles.labelAndInput}
        type="password"
        label="New Password"
        name="newPassword"
        placeholder="Enter you new password"
        value={newPassword}
        onChange={onChange}
        errorMessage={newPasswordError}
      />
      <LabelWithInput
        className={styles.labelAndInput}
        type="password"
        label="Confirm New Password"
        name="confirmPassword"
        placeholder="Confirm your new password"
        value={confirmPassword}
        onChange={onChange}
        errorMessage={confirmPasswordError}
      />
      <div className={styles.btnsContainer}>
        <Button
          className={styles.saveBtn}
          btnName="Save"
          variant={ButtonVariants.SmallStd}
          onClick={updatePasswordHandler}
          isLoading={isLoading}
        />
        <Button
          className={styles.cancelBtn}
          btnName="Cancel"
          variant={ButtonVariants.SmallStd}
          onClick={cancelHandler}
        />
      </div>
    </div>
  );
}

Password.defaultProps = {
  className: '',
};

export default Password;
