import Input from 'components/input/input';
import { errorInformationIcon } from 'constants/index';
import React from 'react';
import styles from './labelAndInput.module.scss';

interface iProps {
  id?: string;
  className?: string;
  value: string;
  name?: string;
  label: string;
  type?: 'text' | 'password';
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  onFocus?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function LabelAndInput(props: iProps): JSX.Element {
  const {
    className,
    id,
    value,
    name,
    label,
    placeholder,
    disabled,
    errorMessage,
    type = 'text',
    onFocus,
    onChange,
  } = props;

  const isError = errorMessage !== '';

  return (
    <div className={`${styles.labelAndInput} ${className}`}>
      <p className={`${styles.label} ${isError && styles.error}`}>{label}</p>
      {isError && (
        <img
          className={styles.errorInformationIcon}
          src={errorInformationIcon}
          alt=""
        />
      )}
      <Input
        className={`${styles.input} ${isError && styles.errorExist}`}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onFocus={onFocus}
        onChange={onChange}
        isError={isError}
      />

      <p
        className={`${styles.errorMessage}  ${!isError && styles.inActive} ${
          isError && styles.active
        }`}
      >
        {errorMessage}
      </p>
    </div>
  );
}

LabelAndInput.defaultProps = {
  className: '',
  id: '',
  name: '',
  placeholder: '',
  disabled: false,
  errorMessage: '',
  type: 'text',
  onFocus: () => {
    //
  },
  onChange: () => {},
};

export default LabelAndInput;
