import React from 'react';
import Button, { ButtonVariants } from 'components/button/button';
import { iContentData } from 'types';
import MoreIcon from 'components/svg/more';
import { closeIcon, CONTENT_TYPE } from 'constants/index';
import styles from './contentPreview.module.scss';

interface iProps {
  className?: string;
  data: iContentData;
  close: () => void;
}

function ContentPreview(props: iProps): JSX.Element {
  const { className, data, close } = props;

  const content = data;
  const contentView = data;
  const date =
    (contentView?.updated_at && new Date(contentView?.updated_at)) || '';

  return (
    <div className={`${styles.contentPreview} ${className}`}>
      <div className={styles.drawerHeader}>
        <div className={styles.colOne}>
          <Button variant={ButtonVariants.ChangeView} btnName="Info" isActive />
        </div>
        <div className={styles.colTwo}>
          <MoreIcon className={styles.moreIcon} />

          <img
            className={styles.closeIcon}
            src={closeIcon}
            alt=""
            onClick={close}
          />
        </div>
      </div>

      <div className={`${styles.section}`}>
        <div className={styles.contentPreview}>
          {content?.contentType === CONTENT_TYPE.Image && (
            <img className={styles.contentPreview} src={content?.url} alt="" />
          )}
          {content?.contentType === CONTENT_TYPE.Video && (
            <video preload="none" src={content?.url} controls>
              <source src={content?.url} type="video/mp4" />
              <track src="captions_en.vtt" kind="captions" />
            </video>
          )}
        </div>

        <p className={styles.contentName}>{contentView?.name}</p>
        <table className={styles.details}>
          <tr>
            <td className={styles.heading}>Dimension</td>
            <td className={styles.value}>{contentView?.dimension}</td>
          </tr>
          <tr>
            <td className={styles.heading}>Size</td>
            <td className={styles.value}>{contentView?.size} KB</td>
          </tr>
          <tr>
            <td className={styles.heading}>Type</td>
            <td className={styles.value}>{contentView?.contentType}</td>
          </tr>
          <tr>
            <td className={styles.heading}>Modified</td>
            {/* <td className={styles.value}>14.03.2020 13:47</td> */}
            <td className={styles.value}>
              {' '}
              {date &&
                `${date.getDate()}.${date.getMonth()}.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()} `}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

ContentPreview.defaultProps = {
  className: '',
};

export default ContentPreview;
