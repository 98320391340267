import React, { useCallback, useState, useEffect } from 'react';
import {
  blueAddBtn,
  activeFolderIcon,
  usersIcon,
  // caretFilledSmallDown,
} from 'constants/index';
import { useStore } from 'store';
import { validators } from 'helpers';
import AvatarGroup from 'components/avatar-group/avatarGroup';
import { iState } from 'types';
import { layoutActions } from 'store/actions';
import Modal, { ModalVariants } from 'components/modal/modal';
import { ModalViews } from 'enums';
import Button, { ButtonVariants } from 'components/button/button';
import UsersList, { UsersListVariants } from './shared-with-list/usersList';
import { USERS } from './constants';
import styles from './shareAccess.module.scss';

interface iProps {
  className?: string;
  heading: string;
  shareType: 'screen' | 'content' | 'playlist';
}

function ShareAccess(props: iProps): JSX.Element {
  const { className, shareType, heading } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { isShowShareAccess } = state.layout || {};
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [sharedAccessWith, setSharedAccessWith] = useState<any>([]);
  const [isShowInviteUser, setIsShowInviteUser] = useState<boolean>(false);

  useEffect(() => {
    if (searchKey && validators.isEmail(searchKey)) {
      const found = USERS.find((i) => i.email === searchKey);
      if (found) setIsShowInviteUser(false);
      else setIsShowInviteUser(true);
    } else setIsShowInviteUser(false);
  }, [searchKey]);

  const searchedUsers =
    (searchKey !== '' &&
      USERS.filter(
        (i) =>
          i.firstName.toLowerCase().includes(searchKey.toLowerCase()) ||
          i.lastName.toLowerCase().includes(searchKey.toLowerCase())
      )) ||
    [];

  const usersData =
    (searchKey !== '' && searchedUsers.length === 0 && []) ||
    (searchedUsers.length > 0 && searchedUsers) ||
    USERS;

  const chooseToShareAccess = useCallback(
    (user) => {
      setSharedAccessWith([...sharedAccessWith, user]);
    },
    [sharedAccessWith]
  );

  const inviteUserHandler = useCallback(() => {
    setSharedAccessWith([...sharedAccessWith, { email: searchKey }]);
  }, [sharedAccessWith, searchKey]);

  console.log('sharedAccessWith', sharedAccessWith);

  return (
    <>
      <div className={`${styles.shareWrapper} ${className}`}>
        <p className={styles.heading}>
          {heading}
          {/* Has access to the{' '}
          {(shareType === 'screen' && 'screen') ||
            (shareType === 'playlist' && 'playlist') ||
            (shareType === 'content' && 'folder')} */}
        </p>
        {sharedAccessWith.length > 0 && (
          <AvatarGroup className={styles.avatarGroup} data={sharedAccessWith} />
        )}
        <Button
          className={styles.shareBtn}
          btnName="Share"
          variant={ButtonVariants.SmallTransp}
          icon={blueAddBtn}
          iconPosition="left"
          onClick={() =>
            dispatch(layoutActions.toggleModal(ModalViews.isShowShareAccess))
          }
        />
      </div>
      <Modal
        isOpen={isShowShareAccess}
        variant={ModalVariants.Standard}
        headingIcon={activeFolderIcon}
        heading={
          (shareType === 'screen' && 'Screen name') ||
          (shareType === 'playlist' && 'Playlist name') ||
          (shareType === 'content' && 'Folder name') ||
          ''
        }
        onClose={() => dispatch(layoutActions.toggleModal())}
        submit={() => {}}
        btnName="Next"
      >
        <div className={styles.shareModal}>
          <div className={styles.searchUsers}>
            <div
              className={`${styles.searchDropdown} ${
                isFocused && styles.focused
              }`}
            >
              <input
                className={styles.search}
                type="text"
                value={searchKey}
                placeholder="Invite someone to this folder"
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                  setTimeout(() => {
                    setIsFocused(false);
                  }, 200);
                }}
                onChange={(event) => setSearchKey(event.target.value)}
              />
              <div className={styles.canEdit}>
                <span>Can edit</span>
                {/* <img src={caretFilledSmallDown} alt="" /> */}
              </div>
              {isFocused && (
                <UsersList
                  className={styles.foundList}
                  variant={UsersListVariants.ListToChoose}
                  data={usersData}
                  isShowInviteUser={isShowInviteUser}
                  onClick={chooseToShareAccess}
                  onClickInvite={inviteUserHandler}
                />
              )}
            </div>
            <div className={styles.accessMessage}>
              <img className={styles.usersIcon} src={usersIcon} alt="" />
              <p className={styles.message}>
                The following people has access to this folder
              </p>
            </div>
          </div>
          <UsersList
            variant={UsersListVariants.SharedWithList}
            data={sharedAccessWith}
          />
        </div>
      </Modal>
    </>
  );
}

ShareAccess.defaultProps = {
  className: '',
};

export default ShareAccess;
