import {
  caretLeft,
  helpCircleOutline,
  logoutIcon,
  ROUTES,
  settingsIcon,
} from 'constants/index';
import Avatar, { AvatarVariants } from 'components/avatar/avatar';
import { LayoutViews, UserRoles } from 'enums';
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { commonActions, homePageActions, layoutActions } from 'store/actions';
import { iCompanyData, iState } from 'types';
// import Avatar from '../avatar/avatar';
import Company from '../company-menu-item/company';
import { COMPANIES } from '../constants';
import styles from './moreDrawer.module.scss';

interface iProps {
  className?: string;
  isOpen: boolean;
}

function MoreDrawer(props: iProps): JSX.Element {
  const { className, isOpen } = props;
  const [activeCompany, setActiveCompany] = useState<iCompanyData | null>();
  const [state, dispatch]: [iState, any] = useStore();
  const { companies, companyData } = state.homePage || {};
  const { firstName, lastName, profile_pic } = state.user.loggedInData || {};
  const { user } = state || {};

  const navigate = useNavigate();

  useEffect(() => {
    setActiveCompany(companyData);
  }, [companyData]);

  const logout = useCallback(() => {
    window.Intercom('shutdown');
    localStorage.removeItem('isLoggedIn');
    window.Intercom('boot', {
      app_id: 'd7jw43br',
    });
    dispatch(commonActions.reset());
    navigate(ROUTES.login);
  }, [dispatch, navigate]);

  const onClickCompanyHandler = useCallback(
    (company: iCompanyData) => {
      // dispatch(homePageActions.setCompanyData(company));
      // dispatch(layoutActions.initialSidebarState(true));
      setActiveCompany(company);
      dispatch(layoutActions.setShowClientsInstance(true));
      dispatch(homePageActions.setCompanyData(company));
      dispatch(layoutActions.initialSidebarState(true));
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      navigate('/');
    },
    [dispatch, navigate]
  );

  const handleHelpCenter = useCallback(() => {
    window.open('http://support.screenz.no/nb/');
  }, []);

  return (
    <div
      className={`${className} ${styles.moreDrawerWrapper} ${
        isOpen && styles.open
      }`}
    >
      <p className={styles.drawerHeading}>More</p>
      <div
        className={`${className} ${styles.profileWrapper}`}
        onClick={() => navigate(ROUTES.userSettings)}
      >
        <Avatar
          variant={AvatarVariants.Small}
          className={styles.avatar}
          firstName={firstName}
          lastName={lastName}
          profilePic={profile_pic}
        />
        <p>{`${firstName} ${lastName}`}</p>
      </div>
      <div className={styles.menu}>
        <img src={settingsIcon} alt="" />
        <p onClick={() => navigate(ROUTES.userSettings)}>Settings</p>
        <img src={caretLeft} alt="" />
      </div>
      {user && user.role === UserRoles.SuperAdmin && (
        <div className={`${styles.companies} ${styles.last}`}>
          <div className={styles.companyHeading}>
            <img src={logoutIcon} alt="" />
            <p>Companies</p>
          </div>

          <div className={styles.companiesPopover}>
            {companies.map((company, i) => (
              <Company
                data={company}
                isActive={company.id === activeCompany?.id}
                isLastOne={COMPANIES.length - 1 === i}
                onClick={() => onClickCompanyHandler(company)}
              />
            ))}
          </div>
        </div>
      )}
      <div
        className={`${styles.menu} ${styles.helpCenter}`}
        onClick={handleHelpCenter}
      >
        <img src={helpCircleOutline} alt="" />
        <p>Screenz Help Centre</p>
        <img src={caretLeft} alt="" />
      </div>
      <div className={styles.menu} onClick={logout}>
        <img src={logoutIcon} alt="" />
        <p>Logout</p>
        <img src={caretLeft} alt="" />
      </div>
    </div>
  );
}

MoreDrawer.defaultProps = {
  className: '',
};

export default MoreDrawer;
