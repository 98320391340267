import {
  getAllSystemConfigs,
  getUserByPassToken,
  getUserRoles,
  login,
  updateUser,
} from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import Input from 'components/input/input';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import Loader from 'components/loader/loader';
import Logo from 'components/logo/logo';
import { arrowRightWhiteIcon, ROLES, ROUTES } from 'constants/index';
import { LayoutViews } from 'enums';
import { toaster, validators } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { homePageActions, layoutActions, userActions } from 'store/actions';
import { iUserData } from 'types';
import Pattern from '../components/pattern/pattern';
import styles from './joinYourTeam.module.scss';

const SETUP_SCREENZ = 'setup_screenz';
const JOIN_TEAM = 'join_team';

function JoinYourTeam(): JSX.Element {
  const navigate = useNavigate();
  const [, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [userData, setUserData] = useState<iUserData>();
  const [fullName, setFullName] = useState<string>('');
  const [password, setPassword] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [nameError, setNameError] = useState('');

  console.log('userData___', userData);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token') || '';
  const action = queryParams.get('action') || JOIN_TEAM;

  // http://localhost:3000/join-your-team/?token=532f97c8-47e7-4424-9851-e1cdb23ba936
  // http://localhost:3000/join-your-team/?token=dhuwhd3&action=setup_screenz

  // const getUserHandler = useCallback(async () => {
  //   if (!id) {
  //     toaster.error('User id is not passed in url parameter');
  //     return;
  //   }
  //   const response = await getUser({
  //     // userId: '07b71bf3-7e5c-4196-acc2-694a6605faf7',
  //     userId: id,
  //   });
  //   if (response) {
  //     setUserData(response);
  //     setFullName(`${response.first_name} ${response.last_name}`);
  //   }
  // }, []);

  // useEffect(() => {
  //   getUserHandler();
  // }, []);

  const loginHandler = useCallback(async () => {
    if (!userData) {
      toaster.error('User data not available');
      return;
    }
    const response = await login(
      { email: userData?.email, password: password.password },
      () => {},
      () => {}
    );
    if (!response) return;
    const roles = (await getUserRoles()) || [];
    const systemConfigs = await getAllSystemConfigs();
    if (systemConfigs) {
      dispatch(layoutActions.setEmptyStates(systemConfigs));
    }
    dispatch(userActions.setLoginData({ data: response, roles }));
    if (Number(response.roleId) !== ROLES.super_admin)
      dispatch(homePageActions.setCompanyData(response.companyData));
    dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
    navigate(ROUTES.home, { replace: true });
    localStorage.setItem('isLoggedIn', 'true');
  }, [userData, password.password, dispatch, navigate]);

  const getUserByToken = useCallback(async () => {
    if (!token) {
      toaster.error('Password token is not passed in url parameter');
      return;
    }
    const response = await getUserByPassToken(
      {
        password_token: token,
      },
      setIsLoading
    );
    if (response) {
      setUserData(response);
      const { first_name, last_name } = response || {};
      let name = '';
      if (first_name !== null) name = first_name;
      if (last_name !== null) name = `${name}${name && ' '}${last_name}`;
      setFullName(name);
      setIsError(false);
    } else {
      // navigate(ROUTES.login);
      setIsError(true);
    }
  }, [token]);

  useEffect(() => {
    getUserByToken();
  }, [getUserByToken]);

  const onPasswordChange = useCallback(
    (event) => {
      setPassword({ ...password, [event.target.name]: event.target.value });
      setErrorMessage('');
      // setNameError('');
    },
    [password]
  );

  const joinTeamHandler = useCallback(async () => {
    const { password: pwd, confirmPassword } = password;
    if (!userData) return;

    if (!fullName) {
      toaster.error('Please enter your full name');
      setNameError('Please enter your full name');
      return;
    }
    if (!pwd || !confirmPassword) {
      toaster.error('Please enter password and confirm both the passwords');
      setErrorMessage('Please enter password and confirm both the passwords');
      return;
    }
    if (pwd !== confirmPassword) {
      toaster.error('Password and confirm password should be same');
      setErrorMessage('Password and confirm password should be same');
      return;
    }
    if (!validators.validatePassword(pwd)) {
      setErrorMessage(
        'Password must contain minimum of 8 characters including of atleast, 1 upper letter, 1 small letter, 1 digit, 1 special character'
      );
      return;
    }
    setErrorMessage('');
    setNameError('');

    const words = fullName.split(' ');
    const first_name = words[0];
    const last_name = (words?.length > 1 && words.splice(1).join(' ')) || '';

    const response = await updateUser(
      {
        password: pwd,
        firstName: first_name,
        lastName: last_name,
        email: userData.email,
        profile_pic: userData.profile_pic,
        companyId: userData.company?.id,
        roleId: `${userData.role.id}`,
        id: userData.id,
        invitedStatus: 'ACCEPTED',
      },
      () => {}
    );
    if (response) {
      // toaster.success('Joined team successfully, Please login');
      // navigate(ROUTES.login);
      loginHandler();
    } else {
      navigate(ROUTES.login, { replace: true });
    }
  }, [navigate, fullName, password, userData, loginHandler, onclick]);

  return (
    <div
      className={`flex flex-col p5 ${styles.loginWrapper}`}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          joinTeamHandler();
        }
      }}
    >
      <Pattern position="top" />
      <Pattern position="bottom" />
      <header className={styles.header}>
        <p className={styles.dontHaveAccount}>Already using Screenz? &nbsp;</p>
        <p className={styles.signInBtn} onClick={() => navigate(ROUTES.login)}>
          Sign in
        </p>
      </header>
      <section className={`flex-center flex-grow ${styles.section}`}>
        <div className={`${styles.loginContainer}`}>
          {isLoading && <Loader />}
          {!isLoading && userData && (
            <>
              <Logo className={styles.logo} variant="onboarding" />
              <p className={styles.joinInMessage}>Before we start</p>
              <p className={styles.enterDetails}>
                Lets make your account a bit more personal.
              </p>
              <div className={styles.joinInForm}>
                <LabelAndInput
                  className={`${styles.inputFirstName}`}
                  name="fullName"
                  type="text"
                  label="Your name"
                  placeholder="Firstname Lastname"
                  value={fullName}
                  onChange={(event) => {
                    setFullName(event.target.value);
                    setNameError('');
                  }}
                  errorMessage={nameError}
                />
                {action === JOIN_TEAM && (
                  <>
                    <p className={styles.enterEmail}>Email</p>
                    <Input
                      className={styles.inputEmail}
                      name="email"
                      value={userData.email}
                      disabled
                    />
                  </>
                )}
                {action === SETUP_SCREENZ && (
                  <>
                    <p className={styles.enterEmail}>Company</p>
                    <Input
                      className={`${styles.inputEmail} ${styles.companyName}`}
                      name="company"
                      value={userData.company?.name || ''}
                    />
                  </>
                )}
                <LabelAndInput
                  className={`${styles.inputFirstName} ${styles.password}`}
                  name="password"
                  type="password"
                  label="Create Password"
                  placeholder="Create Password"
                  value={password.password}
                  onChange={onPasswordChange}
                  errorMessage={errorMessage && ` `}
                />
                <p className={`mb1  ${styles.passwordHelperText}`}>
                  Must be at least 8 characters.
                </p>
                <LabelAndInput
                  className={`${styles.inputFirstName} ${styles.password}`}
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  value={password.confirmPassword}
                  onChange={onPasswordChange}
                  errorMessage={errorMessage}
                />
                <p className={`mb1  ${styles.passwordHelperText}`}>
                  Both passwords must match.
                </p>
                {action === JOIN_TEAM && (
                  <span className={styles.termsAndConditions}>
                    By clicking "Join" you agree to Screen's{` `}
                    <a
                      href="https://www.screenz.no/terms-conditions"
                      target="blank"
                      rel="noreferrer"
                    >
                      Terms of service{` `}
                    </a>
                    and{` `}
                    <a
                      href="https://en.screenz.no/privacy-policy"
                      target="blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </span>
                )}
                <Button
                  className={styles.joinBtn}
                  btnName={
                    action === SETUP_SCREENZ
                      ? 'Setup Screenz'
                      : 'Join your team'
                  }
                  variant={ButtonVariants.MediumStd}
                  onClick={joinTeamHandler}
                  icon={arrowRightWhiteIcon}
                  iconPosition="right"
                />
              </div>
            </>
          )}
          {isError && !isLoading && (
            <p className={styles.errorMessage}>
              Your Invite is expired, refer for new email invite.
            </p>
          )}
        </div>
      </section>
    </div>
  );
}

export default JoinYourTeam;
