import { getUserByCompanyId } from 'apis';
import { iUserData, iState } from 'types';
import { useStore } from 'store';
import { InvitedTableColumns } from 'constants/index';
import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import InvitedAvatar from './components/invited-avatar/invitedAvatar';
import Resend from './components/resend/resend';
import styles from './invited.module.scss';

function Invited({
  resendingInviteTo,
  revokingInviteTo,
  isResending,
  isRevoking,
  resend,
  revoke,
}): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [state]: [iState, any] = useStore();
  const { homePage } = state || {};
  const [invitedUser, setInvitedUser] = useState<Array<iUserData>>([]);
  const { companyData } = homePage || {};
  const { id: companyId } = companyData || {};
  console.log({ isLoading });
  const columns = useMemo(() => InvitedTableColumns, []);
  const data = useMemo(() => invitedUser, [invitedUser]);

  const tableInstance = useTable({
    columns,
    data,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  useEffect(() => {
    (async () => {
      if (!companyId) return;
      const response = await getUserByCompanyId(
        { company: companyId },
        setIsLoading
      );
      if (response) {
        console.log({ response });
        const parsed = response
          .filter((i) => i.invitedStatus === 'INVITED')
          .map((i) => ({
            ...i,
            user: {
              name: i.first_name,
              lastName: i.last_name,
              email: i.email,
              profilePic: i.profile_pic,
              invitedStatus: i.invitedStatus,
            },
          }));
        setInvitedUser(parsed);
      }
    })();
  }, [companyId]);

  return (
    <table {...getTableProps()} className={styles.tableHead}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                const { id } = cell.column || {};
                const { role } = row.original || {};
                const userAvatar = id === 'user';
                const sentDetails = id === 'sent';
                const actionsDetails = id === 'actions';
                const isShowPermission = id === 'permission';
                const permissions =
                  (role.id === ('1' || '2') && 'Full Permission') ||
                  'Limited Permission';

                return (
                  <td
                    {...cell.getCellProps()}
                    className={`${id === 'sentDetails' && styles.sentDetails}`}
                  >
                    {userAvatar ? (
                      <InvitedAvatar data={cell.value} />
                    ) : (
                      cell.value
                    )}
                    {isShowPermission && permissions}
                    {sentDetails}
                    {actionsDetails && (
                      <Resend
                        // resendingInviteTo
                        // revokingInviteTo
                        isResending={
                          (resendingInviteTo === row.original.id &&
                            isResending) ||
                          false
                        }
                        isRevoking={
                          (revokingInviteTo === row.original.id &&
                            isRevoking) ||
                          false
                        }
                        resend={() => resend(row.original)}
                        revoke={() => revoke(row.original)}
                      />
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
export default Invited;
