import React from 'react';
import Avatar, { AvatarVariants } from 'components/avatar/avatar';
import styles from './avatarGroup.module.scss';

interface iProps {
  className?: string;
  data: any;
}

function AvatarGroup(props: iProps): JSX.Element {
  const { className, data } = props;

  return (
    <div className={`${styles.avatarGroup} ${className}`}>
      {data.slice(0, 4).map((user, index) => (
        <Avatar
          className={styles.avatar}
          variant={AvatarVariants.Medium}
          style={{ left: `${index * 30}px` }}
          firstName={user.firstName}
          lastName={user.lastName}
          profilePic={user.profilePic}
          avatarCount={index > 2 ? data.length - 3 : 0}
        />
      ))}
      {data.length === 1 && (
        <p className={styles.accessToOnlyOne}>Only you have access</p>
      )}
    </div>
  );
}

AvatarGroup.defaultProps = {
  className: '',
};

export default AvatarGroup;
