export const VALUE = 'value';
export const RESPONSIVE_WINDOW_WIDTH = 768;
export const CONTENT_TYPE = {
  Image: 'image',
  Video: 'video',
  Website: 'website',
};

export const ROLES = {
  super_admin: 1,
  admin: 2,
  reseller: 3,
  user: 4,
};
