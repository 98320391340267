/* eslint-disable */
import AddTeammateModal from 'components/add-teammate-modal/addTeammateModal';
import Button, { ButtonVariants } from 'components/button/button';
import { whiteAddIcon } from 'constants/index';
import { ModalViews, UserSettingsView } from 'enums';
import React, { useCallback, useState } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import SubHeader from '../subheader/subheader';
import { iState } from 'types';
import TeamMembersPermissions from '../../components/team-members-permissions/teamMembersPermissions';
import CompanyInformation from '../company-information/companyInformation';
import ContactInformation from '../contact-information/contactInformation';
import General from '../general/general';
import HeaderTitle from '../general/header-title/headerTitle';
import Language from '../language/language';
// import Language from '../language/language';
import Navigators from '../navigators/navigators';
import Password from '../password/password';
import PersonalInformation from '../personal-information/personalInformation';
import ProfilePicture from '../profile-picture/profilePicture';
import TeamMember from '../team-members/teamMembers';
import TwoFactorAuthentication from '../two-factor-authentication/twoFactorAuthentication';
import ViewSwitchDropdown from '../view-switch-dropdown/viewSwitchDropdown';
import styles from './layout.module.scss';
import PasswordAndSecurity from './password-and-security/passwordAndSecurity';
import Profile from './profile/profile';

interface iProps {
  className?: string;
}

function Layout(props: iProps): JSX.Element {
  const { className } = props;

  const [state, dispatch]: [iState, any] = useStore();
  const { activeView } = state.user.userSettings || {};
  const { homePage } = state || {};
  const { companyData } = homePage || {};
  const [enableBtn, setEnableBtn] = useState(false);

  const selectedOption =
    (activeView === UserSettingsView.General && 'General') ||
    (activeView === UserSettingsView.TeamMembers && (
      <p>Team members for {companyData?.name}</p>
    )) ||
    (activeView === UserSettingsView.Profile && 'Profile') ||
    (UserSettingsView.PasswordAndSecurity && 'Password & Security');

  const displayAddTeammateBtn =
    // activeView === UserSettingsView.General ||
    activeView === UserSettingsView.TeamMembers;
  const onTeammateClick = useCallback(() => {
    dispatch(layoutActions.toggleModal(ModalViews.isShowAddTeammate));
  }, [dispatch]);

  const displayCancelAndSaveBtn = activeView === UserSettingsView.General;
  return (
    <>
      <div className={`${styles.localLayout} ${className}`}>
        <div className={styles.sidebar}>
          <Navigators />
        </div>
        <div className={styles.right}>
          <div className={styles.subHeader}>
            <div>{selectedOption}</div>
            {displayAddTeammateBtn && (
              <div>
                <Button
                  className={styles.screenzBtn}
                  variant={ButtonVariants.SmallStd}
                  btnName="Teammate"
                  icon={whiteAddIcon}
                  onClick={onTeammateClick}
                />
              </div>
            )}
            {displayCancelAndSaveBtn || (false && <HeaderTitle isDisabled />)}
          </div>
          {activeView === UserSettingsView.TeamMemberPermissions && (
            <TeamMembersPermissions />
          )}

          {activeView === UserSettingsView.General && <General />}
          {activeView === UserSettingsView.TeamMembers && <TeamMember />}
          {activeView === UserSettingsView.Profile && <Profile />}
          {activeView === UserSettingsView.PasswordAndSecurity && (
            <PasswordAndSecurity />
          )}
          <div></div>
          <AddTeammateModal />
        </div>
      </div>
      <div className={styles.responsiveContainer}>
        <ViewSwitchDropdown />
        {activeView === UserSettingsView.General && (
          <>
            <CompanyInformation />
            <ContactInformation />
          </>
        )}
        {activeView === UserSettingsView.TeamMembers && <TeamMember />}
        {activeView === UserSettingsView.Profile && (
          <>
            <PersonalInformation setEnableBtn={setEnableBtn} />
            <ProfilePicture setEnableBtn={setEnableBtn} enableBtn={enableBtn} />
            <Language />
          </>
        )}
        {activeView === UserSettingsView.PasswordAndSecurity && (
          <>
            <Password />
            <TwoFactorAuthentication />
          </>
        )}
        <AddTeammateModal />
      </div>
    </>
  );
}

Layout.defaultProps = {
  className: '',
};

export default Layout;
