import { addCompany } from 'apis';
import Checkbox from 'components/checkbox/checkbox';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import Modal from 'components/modal/modal';
import { companyIcon, team } from 'constants/index';
import { LayoutViews } from 'enums';
import { toaster, validators } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { homePageActions, layoutActions } from 'store/actions';
import { iAddCompanyArgs, iState } from 'types';
import styles from './addClientModal.module.scss';

const clientInitialValues = {
  name: '',
  org_no: '',
  address: '',
  postal_code: '',
  email: '',
  city: '',
  phone_number: '',
  country_code: '',
};
function AddClientModal(): JSX.Element {
  const [client, setClient] = useState<iAddCompanyArgs>(clientInitialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [state, dispatch]: [iState, any] = useStore();
  const { layout } = state || {};
  const { chosenView, isShowAddClient = false } = layout || {};
  const { SuperAdminClients, SuperAdminResellers, ResellerClients } =
    LayoutViews;
  const [checked, setChecked] = useState(false);
  const [companyErr, setCompanyErr] = useState('');
  const [addressErr, setaddressErr] = useState('');
  const [postalErr, setPostalErr] = useState('');
  const [cityErr, setCityErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [orgErr, setOrgErr] = useState('');

  useEffect(() => {
    const inputElement = document.getElementById('addCompanyName');
    if (inputElement && isShowAddClient) {
      inputElement.focus();
    }
    // window.addEventListener("keypress", () => {
    //   if(KeyboardEvent)
    // })
  }, [isShowAddClient]);

  const emptyErrors = (): void => {
    if (client.name) setCompanyErr('');
    if (client.org_no) setOrgErr('');
    if (client.address) setaddressErr('');
    if (client.postal_code) setPostalErr('');
    if (client.city) setCityErr('');
    if (client.email) setEmailErr('');
  };

  const errorFields = (): void => {
    if (!client.name) setCompanyErr('Please enter the Address');
    if (!client.org_no) setOrgErr('Please enter the Org. nr.');
    if (!client.address) setaddressErr('Please enter the Address');
    if (!client.postal_code) setPostalErr('Please enter the Postal Code');
    if (!client.city) setCityErr('Please enter the City');
    if (!client.email) setEmailErr('Please enter the Email');
    emptyErrors();
  };

  const onChange = useCallback(
    (event) => {
      const temp = { ...client };
      temp[event.target.name] = event.target.value;

      setClient(temp);
      // emptyErrors();
    },
    [client]
  );

  const setModalPosition = (): boolean => {
    if (companyErr || orgErr || addressErr || postalErr || cityErr || emailErr)
      return true;
    return false;
  };

  const closeErrors = (): void => {
    setCompanyErr('');
    setOrgErr('');
    setaddressErr('');
    setPostalErr('');
    setCityErr('');
    setEmailErr('');
  };

  const addClient = useCallback(async (): Promise<null> => {
    const { name, org_no, address, postal_code, city, email } = client;
    if (chosenView === SuperAdminClients) {
      errorFields();
      if (!name || !org_no || !address || !postal_code || !city || !email) {
        toaster.error('Please enter all fields');
        return null;
      }
      if (!validators.isEmail(email)) {
        toaster.error('Please enter valid email id');
        return null;
      }

      const response = await addCompany(client, setIsLoading);
      if (response) {
        dispatch(layoutActions.toggleModal());
        dispatch(homePageActions.invokeGetCompanies(true));
        dispatch(homePageActions.addSuperUserClient());
        toaster.success('Client added successfully');
        setClient(clientInitialValues);
      }
    }
    if (chosenView === SuperAdminResellers)
      dispatch(homePageActions.addSuperUserResellerClient());
    if (chosenView === ResellerClients)
      dispatch(homePageActions.addResellerClient());
    return null;
  }, [
    dispatch,
    chosenView,
    ResellerClients,
    SuperAdminClients,
    SuperAdminResellers,
    client,
  ]);

  return (
    <Modal
      isOpen={isShowAddClient}
      isLoading={isLoading}
      heading={
        (chosenView === SuperAdminClients && 'Add new client') ||
        (chosenView === SuperAdminResellers && 'Add new Reseller') ||
        ''
      }
      btnName={
        (chosenView === SuperAdminClients && 'Add Client') ||
        (chosenView === SuperAdminResellers && 'Add Company') ||
        ''
      }
      headingIcon={companyIcon}
      // onClose={() => dispatch(layoutActions.toggleAddClient())}
      onClose={() => {
        dispatch(layoutActions.toggleModal());
        setClient(clientInitialValues);
        closeErrors();
      }}
      submit={addClient}
      clientModal={setModalPosition()}
    >
      <div className={styles.addClient}>
        <div className={styles.labelAndInput}>
          {/* <p className={styles.label}>Company name</p> */}
          {/* <span className={styles.label}>Screen name</span>
          <Input
            id="addScreenName"
            className={styles.input}
            name="screenName"
            placeholder={companyErr || 'Screen Name'}
            value={client.name}
            onChange={onChange}
            isError={companyErr !== ''}
          /> */}
          <LabelAndInput
            className={styles.input}
            name="name"
            id="addCompanyName"
            label="Company name"
            placeholder="Enter here"
            value={client.name}
            onChange={onChange}
            errorMessage={companyErr}
          />
          {/* <Input
            className={styles.input}
            name="name"
            placeholder="Enter here"
            value={client.name}
            onChange={onChange}
            errorMessage="hi"
          /> */}
        </div>
        <div className={styles.labelAndInput}>
          {/* <p className={styles.label}>Org. nr.</p> */}
          <LabelAndInput
            className={styles.input}
            name="org_no"
            placeholder="Enter here"
            value={client.org_no}
            onChange={onChange}
            label="Org. nr."
            errorMessage={orgErr}
          />
          {/* <Input
            className={styles.input}
            name="org_no"
            placeholder="Enter here"
            value={client.org_no}
            onChange={onChange}
          /> */}
        </div>
        <div className={styles.labelAndInput}>
          {/* <p className={styles.label}>Address</p> */}
          <LabelAndInput
            className={styles.input}
            name="address"
            placeholder="Enter here"
            value={client.address}
            onChange={onChange}
            label="Address"
            errorMessage={addressErr}
          />
          {/* <Input
            className={styles.input}
            name="address"
            placeholder="Enter here"
            value={client.address}
            onChange={onChange}
          /> */}
        </div>

        <div className="flex">
          <div className={`${styles.labelAndInput} ${'w-30 mr2'}`}>
            {/* <p className={styles.label}>Postal Code</p> */}
            <LabelAndInput
              className={styles.input}
              name="postal_code"
              placeholder="Enter here"
              value={client.postal_code}
              onChange={onChange}
              label="Postal Code"
              errorMessage={postalErr}
            />

            {/* <Input
              className={styles.input}
              name="postal_code"
              placeholder="Enter here"
              value={client.postal_code}
              onChange={onChange}
            /> */}
          </div>
          <div className={`${styles.labelAndInput} ${'flex-grow'}`}>
            {/* <p className={styles.label}>City</p> */}
            <LabelAndInput
              className={styles.input}
              name="city"
              placeholder="Enter here"
              value={client.city}
              onChange={onChange}
              label="City"
              errorMessage={cityErr}
            />
            {/* <Input
              className={styles.input}
              name="city"
              placeholder="Enter here"
              value={client.city}
              onChange={onChange}
            /> */}
          </div>
        </div>
        <div className={styles.subModalCotanier}>
          <div className={styles.subModalSubCotanier}>
            <img src={team} alt="" />
            <p className={styles.subTitle}>Owner</p>
          </div>
          <div
            onClick={() => setChecked(!checked)}
            className={styles.checkBoxContainer}
          >
            <Checkbox
              checkmarkStyle
              isChecked={checked}
              className={styles.checkbox}
            />
            <p className={styles.welcomeNotification}>Send welcome email</p>
          </div>
        </div>
        <div className={styles.labelAndInput}>
          {/* <p className={styles.label}>Email</p> */}
          <LabelAndInput
            className={styles.input}
            name="email"
            placeholder="Enter here"
            value={client.email}
            onChange={onChange}
            label="Email"
            errorMessage={emailErr}
          />
          {/* <Input
            className={styles.input}
            name="email"
            placeholder="Enter here"
            value={client.email}
            onChange={onChange}
          /> */}
        </div>
      </div>
    </Modal>
  );
}

export default AddClientModal;
