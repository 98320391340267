import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES, CONTENT_TYPE } from 'constants/index';
import { UserRoles } from 'enums';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import ContentPreview from './content-preview/contentPreview';
import WebContent from './web-content/webContent';
import PlaylistPreview from './playlist-preview/playlistPreview';
import styles from './sideDrawer.module.scss';

interface iProps {
  className?: string;
}

function SideDrawer(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { pathname } = useLocation();
  const { isShowContentPreview = false, isShowPlaylistPreview } =
    state.layout || {};
  const { contentView } = state.contentPage || {};
  const { playlistPreview } = state.playlistsPage || {};
  const { role } = state.user || {};
  console.log('contentView____', contentView);

  const isWebContent =
    contentView?.contentType === CONTENT_TYPE.Website || false;
  const closeDrawer = useCallback(() => {
    dispatch(layoutActions.toggleSideDrawer());
  }, [dispatch]);

  return (
    <div
      className={`${styles.sideDrawer} ${
        (isShowContentPreview || isShowPlaylistPreview) && styles.open
      } ${role === UserRoles.SuperAdmin && styles.superAdmin} ${
        pathname === ROUTES.playlist && styles.playlistDetails
      } ${className}`}
    >
      {/* {isWebContent ? <WebContent close={closeDrawer} />} */}

      {isShowContentPreview &&
        contentView &&
        (isWebContent ? (
          <WebContent data={contentView} close={closeDrawer} />
        ) : (
          <ContentPreview data={contentView} close={closeDrawer} />
        ))}
      {isShowPlaylistPreview && playlistPreview && (
        <PlaylistPreview data={playlistPreview} close={closeDrawer} />
      )}
    </div>
  );
}

SideDrawer.defaultProps = {
  className: '',
};

export default SideDrawer;
