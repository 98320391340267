import screenshotImage from 'assets/images/screenshot.jpg';
import { uuid } from 'helpers';
import { iCompanyData, iScreen } from 'types';

export const CLIENTS: iCompanyData[] = [
  {
    id: uuid(),
    email: 'email',
    address: 'address',
    city: 'city',
    name: 'Client 1',
    postal_code: 'postal_code',
    org_no: 'org_no',
    phone_number: 'phone_number',
    country_code: 'country_code',
    online: 0,
    offline: 0,
    totalClients: 0,
  },
  {
    id: uuid(),
    email: 'email',
    address: 'address',
    city: 'city',
    name: 'Client 1',
    postal_code: 'postal_code',
    org_no: 'org_no',
    phone_number: 'phone_number',
    country_code: 'country_code',
    online: 0,
    offline: 0,
    totalClients: 0,
  },
  {
    id: uuid(),
    email: 'email',
    address: 'address',
    city: 'city',
    name: 'Client 2',
    postal_code: 'postal_code',
    org_no: 'org_no',
    phone_number: 'phone_number',
    country_code: 'country_code',
    online: 0,
    offline: 0,
    totalClients: 0,
  },
  {
    id: uuid(),
    email: 'email',
    address: 'address',
    city: 'city',
    name: 'Client 3',
    postal_code: 'postal_code',
    org_no: 'org_no',
    phone_number: 'phone_number',
    country_code: 'country_code',
    online: 0,
    offline: 0,
    totalClients: 0,
  },
  {
    id: uuid(),
    email: 'email',
    address: 'address',
    city: 'city',
    name: 'Client 4',
    postal_code: 'postal_code',
    org_no: 'org_no',
    phone_number: 'phone_number',
    country_code: 'country_code',
    online: 0,
    offline: 0,
    totalClients: 0,
  },
];

export const SCREENS: iScreen[] = [
  {
    isOnline: false,
    deviceName: 'Samsung BDL25182',
    screenName: 'Bykle Rådhus Inngang',
    contentName: 'Content Name Used',
    screenshot: screenshotImage,
    isConnectedScreen: true,
  },
  {
    isOnline: false,
    deviceName: 'Samsung BDL25182',
    screenName: 'Bykle Rådhus Inngang',
    contentName: 'Content Name Used',
    screenshot: '',
    isConnectedScreen: false,
  },
  {
    isOnline: true,
    deviceName: 'Screenz Player',
    screenName: 'Bykle Rådhus Inngang',
    contentName: 'Content Name Used',
    screenshot: '',
    isConnectedScreen: true,
  },
  {
    isOnline: true,
    deviceName: 'Screenz Player',
    screenName: 'Bykle Rådhus Inngang',
    contentName: 'Content Name Used',
    screenshot: screenshotImage,
    isConnectedScreen: true,
  },
  {
    isOnline: true,
    deviceName: 'Screenz Player',
    screenName: 'Bykle Rådhus Inngang',
    contentName: 'Content Name Used',
    screenshot: screenshotImage,
    isConnectedScreen: true,
  },
  {
    isOnline: true,
    deviceName: 'Screenz Player',
    screenName: 'Bykle Rådhus Inngang',
    contentName: 'Content Name Used',
    screenshot: '',
    isConnectedScreen: true,
  },
  {
    isOnline: false,
    deviceName: 'Samsung BDL25182',
    screenName: 'Bykle Rådhus Inngang',
    contentName: 'Content Name Used',
    screenshot: '',
    isConnectedScreen: false,
  },
];
