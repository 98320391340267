import * as Sentry from '@sentry/react';
import { initializeSentry } from 'helpers/sentry';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import App from './ReactGridLayout';
// import App from './ReactBeautifulDndGrid';
// import reportWebVitals from './reportWebVitals';

initializeSentry();

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={() => <p>Something went wrong in Screenz</p>}
    showDialog
  >
    <App />
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
