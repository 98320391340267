import { emptyScreensFolders, ROUTES } from 'constants/index';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './subFolderEmptyState.module.scss';

interface iProps {
  className?: string;
}

function SubFolderEmptyState(props: iProps): JSX.Element {
  const { className } = props;
  const { pathname } = useLocation();

  const description = pathname === ROUTES.playlists ? 'playlist' : 'screen';
  return (
    <div className={`${className} ${styles.subFolderEmptyState}`}>
      <img
        className={styles.emptyScreensFoldersBanner}
        src={emptyScreensFolders}
        alt=""
      />
      <p className={styles.noScreens}>{`No ${description}s here:-(`}</p>
      <p
        className={styles.description}
      >{`Add a ${description} or folder to start`}</p>
    </div>
  );
}

SubFolderEmptyState.defaultProps = {
  className: '',
};

export default SubFolderEmptyState;
