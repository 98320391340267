import React from 'react';
import Radio from 'components/radiobutton/radioButton';
import Accordion from './accordion/accordion';
import { PermissionsVariants, Variants } from './variants';
import styles from './permissions.module.scss';

interface iProps {
  className?: string;
  variant: PermissionsVariants;
}

const FOLDERS = [
  {
    folder: 'Parent 1',
    subFolders: [
      {
        folder: 'Child 1.1',
        subFolders: [
          {
            folder: 'Child 2',
            subFolders: [
              {
                folder: 'Child 3',
                subFolders: [
                  {
                    folder: 'Child 4',
                    subFolders: [],
                  },
                ],
              },
            ],
          },
          {
            folder: 'Child 1.2',
            subFolders: [
              {
                folder: 'Child 1.2.1',
                subFolders: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    folder: 'Parent 2',
    subFolders: [],
  },
];

function Permissions(props: iProps): JSX.Element {
  const { className, variant } = props;
  const [isAllPermitted, setIsAllPermitted] = React.useState<boolean>(false);
  const [isPartiallyPermitted, setIsPartiallyPermitted] =
    React.useState<boolean>(false);

  const { icon: Icon, accessType, all, specific } = Variants[variant] || {};

  const folderHierarchy = (folders): JSX.Element => {
    return folders.map((i) => {
      return (
        <Accordion heading={i.folder}>
          <div className={styles.accordion}>
            {i?.subFolders?.length > 0 && folderHierarchy(i.subFolders)}
          </div>
        </Accordion>
      );
    });
  };

  return (
    <div className={`${styles.permissions} ${className}`}>
      <div className={styles.permissionsHeading}>
        <Icon className={styles.icon} />
        <p>{accessType}</p>
      </div>
      <div className={styles.permissionLeves}>
        <div
          className={styles.permissionToAll}
          onClick={() => {
            setIsPartiallyPermitted(false);
            setIsAllPermitted(!isAllPermitted);
          }}
        >
          <Radio
            className={styles.radioBtn}
            value=""
            isChecked={isAllPermitted}
            onChange={() => {}}
          />
          {all}
        </div>
        <div
          className={styles.permissionToSpecific}
          onClick={() => {
            setIsAllPermitted(false);
            setIsPartiallyPermitted(!isPartiallyPermitted);
          }}
        >
          <Radio
            className={styles.radioBtn}
            value=""
            isChecked={isPartiallyPermitted}
            onChange={() => {}}
          />
          {specific}
        </div>
      </div>
      {isPartiallyPermitted && (
        <div className={styles.sharablesList}>
          <div className={styles.listHeading}>
            <p>Currently assigned to the following screens.</p>
            <p>Access Status</p>
          </div>
          <div className={styles.container}>{folderHierarchy(FOLDERS)}</div>
        </div>
      )}
    </div>
  );
}

Permissions.defaultProps = {
  className: '',
};

export { PermissionsVariants };
export default Permissions;
