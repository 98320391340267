import { refreshIcon } from 'constants/index';
import React from 'react';
import { useStore } from 'store';
import { homePageActions } from 'store/actions';
import { iState } from 'types';
import styles from './pingTime.module.scss';

interface iProps {
  className?: string;
  isLoading: boolean;
}

function PingTime(props: iProps): JSX.Element {
  const { className, isLoading } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { screenData } = state.homePage || {};

  //

  const { lastPing = '' } = screenData || {};

  let date = '';
  if (lastPing)
    date = `${new Date(lastPing).toLocaleString('en-us', {
      weekday: 'short',
    })} ${new Date(lastPing).getDate()}, ${new Date(
      lastPing
    ).getFullYear()} ${new Date(lastPing).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })} `;
  console.log(isLoading);

  return (
    <div className={`${styles.timeAndRefresh} ${className}`}>
      <p className={styles.pingTime}>
        <span>Last ping: </span>
        <span>{date}</span>
        <span>device time</span>
      </p>
      <div
        className={`${styles.refreshIcon} `}
        onClick={() => dispatch(homePageActions.invokeGetScreenById(true))}
      >
        <img
          className={isLoading && styles.refreshAnimation}
          src={refreshIcon}
          alt=""
        />
      </div>
    </div>
  );
}

PingTime.defaultProps = {
  className: '',
};

export default PingTime;
