import React, { useCallback, useState } from 'react';
import { iState } from 'types';
import { useStore } from 'store';
import { contentPageActions } from 'store/actions';
import { CONTENT_TYPE } from 'constants/index';
import { toaster } from 'helpers';
import { addContent } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import styles from './buttons.module.scss';

interface iProps {
  className?: string;
}

function Buttons(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user, contentPage } = state || {};
  const { contentActiveFolder, addWebContentData } = contentPage || {};
  const { companyData } = state.homePage;

  const { url, webSiteName, isLoadableInIFrame } = addWebContentData || {};

  const addWebpageHandler = useCallback(async () => {
    if (!isLoadableInIFrame) {
      toaster.error('This url can not be loaded in the iframe');
      return;
    }
    if (!url) {
      toaster.error('Please enter an URL');
      return;
    }
    if (!webSiteName) {
      toaster.error('Please enter a name to remember');
      return;
    }

    if (!companyData) {
      toaster.error('Company data not found');
      return;
    }
    if ([].length === 0) return;
    const response = await addContent(
      {
        name: webSiteName,
        contentType: CONTENT_TYPE.Website,
        company: companyData.id,
        url,
        size: '',
        dimension: '',
        folder: contentActiveFolder?.id || null,
        thumbnailPath: '',
        user: user.loggedInData ? user.loggedInData?.id : '',
      },
      setIsLoading
    );
    if (response) {
      toaster.success('Webpage added successfully');
      dispatch(contentPageActions.setAddWebContentData(null));
    }
  }, [
    url,
    webSiteName,
    companyData,
    contentActiveFolder?.id,
    user.loggedInData,
    dispatch,
    isLoadableInIFrame,
  ]);

  return (
    <div className={`${styles.buttons} ${className}`}>
      <Button
        className={styles.btns}
        btnName="Cancel"
        variant={ButtonVariants.SmallTransp}
      />
      <Button
        className={styles.btns}
        btnName="Save webpage"
        variant={ButtonVariants.SmallStd}
        onClick={addWebpageHandler}
        isLoading={isLoading}
      />
    </div>
  );
}

Buttons.defaultProps = {
  className: '',
};

export default Buttons;
