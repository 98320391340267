import React from 'react';
import styles from './runningState.module.scss';

interface iProps {
  className?: string;
}

function RunningStatus(props: iProps): JSX.Element {
  const { className } = props;
  return <div className={`${className} ${styles.runningStatus}`}>RUNNING</div>;
}

RunningStatus.defaultProps = {
  className: '',
};

export default RunningStatus;
