import Modal from 'components/modal/modal';
import Popover from 'components/popover/popover';
import RemoteIcon from 'components/svg/remote';
import { closeIcon } from 'constants/index';
import React, { useState } from 'react';
import Controller from '../../remote/remote';
import styles from './remoteWrapper.module.scss';

interface iProps {
  className?: string;
  isOnline: boolean;
  screenId: string;
}

function Remote(props: iProps): JSX.Element {
  const { className, isOnline, screenId } = props;
  const [isControllerOpen, setIsControllerOpen] = useState<boolean>(false);
  const [showPopUpState, setShowPopUpState] = useState(false);

  // useEffect(() => {
  //   console.log(showPopUpState, 'newState');
  // }, [showPopUpState, setShowPopUpState]);
  const onClose = (): void => {
    setIsControllerOpen(false);
  };
  const openController = (): void => {
    setIsControllerOpen(true);
  };

  return (
    <div className={`${className} ${styles.remoteWrapper}`}>
      <Popover
        popoverClassName={styles.controllerPopover}
        popoverComponent={
          <div
            className={styles.popoverWrapper}
            onMouseLeave={() => {
              setShowPopUpState(!showPopUpState);
            }}
          >
            <div className={styles.remoteHeaderContainer}>
              <p className={styles.remoteTitle}>Remote</p>
              <img
                src={closeIcon}
                alt=""
                onClick={() => {
                  setShowPopUpState(false);
                }}
              />
            </div>
            <Controller screenId={screenId} />
          </div>
        }
        closeBtnWork
        closePopover={!showPopUpState}
      >
        <RemoteIcon
          onClick={() => setShowPopUpState(!showPopUpState)}
          className={` ${styles.remoteIcon} ${!isOnline && styles.offline}`}
        />
      </Popover>

      <RemoteIcon
        className={`${styles.remoteIcon} ${!isOnline && styles.offline} ${
          styles.onResponsive
        }`}
        onClick={openController}
      />
      <Modal
        className={styles.onResponsive}
        isOpen={isControllerOpen}
        btnName="Add Screen"
        heading="Remote"
        isShowFooter={false}
        onClose={onClose}
        submit={() => {
          //
        }}
      >
        <Controller screenId={screenId} />
      </Modal>
    </div>
  );
}

Remote.defaultProps = {
  className: '',
};

export default Remote;
