import { ROUTES } from 'constants/index';
import { LayoutViews } from 'enums';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'store';
import { layoutActions } from 'store/actions/layout.actions';
import { iState } from 'types';
import styles from './viewSwitchButton.module.scss';

interface iProps {
  className?: string;
  variant: LayoutViews;
  // btnName: 'Screens' | 'Details' | 'Clients' | 'Resellers' | 'Content';
}

function ViewSwitchButton(props: iProps): JSX.Element {
  const { className, variant } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { layout } = state || {};
  const { chosenView } = layout || {};
  const { pathname } = useLocation();

  const {
    Screens,
    ScreensDetails,
    SuperAdminClients,
    SuperAdminResellers,
    // ResellerClients,
    Content,
    ContentDetails,
    Playlists,
    PlaylistsDetails,
    Playlist,
    PlaylistDetails,
  } = LayoutViews;

  const toggleView = useCallback(() => {
    dispatch(layoutActions.setHomePageView(variant));
  }, [dispatch, variant]);

  const isActive =
    (chosenView === Playlist &&
      variant === Playlist &&
      pathname === ROUTES.playlist) ||
    (chosenView === PlaylistDetails &&
      variant === PlaylistDetails &&
      pathname === ROUTES.playlist) ||
    (chosenView === Playlists &&
      variant === Playlists &&
      pathname === ROUTES.playlists) ||
    (chosenView === PlaylistsDetails &&
      variant === PlaylistsDetails &&
      pathname === ROUTES.playlists) ||
    (chosenView === Content &&
      variant === Content &&
      pathname === ROUTES.content) ||
    (chosenView === ContentDetails &&
      variant === ContentDetails &&
      pathname === ROUTES.content) ||
    (chosenView === Screens &&
      variant === Screens &&
      pathname === ROUTES.home) ||
    (chosenView === ScreensDetails &&
      variant === ScreensDetails &&
      pathname === ROUTES.home) ||
    (variant === SuperAdminClients && chosenView === SuperAdminClients) ||
    (variant === SuperAdminResellers &&
      SuperAdminResellers &&
      chosenView === SuperAdminResellers);

  const btnName =
    (variant === Playlist && 'Playlist') ||
    (variant === PlaylistDetails && 'Details') ||
    (variant === Playlists && 'Playlists') ||
    (variant === PlaylistsDetails && 'Details') ||
    (variant === Content && 'Content') ||
    (variant === ContentDetails && 'Details') ||
    (variant === Screens && 'Screens') ||
    (variant === ScreensDetails && 'Details') ||
    (variant === SuperAdminClients && 'Clients') ||
    (variant === SuperAdminResellers && 'Resellers');

  return (
    <button
      type="button"
      className={`${className} ${styles.button} ${isActive && styles.active}`}
      onClick={() => toggleView()}
    >
      {btnName}
    </button>
  );
}

ViewSwitchButton.defaultProps = {
  className: '',
};

export default ViewSwitchButton;
