/* eslint-disable */
import React, { useCallback } from 'react';
import { caretLeft } from 'constants/index';
import Dustbin from 'components/svg/dustbin';
import { useStore } from 'store';
import { userActions } from 'store/actions';
import Button, { ButtonVariants } from 'components/button/button';
import styles from './subheader.module.scss';
import { iState } from 'types';
import { UserSettingsView } from 'enums';

interface iProps {
  className?: string;
}

function SubHeader(props: iProps): JSX.Element {
  const { className } = props;
  const [, dispatch]: [iState, any] = useStore();

  const changeView = useCallback(() => {
    dispatch(userActions.changeView(UserSettingsView.TeamMembers));
  }, [dispatch]);

  return (
    <div className={`${styles.subheader} ${className}`}>
      <div className={styles.left}>
        <div className={styles.heading} onClick={changeView}>
          <img className={styles.arrowIcon} src={caretLeft} alt="" />
          <p>Team member</p>
        </div>
      </div>
      <div className={styles.right}>
        <Button
          className={styles.btnStyle}
          btnName="Cancel"
          variant={ButtonVariants.SmallTransp}
          onClick={changeView}
        />

        <button
          className={`${styles.btnStyle} ${styles.removeButton}`}
          type="button"
        >
          <Dustbin className={styles.distbinIcon} />
          Remove teammate
        </button>
        <Button
          className={styles.btnStyle}
          btnName="Save changes"
          variant={ButtonVariants.SmallStd}
          isDisabled
        />
      </div>
    </div>
  );
}

SubHeader.defaultProps = {
  className: '',
};

export default SubHeader;
