import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './popover.module.scss';

interface iProps {
  isDisabled?: boolean;
  className?: string;
  setIsShowing?: (a: boolean) => void;
  canCloseOnClick?: boolean;
  popoverClassName?: string;
  children: JSX.Element;
  popoverComponent: JSX.Element;
  showArrow?: boolean;
  setPopoverEffect?: (a: boolean) => void;
  popOverEffect?: boolean;
  searchClick?: boolean;
  setSearchKeyword?: (a: string) => void;
  closePopover?: boolean;
  closeBtnWork?: boolean;
}

function Popover(props: iProps): JSX.Element {
  const {
    isDisabled,
    className,
    popoverClassName,
    setIsShowing,
    children,
    showArrow = false,
    popoverComponent,
    canCloseOnClick,
    setPopoverEffect,
    popOverEffect,
    searchClick,
    setSearchKeyword,
    closePopover = false,
    closeBtnWork,
  } = props;
  const [isShowPopover, setIsShowPopover] = useState<boolean>(false);
  const containerRef: any = useRef(null);

  useEffect(() => {}, [searchClick]);
  const clickOutSide = useCallback(
    (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (setSearchKeyword) setSearchKeyword('');
        setIsShowPopover(false);
        if (setPopoverEffect) setPopoverEffect(false);
        if (setIsShowing) setIsShowing(false);
      }
    },
    [setIsShowing, setPopoverEffect, setSearchKeyword]
  );

  useEffect(() => {
    document.addEventListener('click', clickOutSide);
    return () => document.removeEventListener('click', clickOutSide);
  }, [clickOutSide]);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      // console.log(event.target, 'inputelement');
      event.stopPropagation();
      if (isDisabled) return;
      setIsShowPopover(true);
      if (event.target instanceof HTMLInputElement) return;
      if (setPopoverEffect) setPopoverEffect(true);
      if (isShowPopover && canCloseOnClick && !closeBtnWork) {
        setIsShowPopover(false);
        if (popOverEffect && setPopoverEffect) setPopoverEffect(false);
      }
      // if (isShowPopover && !canCloseOnClick && closeBtnWork) {
      //   setIsShowPopover(false);
      // }
    },
    [
      isDisabled,
      setIsShowPopover,
      isShowPopover,
      canCloseOnClick,
      popOverEffect,
      setPopoverEffect,
      closeBtnWork,
    ]
  );

  useEffect(() => {
    if (closePopover) {
      setIsShowPopover(false);
      setIsShowPopover(false);
    }
  }, [closePopover, onClick]);
  // console.log(canCloseOnClick);

  // const onClick = useCallback(() => {
  //   setIsShowPopover(true);
  // }, [setIsShowPopover]);

  return (
    <div
      ref={containerRef}
      className={`${styles.popoverContainer} ${className}`}
      onClick={onClick}
    >
      {children}
      {isShowPopover && (
        <div className={`${popoverClassName} ${styles.popover}`}>
          {showArrow && <span className={styles.arrow} />}
          {popoverComponent}
        </div>
      )}
    </div>
  );
}

Popover.defaultProps = {
  isDisabled: false,
  className: '',
  popoverClassName: '',
  showArrow: false,
  canCloseOnClick: true,
  setIsShowing: () => {},
  setPopoverEffect: () => {},
  setSearchKeyword: () => {},
  popOverEffect: false,
  searchClick: false,
  closePopover: false,
  closeBtnWork: false,
};

export default Popover;
