import React, { useCallback, useState } from 'react';
import { iContentData, iState } from 'types';
import { editWebContent } from 'apis';
import { useStore } from 'store';
import { contentPageActions } from 'store/actions';
import Button, { ButtonVariants } from 'components/button/button';
import { closeIcon, WebIcon, refreshIcon, editIcon } from 'constants/index';
import styles from './webContent.module.scss';

interface iProps {
  className?: string;
  data: iContentData;
  close: () => void;
}

function WebContent(props: iProps): JSX.Element {
  const { className, data, close } = props;
  const [name, setName] = useState<string>(data.name);
  const [isMutated, setIsMutated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, dispatch]: [iState, any] = useStore();

  const onChange = useCallback((event) => {
    setIsMutated(true);
    setName(event.target.value);
  }, []);

  const updateWebContent = useCallback(async () => {
    const response = await editWebContent({ id: data.id, name }, setIsLoading);
    if (response) {
      setIsMutated(false);
      dispatch(
        contentPageActions.setContentView({ ...data, name: response.name })
      );
    } else setIsMutated(false);
  }, [dispatch, data, name]);

  return (
    <div className={`${styles.webContentPreview} ${className}`}>
      <div className={styles.drawerHeader}>
        <div className={styles.colOne}>
          <span className={styles.previewHeading}>Details</span>
        </div>
        <div className={styles.colTwo}>
          <img
            className={styles.closeIcon}
            src={closeIcon}
            alt=""
            onClick={close}
          />
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.webSiteHeading}>
          <img src={WebIcon} alt="" />
          <p>Webpage name</p>
        </div>
        <div className={styles.inputAndSaveBtn}>
          <input type="text" value={name} onChange={onChange} />
          <Button
            className={styles.saveBtn}
            btnName="Save"
            variant={ButtonVariants.SmallTransp}
            onClick={updateWebContent}
            isDisabled={!isMutated}
            isLoading={isLoading}
          />
        </div>
        <div className={styles.webIcon}>
          <img src={WebIcon} alt="" />
        </div>
        <p className={styles.url}>{data.url}</p>
        <div className={styles.refresh}>
          <img src={refreshIcon} alt="" />
          <p>Does’nt not refresh</p>
        </div>
        <Button
          className={styles.editWebPageBtn}
          btnName="Edit Webpage"
          variant={ButtonVariants.SmallTransp}
          icon={editIcon}
          iconPosition="left"
        />
      </div>
    </div>
  );
}

WebContent.defaultProps = {
  className: '',
};

export default WebContent;
