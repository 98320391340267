import { companyIcon, userIcon } from 'constants/index';
import { UserSettingsView } from 'enums';

export const SETTING_NAV = [
  {
    id: 1,
    heading: 'Your Company',
    icon: companyIcon,
    options: [
      {
        id: '1.1',
        option: 'General',
        view: UserSettingsView.General,
      },
      {
        id: '1.2',
        option: 'Team Members',
        view: UserSettingsView.TeamMembers,
      },
    ],
  },
  {
    id: 2,
    heading: 'Your Info',
    icon: userIcon,
    options: [
      {
        id: '2.1',
        option: 'Profile',
        view: UserSettingsView.Profile,
      },
      {
        id: '2.2',
        option: 'Password And Security',
        view: UserSettingsView.PasswordAndSecurity,
      },
    ],
  },
];
