import React from 'react';
import styles from './notsetup.module.scss';

interface iProps {
  className?: string;
}

function NotSetup(props: iProps): JSX.Element {
  const { className } = props;
  return <div className={`${className} ${styles.notSetup}`}>Not Setup</div>;
}

NotSetup.defaultProps = {
  className: '',
};

export default NotSetup;
