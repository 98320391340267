import React, { useState, useEffect } from 'react';
import './slider.scss';

interface iProps {
  className?: string;
  onChange: (value: number) => void;
  onRealTimeChange?: (value: number) => void;
  value: number;
}

function RangeSlider(props: iProps): JSX.Element {
  const { className, value, onChange, onRealTimeChange } = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(value);
  }, [value]);

  return (
    <div className={`slider-container ${className}`}>
      <span className="bar">
        <span className="fill" style={{ width: `${progress}%` }} />
      </span>
      <input
        id="slider"
        className={`slider ${progress > 0 && 'inProgress'}`}
        type="range"
        min="0"
        max="100"
        value={progress}
        onMouseUp={() => {
          onChange(progress);
        }}
        onChange={(e) => {
          if (onRealTimeChange) onRealTimeChange(Number(e.target.value));
          setProgress(Number(e.target.value));
        }}
      />
    </div>
  );
}

RangeSlider.defaultProps = {
  className: '',
  onRealTimeChange: () => {},
};

export default RangeSlider;
