import { connectionIcon, disconnectionIcon, folderIcon } from 'constants/index';
import React, { useEffect, useState } from 'react';
import ShareAccess from 'components/share-access/shareAccess';
import { getOnlineStatus } from 'helpers';
import { useStore } from 'store';
import { iScreenData, iState } from 'types';
import styles from './screenPrimaryDetails.module.scss';

interface iProps {
  className?: string;
  data: iScreenData[];
}

interface iDeviceStatusCounts {
  offlineCount: number;
  onlineCount: number;
}
function ScreenPrimaryDetails(props: iProps): JSX.Element {
  const { className, data } = props;
  const [state]: [iState] = useStore();
  const { screensActiveFolder } = state.homePage || {};
  const [deviceStatusCounts, setDeviceStatusCounts] =
    useState<iDeviceStatusCounts>({ offlineCount: 0, onlineCount: 0 });
  console.log('data');

  // const getOnlineStatus = useCallback((lastPingTime) => {
  //   const ONE_MINUT = 60000;
  //   const difference = new Date().getTime() - new Date(lastPingTime).getTime();
  //   if (difference < ONE_MINUT) return true;
  //   return false;
  // }, []);

  useEffect(() => {
    let offlineCount = 0;
    let onlineCount = 0;
    if (data) {
      data.forEach((element) => {
        if (getOnlineStatus(element.lastPing)) {
          onlineCount += 1;
        } else {
          offlineCount += 1;
        }
      });
      setDeviceStatusCounts({ offlineCount, onlineCount });
    } else {
      setDeviceStatusCounts({ offlineCount: 0, onlineCount: 0 });
    }
  }, [data]);

  return (
    <div className={`${className} ${styles.screenzDetails}`}>
      <div className={styles.headingSection}>
        <button type="button">Details</button>
      </div>
      <img className={styles.folderIcon} src={folderIcon} alt="" />
      <p className={styles.paragraphOne}>Your Screens Status</p>

      <div className={styles.container}>
        <div className={styles.connectionStatus}>
          <div className={`${styles.statusIcon} ${styles.connected}`}>
            <img src={connectionIcon} alt="" />
          </div>
          <span className={styles.count}>{deviceStatusCounts.onlineCount}</span>
          <span className={styles.message}>Active Screens</span>
        </div>

        <div className={styles.connectionStatus}>
          <div className={`${styles.statusIcon} ${styles.disconnected}`}>
            <img src={disconnectionIcon} alt="" />
          </div>
          <span className={styles.count}>
            {deviceStatusCounts.offlineCount}
          </span>
          <span className={styles.message}>Players are offline</span>
        </div>
      </div>
      <ShareAccess
        className={styles.screenFolderShare}
        shareType="screen"
        heading={
          screensActiveFolder
            ? 'Has access the folder'
            : 'Has access to all screens'
        }
      />
    </div>
  );
}

ScreenPrimaryDetails.defaultProps = {
  className: '',
};

export default ScreenPrimaryDetails;
