import React from 'react';
import styles from './tempCreds.module.scss';

function Index(): JSX.Element {
  return (
    <div className={styles.tempCreds}>
      {/* <h4>Super admin temp credentials</h4>
      <span>email: </span>
      <span className={styles.select}>deeksha.r@codematrix.org</span>
      <p />
      <span>password: </span>
      <span className={styles.select}>Deeksha@24</span> */}
      {/* <p className="mb3" />
      <span>email: </span>
      <span className={styles.select}>superuseradmin@email.com</span>
      <p />
      <span>password: </span>
      <span className={styles.select}>12345</span> */}
      {/* <p className="mb3" />
      <span>email: </span>
      <span className={styles.select}>reselleradmin@email.com</span>
      <p />
      <span>password: </span>
      <span className={styles.select}>12345</span> */}
    </div>
  );
}

export default Index;
