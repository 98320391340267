import Popover from 'components/popover/popover';
import CaretFilledDown from 'components/svg/caretfilledDown';
import { contentIcon, playlistsIcon, ROUTES } from 'constants/index';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import styles from './playlistContentPopover.module.scss';

interface iProps {
  className?: string;
  setView: (a: 'Playlists' | 'Content') => void;
}

const SCREENS_VIEW_OPTIONS = [
  { id: 0, icon: playlistsIcon, option: 'Playlists' },
  { id: 1, icon: contentIcon, option: 'Content' },
];

const PLAYLIST_VIEW_OPTIONS = [
  { id: 1, icon: contentIcon, option: 'All Content' },
];

function PlaylistContentPopover(props: iProps): JSX.Element {
  const { className, setView } = props;
  const { pathname } = useLocation();
  const [activeView, setActiveView] = useState(
    (pathname === ROUTES.playlist && PLAYLIST_VIEW_OPTIONS[0]) ||
      SCREENS_VIEW_OPTIONS[0]
  );
  const [dropDown, setDropDown] = useState(false);

  const onClick = useCallback(
    (view) => {
      setActiveView(view);
      setView(view.option);
    },
    [setView]
  );

  const VIEWS =
    (pathname === ROUTES.playlist && PLAYLIST_VIEW_OPTIONS) ||
    SCREENS_VIEW_OPTIONS;

  return (
    <Popover
      popoverClassName={styles.viewPopover}
      popoverComponent={
        <ul className={styles.viewOptions}>
          {VIEWS.map((i) => (
            <li className={styles.option} onClick={() => onClick(i)}>
              <img className={styles.icon} src={i.icon} alt="" />
              {i.option}
            </li>
          ))}
        </ul>
      }
      setPopoverEffect={setDropDown}
      popOverEffect={dropDown}
    >
      <div
        className={`${className} ${styles.playlistDropdown} ${
          dropDown && styles.active
        }`}
      >
        <img className={styles.playlistsIcon} src={activeView.icon} alt="" />
        <span className={styles.playlistName}>{activeView.option}</span>
        <CaretFilledDown className={styles.caretFilledDropdownIcon} />
      </div>
    </Popover>
  );
}

PlaylistContentPopover.defaultProps = {
  className: '',
};

export default PlaylistContentPopover;
