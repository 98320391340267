/* eslint-disable */
import Checkbox from 'components/checkbox/checkbox';
import { CONTENT_TYPE, ROUTES, videoPlayerIcon } from 'constants/index';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useStore } from 'store';
import { homePageActions, playlistPageActions } from 'store/actions';
import { iContentData, iState } from 'types';
import styles from './contentList.module.scss';

interface iProps {
  className?: string;
  searchKey?: string;
  checked?: any;
}

function ContentList(props: iProps): JSX.Element {
  const { className, searchKey = '', checked } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { contentToAddToPlaylist } = state.playlistsPage || {};
  const { contentToAssignScreen } = state.homePage || {};
  const { contents } = state.contentPage || {};
  const [data, setData] = useState(contents);
  const [filterData, setFilterData] = useState(true);
  const [noResultTitle, setNoresultTitle] = useState('');

  const { pathname } = useLocation();
  useEffect(() => {
    setData(contents);
  }, [contents]);

  const checkMarkDisplay = pathname === ROUTES.screenDetails;

  useEffect(() => {
    if (searchKey !== '')
      setData(
        [...contents].filter((i) =>
          i.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        )
      );
    else setData(contents);
  }, [searchKey, contents, checked, contentToAddToPlaylist]);

  const onClickContent = useCallback(
    (content) => {
      if (pathname === ROUTES.playlist) {
        dispatch(playlistPageActions.addContentToPlaylist(content));
      }
      if (pathname === ROUTES.screenDetails) {
        dispatch(homePageActions.setContentToAssignToScreen(content.id));
        dispatch(homePageActions.setPlaylistToAssignToScreen(''));
      }
    },
    [dispatch, pathname]
  );

  const filteredData = useCallback(
    (i: iContentData) => {
      if (checked.image && !checked.videos && !checked.webPages) {
        return i.contentType === CONTENT_TYPE.Image && i;
      }
      if (checked.image && checked.videos && !checked.webPages) {
        return (
          (i.contentType === CONTENT_TYPE.Image ||
            i.contentType === CONTENT_TYPE.Video) &&
          i
        );
      }
      if (checked.image && !checked.videos && checked.webPages) {
        return (
          (i.contentType === CONTENT_TYPE.Image ||
            i.contentType === CONTENT_TYPE.Website) &&
          i
        );
      }
      if (checked.videos && !checked.image && !checked.webPages) {
        return i.contentType === CONTENT_TYPE.Video && i;
      }
      if (checked.videos && !checked.image && checked.webPages) {
        return (
          (i.contentType === CONTENT_TYPE.Video ||
            i.contentType === CONTENT_TYPE.Website) &&
          i
        );
      }
      if (checked.webPages && !checked.image && !checked.videos) {
        return i.contentType === CONTENT_TYPE.Website && i;
      }
      return i;
    },
    [checked]
  );

  useEffect(() => {
    if (data) {
      const length = data.filter((i: iContentData) => {
        if (checked.image && !checked.videos && !checked.webPages) {
          return i.contentType === CONTENT_TYPE.Image && i;
        }
        if (checked.image && checked.videos && !checked.webPages) {
          return (
            (i.contentType === CONTENT_TYPE.Image ||
              i.contentType === CONTENT_TYPE.Video) &&
            i
          );
        }
        if (checked.image && !checked.videos && checked.webPages) {
          return (
            (i.contentType === CONTENT_TYPE.Image ||
              i.contentType === CONTENT_TYPE.Website) &&
            i
          );
        }
        if (checked.videos && !checked.image && !checked.webPages) {
          return i.contentType === CONTENT_TYPE.Video && i;
        }
        if (checked.videos && !checked.image && checked.webPages) {
          return (
            (i.contentType === CONTENT_TYPE.Video ||
              i.contentType === CONTENT_TYPE.Website) &&
            i
          );
        }
        if (checked.webPages && !checked.image && !checked.videos) {
          return i.contentType === CONTENT_TYPE.Website && i;
        }
        return i;
      });
      if (length.length === 0) {
        setFilterData(false);
        const { image, videos, webPages } = checked;
        if (webPages && !videos && !image) {
          setNoresultTitle('webpage');
          return;
        }
        if (!webPages && videos && !image) {
          setNoresultTitle('video');
          return;
        }
        if (!webPages && !videos && image) {
          setNoresultTitle('image');
          return;
        }
        setNoresultTitle('');
        return;
      }
      setFilterData(true);
    }
  }, [checked]);

  return (
    <div className={`${className} ${styles.contentList}`}>
      {!filterData && (
        <p className={`${styles.noPlaylistsFound} `}>
          {noResultTitle
            ? `No ${noResultTitle} exists. You can upload a ${noResultTitle}.`
            : `No content exists. You can upload content.`}
        </p>
      )}
      {filterData &&
        data.filter(filteredData).map((content: iContentData, _, arr) => {
          const found = contentToAddToPlaylist.find((i) => i.id === content.id);
          // console.log('foundfoundfoundfound', contentToAddToPlaylist);
          const isSelected =
            (found && true) || content.id === contentToAssignScreen;
          return (
            <div
              className={`${styles.content} ${isSelected && styles.active}`}
              onClick={() => onClickContent(content)}
            >
              <Checkbox
                className={styles.checkbox}
                checkmarkStyle={!checkMarkDisplay}
                isChecked={isSelected}
              />
              <div className={styles.previewWrapper}>
                <img src={content.url} alt="" />
                {content.contentType === 'video' && (
                  <img
                    className={styles.videoPlayerIcon}
                    src={videoPlayerIcon}
                    alt=""
                  />
                )}
              </div>
              <div className={styles.contentNameAndLength}>
                <p>{content.name}</p>
                <span>
                  {content.contentType === CONTENT_TYPE.Image &&
                    `${content.contentType} / ${content.dimension}`}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

ContentList.defaultProps = {
  className: '',
  searchKey: '',
  checked: {
    showAll: false,
    image: false,
    videos: false,
    webPages: false,
  },
};

export default ContentList;
