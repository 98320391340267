import React from 'react';
import { WebIcon } from 'constants/icons';
import Buttons from './buttons/buttons';
import styles from './header.module.scss';

interface iProps {
  className?: string;
}

function Header(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.addWebContentHeader} ${className}`}>
      <div className={styles.leftOne}>
        <img className={styles.webIcon} src={WebIcon} alt="" />
        <p className={styles.heading}>Add Webpage</p>
      </div>
      <div className={styles.rightOne}>
        <Buttons />
        {/* <Button
          className={styles.btns}
          btnName="Cancel"
          variant={ButtonVariants.SmallTransp}
        />
        <Button
          className={styles.btns}
          btnName="Save webpage"
          variant={ButtonVariants.SmallStd}
        /> */}
      </div>
    </div>
  );
}

Header.defaultProps = {
  className: '',
};

export default Header;
