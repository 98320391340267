import Button, { ButtonVariants } from 'components/button/button';
import Popover from 'components/popover/popover';
import { caretLeft } from 'constants/index';
import React from 'react';
import SectionHeading from '../section-heading/sectionHeading';
import styles from './language.module.scss';

interface iProps {
  className?: string;
}

function ProfilePicture(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.profilePictureWrapper} ${className}`}>
      <SectionHeading className={styles.sectionHeading} heading="Language" />
      <p className={styles.description}>
        Select the language you want the Screenz UI to be shown.
      </p>

      <Popover
        className={styles.popoverWrapper}
        popoverClassName={styles.popover}
        popoverComponent={
          <ul className={styles.listContainer}>
            <li className={styles.list}>Other</li>
          </ul>
        }
      >
        <div className={styles.choosedOption}>
          <p>English</p>
          <img className={styles.caretLeft} src={caretLeft} alt="" />
        </div>
      </Popover>
      <Button
        className={styles.saveChanges}
        isDisabled
        btnName="Save changes"
        variant={ButtonVariants.SmallStd}
      />
    </div>
  );
}

ProfilePicture.defaultProps = {
  className: '',
};

export default ProfilePicture;
