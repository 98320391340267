import { gql } from 'apollo-boost';

export enum User {
  UpdateUser = 'updateuser',
  GetUserRoles = 'getUserroles',
  UpdateUserPassword = 'updateUserpassword',
  iGetUserByCompanyId = 'getuserbyCompanyid',
  AddUser = 'addUser',
  GetUserById = 'getuserbyid',
  GetUserByPassToken = 'getuserbypasstoken',
  ResendInvite = 'resendinvite',
  RevokeInvite = 'revokeinvite',
}

export const updateUserMutation = gql`
    mutation ${User.UpdateUser}(
        $firstName: String,
        $lastName: String,
        $email: String,
        $password: String,
        $profile_pic: String,
        $companyId: String,
        $roleId: String,
        $id: String!,
        $invitedStatus: String!
      ){
        ${User.UpdateUser}(
          firstName: $firstName
          lastName: $lastName
          email: $email
          password: $password
          profile_pic: $profile_pic
          companyId: $companyId
          roleId: $roleId
          id: $id
          invitedStatus: $invitedStatus
          ){
            message
            statusCode
        }
    }
`;

export const getRolesQuery = gql`
    query ${User.GetUserRoles}{
      ${User.GetUserRoles}{
        statusCode
        message
        data {
          id
          role_name
          role_permission
        }
      }
    }
`;

export const updateUserPasswordMutation = gql`
    mutation ${User.UpdateUserPassword}(
      $id: String!
      $password: String
      $oldpassword: String
      ){
        ${User.UpdateUserPassword}(
          id: $id
          password: $password
          oldpassword: $oldpassword
          ){
            message
            statusCode
            data {
              id
              firstName
              lastName
            }
        }
    }
`;

export const getUserByCompanyIdQuery = gql`
query ${User.iGetUserByCompanyId}($company: String!){
  ${User.iGetUserByCompanyId}(company: $company) {
    statusCode
    message
    data {
      id
      first_name
      last_name
      email
      password
      profile_pic
      invitedStatus
      role {
        id
        role_name
      }
    }
  }
}
`;

export const addTeamMemberMutation = gql`
  mutation ${User.AddUser}(
      $email: String!
      $companyId: String
      $roleId: String!
      $invitedStatus: String!
  ){
    ${User.AddUser}(
      email: $email
      companyId: $companyId
      roleId: $roleId
      invitedStatus: $invitedStatus
    ){
      statusCode
      message
      
    }
  }
`;

export const getUserByIdQuery = gql`
query ${User.GetUserById}($userId: String!){
  ${User.GetUserById}(userId: $userId) {
    statusCode
    message
    data {
      id
      first_name
      last_name
      email
      password
      profile_pic
      company {
        created_at
        id
      }
      role {
        id
      }

    }
  }
}
`;

export const getUserByPassTokenQuery = gql`
query ${User.GetUserByPassToken}($password_token: String!){
  ${User.GetUserByPassToken}(password_token: $password_token) {
    statusCode
    message
    data {
      id
      first_name
      last_name
      email
      password
      profile_pic
      company {
        created_at
        id
        name
      }
      role {
        id
      }
    }
  }
}
`;

export const resendInviteMutation = gql`
  mutation ${User.ResendInvite}(
    $id:String, $email:String
  ){
    ${User.ResendInvite}(
      id: $id
      email: $email
    ){
      statusCode
      message
      data{
        id
      }
    }
  }
`;

export const revokeInviteMutation = gql`
  mutation ${User.RevokeInvite}(
    $id:String, $email:String
  ){
    ${User.RevokeInvite}(
      id: $id
      email: $email
    ){
      statusCode
      message
      name
      data{
        id
      }
    }
  }
`;

export const deleteuserMutation = gql`
  mutation deleteuser($id: String) {
    deleteuser(id: $id) {
      statusCode
      message
      data {
        isDeleted
      }
    }
  }
`;
