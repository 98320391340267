import React from 'react';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import Button, { ButtonVariants } from 'components/button/button';
import { whiteAddIcon } from 'constants/index';
import RolesTable from './roles-table/rolesTable';
import styles from './roles.module.scss';

interface iProps {
  className?: string;
}

function Roles(props: iProps): JSX.Element {
  const { className } = props;
  // return <div className={`${styles.roleWrapper} ${className}`}>Roles</div>;

  return (
    <Layout variant={LayoutVariants.layoutFour}>
      <section className={`${styles.roleWrapper} ${className}`}>
        <div className={styles.componentHeading}>
          <p className={styles.heading}>Roles</p>
          <Button
            className={styles.addRoleBtn}
            btnName="Role"
            icon={whiteAddIcon}
            iconPosition="left"
            variant={ButtonVariants.SmallStd}
          />
        </div>
        <div className={styles.rolesList}>
          <RolesTable />
        </div>
      </section>
    </Layout>
  );
}

Roles.defaultProps = {
  className: '',
};

export default Roles;
