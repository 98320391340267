import Button, { ButtonVariants } from 'components/button/button';
import Input from 'components/input/input';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import Screen from 'components/svg/screenz';
import { delete2Icon, duplicateIcon, playlistsIcon } from 'constants/index';
import React, { useCallback, useState } from 'react';
import { useStore } from 'store';
import ShareAccess from 'components/share-access/shareAccess';
import { playlistPageActions } from 'store/actions';
import { iState } from 'types';
import ColorPicker from '../color-picker/colorPicker';
import styles from './details.module.scss';

interface iProps {
  className?: string;
  variant?: 'desktop' | 'mobile';
  setLatePlaylistName?: any;
}

function Index(props: iProps): JSX.Element {
  const { className, variant = 'desktop', setLatePlaylistName } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [isUpdatedName, setIsUpdatedName] = useState<boolean>(false);
  const { playlistData } = state.playlistsPage || {};

  const onChange = useCallback(
    (event) => {
      dispatch(
        playlistPageActions.updatePlaylist({
          ...playlistData,
          [event.target.name]: event.target.value,
        })
      );
      setIsUpdatedName(true);
      setLatePlaylistName(true);
    },
    [dispatch, playlistData, setLatePlaylistName]
  );

  const updatePlaylistHandler = useCallback(() => {
    dispatch(playlistPageActions.disablePlaylistSaveAndCloseBtn(false));
    setIsUpdatedName(false);
  }, [dispatch]);

  return (
    <div className={`${className} ${styles.details}`}>
      {variant === 'desktop' && (
        <div className={styles.header}>
          <img className={styles.playlistsIcon} src={playlistsIcon} alt="" />
          <span className={styles.heading}>Playlist</span>
        </div>
      )}
      <div className={styles.screenNameAndHashCode}>
        <LabelAndInput
          className={styles.playlistName}
          value={playlistData?.name || 'Playlist name'}
          name="name"
          placeholder="Playlist name"
          label="Playlist name"
          onChange={onChange}
        />
        <Button
          className={styles.saveBtn}
          variant={ButtonVariants.ChangeView}
          btnName="Save"
          isActive={isUpdatedName}
          onClick={updatePlaylistHandler}
        />
      </div>
      <div className={styles.contentSection}>
        <p className={styles.sectionHeading}>Info</p>
        <p className={styles.sectionHeadingSize}>Size</p>
        <div className={styles.deviceTypeContainer}>
          <div
            className={`${styles.deviceType} ${styles.desktopSizeDisappear}`}
          >
            <span>Size</span>
          </div>
          <div className={`${styles.deviceType}`}>
            <Screen className={styles.screenIcon} />
            <span>Landscape</span>
          </div>
        </div>
        <div className={styles.deviceScreenSize}>
          <Input value="1920" onChange={() => {}} />
          <span>*</span>
          <Input value="1080" onChange={() => {}} />
          <Button
            className={styles.saveBtn}
            btnName="Save"
            variant={ButtonVariants.ChangeView}
            isActive={isUpdatedName}
          />
        </div>
      </div>
      <ShareAccess
        className={styles.shareAccess}
        shareType="playlist"
        heading=""
      />
      <ColorPicker className={styles.colorPicker} />
      <div className={styles.mutateOption}>
        <img src={duplicateIcon} alt="" />
        <p>Duplicate playlist</p>
      </div>
      <div className={styles.mutateOption}>
        <img src={delete2Icon} alt="" />
        <p>Delete playlist</p>
      </div>
    </div>
  );
}

Index.defaultProps = {
  className: '',
  variant: 'desktop',
  setLatePlaylistName: () => {},
};

export default Index;
