import { updateUser } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import { dustbinIcon } from 'constants/index';
import { s3Operations, toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import CropProfilePic from 'components/crop-profile-pic/cropProfilePic';
import { userActions } from 'store/actions';
import { iState } from 'types';
import styles from './profilePicture.module.scss';

interface iProps {
  className?: string;
  enableBtn?: any;
  setEnableBtn?: any;
}

function ProfilePicture(props: iProps): JSX.Element {
  const { className, enableBtn, setEnableBtn } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { loggedInData } = state.user || {};
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileToCrop, setFileToCrop] = useState<File>();
  const { firstName, lastName, id, email, companyData, roleId, profile_pic } =
    loggedInData || {};
  const [tempProfilePic, setTempProfilePic] = useState('');
  // const [croppedImage, setCroppedImage] = useState<File>();
  // const [disabled, setDisabled] = useState<boolean>(true);

  // console.log('jiojijoo', croppedImage);

  useEffect(() => console.log(enableBtn, 'enableBtn'), [enableBtn]);
  const updateUserHandler = useCallback(async () => {
    if (loggedInData) {
      console.log('profilePic___', { profile_pic, setIsShowCropModal });

      const response = await updateUser(
        {
          firstName,
          id: id || '',
          email,
          lastName,
          profile_pic: tempProfilePic || profile_pic || '',
          companyId: companyData?.id,
          roleId: `${roleId}`,
          invitedStatus: 'ACCEPTED',
        },
        () => {}
      );
      if (response) {
        console.log(response);
        // setDisabled(false);
        toaster.success('Profile updated successfully');
        setEnableBtn(false);
      }
    }
  }, [
    id,
    loggedInData,
    firstName,
    lastName,
    companyData?.id,
    email,
    roleId,
    profile_pic,
    tempProfilePic,
    setEnableBtn,
  ]);

  useEffect(() => {
    if (tempProfilePic) updateUserHandler();
  }, [tempProfilePic, updateUserHandler]);

  const removeProfilePic = useCallback(() => {
    if (loggedInData) {
      dispatch(
        userActions.updateLoggedInUserData({
          ...loggedInData,
          profile_pic: '',
        })
      );
      setTempProfilePic('');

      (async () => {
        if (loggedInData) {
          console.log('profilePic___', { profile_pic });

          const response = await updateUser(
            {
              firstName,
              id: id || '',
              email,
              lastName,
              profile_pic: tempProfilePic || profile_pic || '',
              companyId: companyData?.id,
              roleId: `${roleId}`,
              invitedStatus: 'ACCEPTED',
            },
            () => {}
          );
          if (response) {
            console.log(response);
            // setDisabled(false);
            toaster.success('Profile deleted successfully');
            setEnableBtn(false);
          }
        }
      })();
    }
  }, [
    loggedInData,
    dispatch,
    companyData?.id,
    email,
    firstName,
    id,
    lastName,
    profile_pic,
    roleId,
    setEnableBtn,
    tempProfilePic,
  ]);

  const fileChooseHandler = useCallback(
    async (event) => {
      if (!loggedInData) {
        toaster.error('Something went wrong');
        return;
      }
      const file: File = event.target.files[0];
      console.log('fileChooseHandler', file);
      setIsShowCropModal(true);
      setFileToCrop(file);

      // if ([].length === 0) return;
      // const response: any = await s3Operations.upload({
      //   params: {
      //     Key: file.name,
      //     Body: file,
      //   },
      //   userId: id,
      //   uploadType: 'profile',
      // });
      // if (response) {
      //   console.log('profile_pic_uploaded_success_fully', response);
      //   dispatch(
      //     userActions.updateLoggedInUserData({
      //       ...loggedInData,
      //       profile_pic: response.Location,
      //     })
      //   );
      //   setTempProfilePic(response.Location);
      //   // updateUserHandler();
      // }
    },
    [loggedInData]
  );

  const uploadProfilePic = useCallback(
    async (croppedImage: File) => {
      console.log('upload_cropped_picture', croppedImage);

      if (!loggedInData) {
        toaster.error('Something went wrong');
        return;
      }
      if (!croppedImage) return;
      setIsLoading(true);
      const response: any = await s3Operations.upload({
        params: {
          Key: croppedImage.name,
          Body: croppedImage,
        },
        userId: id,
        uploadType: 'profile',
      });
      if (response) {
        console.log('profile_pic_uploaded_success_fully', response);
        dispatch(
          userActions.updateLoggedInUserData({
            ...loggedInData,
            profile_pic: response.Location,
          })
        );
        setTempProfilePic(response.Location);
        setIsLoading(false);
        setIsShowCropModal(false);
        toaster.success('Profile pic uploaded successfully');
        // updateUserHandler();
      }
    },
    [dispatch, id, loggedInData]
  );

  // useCallback(() => {
  //   (async () => {
  //     alert('FILE has cropped');
  //   })();
  // }, [croppedImage, dispatch, id, loggedInData]);

  return (
    <div className={`${styles.profilePictureWrapper} ${className}`}>
      <p className={styles.sectionHeading}>Profile picture</p>
      {fileToCrop && (
        <CropProfilePic
          isOpen={isShowCropModal}
          file={fileToCrop}
          isLoading={isLoading}
          onClose={() => setIsShowCropModal(false)}
          onCropImageHandler={uploadProfilePic}
        />
      )}
      <div className={styles.profilePicAndActions}>
        <div className={styles.profilePic}>
          {tempProfilePic || profile_pic ? (
            <img src={tempProfilePic || profile_pic} alt="" />
          ) : (
            <span>
              <div className={styles.uploadPro}>
                {/* <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={fileChooseHandler}
                />
                <img
                  src={whiteAddCircle}
                  alt=""
                  className={styles.addImage}
                  onClick={fileChooseHandler}
                /> */}
              </div>
              {(firstName && firstName[0]) || ''}
              {(lastName && lastName[0]) || ''}
            </span>
          )}
        </div>
        <div className={styles.actionsContainer}>
          <div className={styles.uploadProfilePic}>
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              onChange={fileChooseHandler}
            />
            <Button
              className={styles.upload}
              btnName="Upload picture"
              variant={ButtonVariants.SmallStd}
              // onClick={fileChooseHandler}
            />
          </div>
          <Button
            className={styles.delete}
            btnName="Delete"
            variant={ButtonVariants.SmallTransp}
            icon={dustbinIcon}
            iconPosition="left"
            onClick={removeProfilePic}
          />
        </div>
      </div>
      <Button
        className={styles.saveChanges}
        // isDisabled
        btnName="Save changes"
        isDisabled={!enableBtn}
        variant={ButtonVariants.SmallStd}
        onClick={updateUserHandler}
      />
    </div>
  );
}

ProfilePicture.defaultProps = {
  className: '',
  enableBtn: false,
  setEnableBtn: () => {},
};

export default ProfilePicture;
