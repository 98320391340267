import EmptyState, {
  EmptyStateVariants,
} from 'components/empty-state/emptyState';
import Folders from 'components/folders/folders';
import PlaylistTable from 'components/playlists/playlist-table/playlistTable';
import { deletePlaylist } from 'apis';
import Playlists, {
  PlaylistVariants,
} from 'components/playlists/playlists/playlists';

import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import FoldersSkeleton from 'components/skeletons/folders-skeleton/foldersSkeleton';
import ListingSkeleton from 'components/skeletons/listing-skeleton/listingSkeleton';
import SubFolderEmptyState from 'components/sub-folder-empty-state/subFolderEmptyState';
import { toaster } from 'helpers';
import { playlistTableColumns, ROUTES } from 'constants/index';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { layoutActions, playlistPageActions } from 'store/actions';
import { iPlaylistData, iState } from 'types';
import ScreensDetails from '../playlist-details/playlistDetails';
import styles from './playlistsList.module.scss';

interface iProps {
  className?: string;
  searchedData?: iPlaylistData[] | null;
  isLoading: boolean;
}
function PlaylistsList(props: iProps): JSX.Element {
  const { className, searchedData, isLoading } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { chosenView } = state.layout || {};
  const [playlistDataToDelete, setPlaylistDataToDelete] =
    useState<iPlaylistData>();
  const [isDeleting, setIsDeliting] = useState<boolean>(false);
  const [isPlaylistUsedSomeWhere, setIsPlaylistUsedSomeWhere] =
    useState<boolean>(false);
  const { playlists, playlistFolders, playlistActiveFolder } =
    state.playlistsPage || {};
  const navigate = useNavigate();

  console.log('playlistDataToDelete____', { isLoading, playlistDataToDelete });

  const openPlaylist = useCallback(
    (data) => {
      navigate(ROUTES.playlist);
      dispatch(playlistPageActions.setPlaylistData(data));
    },
    [navigate, dispatch]
  );

  const deletePlaylistHandler = useCallback(
    async (canDelete: boolean) => {
      if (!playlistDataToDelete) return;

      const response = await deletePlaylist(
        {
          id: playlistDataToDelete.id,
          is_data: canDelete,
        },
        setIsDeliting
      );
      console.log('DELETE_PLAYLIST', response);
      if (response === 'DELETED') {
        toaster.success('Playlist deleted successfully');
        setIsPlaylistUsedSomeWhere(false);
        dispatch(layoutActions.toggleModal());
        dispatch(playlistPageActions.fetchPlaylistData(true));
      }
      if (response === 'USED_SOMEWHERE') {
        setIsPlaylistUsedSomeWhere(true);
      }
    },
    [dispatch, playlistDataToDelete]
  );

  return (
    <div
      className={`${className} ${styles.listAndDetails} ${
        playlists?.length > 0 && styles.displayboth
      }`}
    >
      <div
        className={`${styles.foldersAndScreensWrapper} ${styles[chosenView]}`}
      >
        {isLoading ? (
          <FoldersSkeleton />
        ) : (
          playlistFolders?.length > 0 && (
            <Folders
              className={styles.foldersWrapper}
              data={playlistFolders}
              openFolder={(folder) => {
                dispatch(playlistPageActions.setPlaylistsActiveFolder(folder));
              }}
            />
          )
        )}

        {playlists?.length === 0 &&
          playlistFolders?.length === 0 &&
          !playlistActiveFolder && (
            <EmptyState variant={EmptyStateVariants.Playlists} />
          )}
        {(playlistFolders?.length > 0 || playlistActiveFolder) &&
          playlists?.length === 0 && <SubFolderEmptyState />}
        {isLoading ? (
          <ListingSkeleton />
        ) : (
          playlists?.length > 0 && (
            <div className={`${className} ${styles.screenList}`}>
              <PlaylistTable
                columns={playlistTableColumns}
                data={
                  searchedData && searchedData?.length > 0
                    ? searchedData
                    : playlists
                }
                className={styles.playlistTable}
                onClick={openPlaylist}
                setDataToDelete={setPlaylistDataToDelete}
              />
              <Playlists
                foundPlaylists={searchedData}
                variant={PlaylistVariants.InPlaylist}
                className={styles.forMobile}
                onClick={openPlaylist}
              />
            </div>
          )
        )}
      </div>

      {playlists?.length > 0 && (
        <ScreensDetails
          className={`${styles.detailView} ${styles[chosenView]}`}
          isLoading={isLoading}
        />
      )}
      {playlistDataToDelete && (
        <DeleteModal
          variant={DeleteModalVariants.Playlist}
          name={playlistDataToDelete?.name || ''}
          isLoading={isDeleting}
          isUsedSomewhere={isPlaylistUsedSomeWhere}
          deleteHandler={() => deletePlaylistHandler(isPlaylistUsedSomeWhere)}
        />
      )}
    </div>
  );
}

PlaylistsList.defaultProps = {
  className: '',
  searchedData: [],
};

export default PlaylistsList;
