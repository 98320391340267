export const USERS = [
  {
    id: 1,
    firstName: 'Leanne',
    lastName: 'Graham',
    email: 'Sincere@april.biz',
    website: 'hildegard.org',
  },
  {
    id: 2,
    firstName: 'Ervin',
    lastName: 'Howell',
    email: 'Shanna@melissa.tv',
    website: 'anastasia.net',
  },
  {
    id: 3,
    firstName: 'Clementine',
    lastName: 'Bauch',
    email: 'Nathan@yesenia.net',
    website: 'ramiro.info',
  },
  {
    id: 4,
    firstName: 'Patricia',
    lastName: 'Lebsack',
    email: 'Julianne.OConner@kory.org',
    website: 'kale.biz',
  },
  {
    id: 5,
    firstName: 'Chelsey',
    lastName: 'Dietrich',
    email: 'Lucio_Hettinger@annie.ca',
    website: 'demarco.info',
  },
  {
    id: 6,
    firstName: 'Mrs.',
    lastName: 'Schulist',
    email: 'Karley_Dach@jasper.info',
    website: 'ola.org',
  },
  {
    id: 7,
    firstName: 'Kurtis',
    lastName: 'Weissnat',
    email: 'Telly.Hoeger@billy.biz',
    website: 'elvis.io',
  },
  {
    id: 8,
    firstName: 'Nicholas',
    lastName: 'Runolfsdottir',
    email: 'Sherwood@rosamond.me',
    website: 'jacynthe.com',
  },
  {
    id: 9,
    firstName: 'Glenna',
    lastName: 'Reichert',
    email: 'Chaim_McDermott@dana.io',
    website: 'conrad.com',
  },
  {
    id: 10,
    firstName: 'Clementina',
    lastName: 'DuBuque',
    email: 'Rey.Padberg@karina.biz',
    website: 'ambrose.net',
  },
];

export const ACCESS_GIVEN_TO = [
  {
    id: 1,
    firstName: 'Clementine',
    lastName: 'Bauch',
    email: 'Nathan@yesenia.net',
    website: 'ramiro.info',
  },
  {
    id: 4,
    firstName: 'Patricia',
    lastName: 'Lebsack',
    email: 'Julianne.OConner@kory.org',
    website: 'kale.biz',
  },
  {
    id: 5,
    firstName: 'Chelsey',
    lastName: 'Dietrich',
    email: 'Lucio_Hettinger@annie.ca',
    website: 'demarco.info',
  },
];
