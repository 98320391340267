import ApolloClient, { DocumentNode } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { API_ENDPOINT } from 'constants/index';
import fetch from 'isomorphic-unfetch';

const client = new ApolloClient({
  fetch,
  uri: API_ENDPOINT,
  // eslint-disable-next-line
  cache: new InMemoryCache({ dataIdFromObject: (o: any): any => false }),
  request: () => {
    //
  },
  onError: (error) => {
    console.log({ error });
  },
});

const STATUS_CODES = [200, 204, 201];
export const Mutation = async (
  mutation: DocumentNode,
  variables: any
): Promise<any> => {
  try {
    const response: any = await client.mutate({ mutation, variables });
    const key = Object.keys(response.data)[0];
    const { statusCode, message } = response.data[key] || {};

    if (!STATUS_CODES.includes(statusCode)) throw new Error(message);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const Query = async (
  query: DocumentNode,
  variables?: any
): Promise<any> => {
  try {
    const response: any = await client.query({
      query,
      variables,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    });
    const key = Object.keys(response.data)[0];
    const { statusCode, message } = response.data[key] || {};
    // if (statusCode !== 200) throw new Error(message);
    if (!STATUS_CODES.includes(statusCode)) throw new Error(message);
    return response;
  } catch (error) {
    console.log();
    throw error;
  }
};
