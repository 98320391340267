import EmptyState, {
  EmptyStateVariants,
} from 'components/empty-state/emptyState';
import RoleBasedHeader from 'components/Layouts/components/role-based-header/roleBasedHeader';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import { toaster, uuid } from 'helpers';
import { updateSystemConfig, getAllSystemConfigs } from 'apis';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iEmptyState } from 'types';
import HeaderTitle from 'modules/user/components/general/header-title/headerTitle';
import React, { useCallback, useState } from 'react';
import { EmptyScreens } from 'enums';
import Subheader from './components/subheader/subheader';
import Table from './components/table/table';
import styles from './emptyStates.module.scss';

const CLIENT_EMPTY_STATES = [
  {
    id: uuid(),
    value: 'Screens',
    key: EmptyScreens.Screens,
    languages: [
      { id: '1', language: 'NO' },
      { id: '2', language: 'EN' },
    ],
    lastUpdated: '01.01.2017  12:34',
  },
  {
    id: uuid(),
    value: 'Content',
    key: EmptyScreens.Content,
    languages: [
      { id: '1', language: 'NO' },
      { id: '2', language: 'EN' },
    ],
    lastUpdated: '01.01.2017  12:34',
  },
  {
    id: uuid(),
    value: 'Playlists',
    key: EmptyScreens.Playlists,
    languages: [
      { id: '1', language: 'NO' },
      { id: '2', language: 'EN' },
    ],
    lastUpdated: '01.01.2017  12:34',
  },
];

const SUPER_USER_EMPTY_STATES = [
  {
    id: uuid(),
    value: 'Clients',
    key: EmptyScreens.Clients,
    languages: [
      { id: '1', language: 'NO' },
      { id: '2', language: 'EN' },
    ],
    lastUpdated: '01.01.2017  12:34',
  },
];

function EmptyStates(): JSX.Element {
  const [, dispatch] = useStore();
  const [currentView, setCurrentView] = useState<EmptyScreens | ''>('');
  const [canSaveChanges, setCanSaveChanges] = useState<boolean>(false);
  const [emptyStateToUpdate, setEmptyStateToUpdate] = useState<iEmptyState>();

  const { Screens, Content, Playlists, Clients } = EmptyScreens;

  const onCancelChanges = (): void => {
    setCurrentView('');
    setCanSaveChanges(false);
  };

  const saveChange = useCallback(async () => {
    if (emptyStateToUpdate) {
      const response = await updateSystemConfig({
        id: emptyStateToUpdate.id,
        sysc_key: emptyStateToUpdate.key,
        sysc_value: JSON.stringify({
          title: emptyStateToUpdate.title,
          description: emptyStateToUpdate.description,
          bannerImg: emptyStateToUpdate.bannerImg,
        }),
      });
      if (response) {
        setCurrentView('');
        const systemConfigs = await getAllSystemConfigs();
        if (systemConfigs)
          dispatch(layoutActions.setEmptyStates(systemConfigs));
        setCanSaveChanges(false);
        toaster.success('Updated empty state successfully');
      }
    }
  }, [emptyStateToUpdate, dispatch]);

  const heading =
    (currentView === Screens && 'Screens') ||
    (currentView === Content && 'Contents') ||
    (currentView === Playlists && 'Playlists') ||
    (currentView === Clients && 'Clients');

  return (
    <Layout variant={LayoutVariants.layoutOne}>
      <div className={styles.emptyStates}>
        <RoleBasedHeader />
        <Subheader />
        {currentView === '' ? (
          <div className={styles.pageWrapper}>
            <p className={styles.heading}>Clients</p>
            <Table
              rows={CLIENT_EMPTY_STATES}
              onClick={(row) => {
                setCurrentView(row.key);
              }}
            />
            <p className={styles.heading}>Superuser</p>
            <Table
              rows={SUPER_USER_EMPTY_STATES}
              onClick={(row) => {
                setCurrentView(row.key);
              }}
            />
          </div>
        ) : (
          <>
            <div className={styles.subHeader}>
              <div>{heading}</div>
              <HeaderTitle
                // isDisabled={!showCancelAndSaveBtns}
                isDisabled={canSaveChanges}
                onCancelChanges={onCancelChanges}
                onSaveChanges={saveChange}
              />
            </div>
            {currentView && (
              <EmptyState
                editable
                setCanSaveChanges={setCanSaveChanges}
                setEmptyStateToUpdate={setEmptyStateToUpdate}
                variant={
                  (currentView === EmptyScreens.Screens &&
                    EmptyStateVariants.Screenz) ||
                  (currentView === EmptyScreens.Clients &&
                    EmptyStateVariants.Clients) ||
                  (currentView === EmptyScreens.Playlists &&
                    EmptyStateVariants.Playlists) ||
                  (currentView === EmptyScreens.Content &&
                    EmptyStateVariants.Content) ||
                  null
                }
              />
            )}
          </>
        )}
      </div>
    </Layout>
  );
}

export default EmptyStates;
