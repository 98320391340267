import { getAllSystemConfigs, getUserRoles, login } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import LabelAndInput from 'components/label-and-input/labelAndInput';
// import Input from 'components/input/input';
import Logo from 'components/logo/logo';
import { ROLES, ROUTES } from 'constants/index';
import { LayoutViews, TwoFAType } from 'enums';
import { validators } from 'helpers';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { homePageActions, layoutActions, userActions } from 'store/actions';
import { iLoginApiArgs } from 'types';
import Pattern from '../components/pattern/pattern';
import styles from './login.module.scss';
import TempCreds from './temp-creds/tempCreds';

function Login(): JSX.Element {
  const [credentials, setCredentials] = useState<iLoginApiArgs>({
    email: '',
    password: '',
  });
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { email, password } = credentials || {};
  const [, dispatch] = useStore();
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState('');

  // useEffect(() => {

  // }, []);
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmailErrorMessage('');
      setPasswordErrorMessage('');
      setCredentials({
        ...credentials,
        [event.target.name]: event.target.value,
      });
    },
    [credentials]
  );

  // const handleGetStarted = useCallback(() => {
  //   navigate('#');
  // }, [navigate]);

  const handleGetStarted = useCallback(() => {
    window.open('https://en.screenz.no/');
    // React.useEffect(() => {
    //   window.location.replace('https://www.google.com');
    // }, []);
  }, []);
  const loginHandler = useCallback(async () => {
    if (!email) {
      setEmailErrorMessage('Please enter a email');
      return;
    }
    if (!password) {
      setPasswordErrorMessage('Please enter a password');
      return;
    }
    if (!validators.isEmail(email)) {
      setEmailErrorMessage('Please enter a valid email');
      return;
    }
    if (email === 'superuseradmin@email.com' && password === '12345') {
      dispatch(
        userActions.setLoginData({
          data: {
            id: '123456',
            firstName: 'Super',
            lastName: 'Admin',
            email: 'superadmin@email.com',
            password: '',
            profile_pic: '',
            companyData: null,
            roleId: '3',
            roleName: LayoutViews.SuperAdminClients,
            jwtToken: '',
            settings: {
              id: '123456',
              isDarkMode: false,
              twoFA: TwoFAType.DISABLED,
              authQR: '',
            },
          },
        })
      );
      dispatch(layoutActions.setHomePageView(LayoutViews.SuperAdminClients));
      navigate(ROUTES.home, { replace: true });
    } else {
      const response = await login(credentials, setIsLoading, setLoginInfo);

      if (!response) {
        if (loginInfo.includes(`404`)) {
          setEmailErrorMessage(`User does not exist`);
          setPasswordErrorMessage(`User Does not exist`);
          return;
        }
        setEmailErrorMessage(`Unauthorised: Invalid Email or Password`);
        setPasswordErrorMessage(`Unauthorised: Invalid Email or Password`);
        return;
      }
      const { settings } = response || {};
      if (settings.twoFA === (TwoFAType.AUTHAPP || TwoFAType.EMAIL)) {
        dispatch(
          userActions.set2FAData({
            id: response.id,
            twoFA: TwoFAType[settings.twoFA],
            isDarkMode: false,
            authQR: '',
          })
        );
        navigate(ROUTES.verify2fa);
        return;
      }
      const roles = (await getUserRoles()) || [];
      const systemConfigs = await getAllSystemConfigs();
      console.log(systemConfigs, 'systemConfigs');
      if (systemConfigs) {
        dispatch(layoutActions.setEmptyStates(systemConfigs));
      }
      dispatch(userActions.setLoginData({ data: response, roles }));
      if (Number(response.roleId) !== ROLES.super_admin)
        dispatch(homePageActions.setCompanyData(response.companyData));
      dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      navigate(ROUTES.home, { replace: true });
      localStorage.setItem('isLoggedIn', 'true');

      // if (email === 'client@email.com' && password === '12345') {
      //   dispatch(
      // userActions.login({
      //   ...state.user,
      //   role: UserRoles.Client,
      //   isLoggedIn: true,
      // })
      //   );
      //   dispatch(layoutActions.setHomePageView(LayoutViews.Screens));
      //   navigate(ROUTES.home);
      // }
      // if (email === 'superuseradmin@email.com' && password === '12345') {
      //   dispatch(
      //     userActions.login({
      //       ...state.user,
      //       role: UserRoles.SuperAdmin,
      //       isLoggedIn: true,
      //     })
      //   );
      //   dispatch(layoutActions.setHomePageView(LayoutViews.SuperAdminClients));
      //   navigate(ROUTES.home, { replace: true });
      // }
      // if (email === 'reselleradmin@email.com' && password === '12345') {
      //   dispatch(
      //     userActions.login({
      //       ...state.user,
      //       role: UserRoles.Reseller,
      //       isLoggedIn: true,
      //     })
      //   );
      //   dispatch(layoutActions.setHomePageView(LayoutViews.ResellerClients));
      //   navigate(ROUTES.home, { replace: true });
      // }
    }
  }, [email, password, credentials, dispatch, navigate, loginInfo]);

  return (
    <div
      className={`flex flex-col p3 ${styles.loginWrapper}`}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          loginHandler();
        }
      }}
    >
      <Pattern position="top" />
      <Pattern position="bottom" />
      <header className={styles.header}>
        <Button
          className={styles.getStartBtn}
          variant={ButtonVariants.MediumTransp}
          btnName="Get started"
          onClick={handleGetStarted}
        />
        <p className={styles.dontHaveAccount}>Don't have an account?</p>
      </header>
      <section className={`flex-center flex-grow ${styles.section}`}>
        <div className={`${styles.loginContainer}`}>
          <Logo className={styles.logo} variant="onboarding" />
          <p className={styles.signInMessage}>Sign in to Screenz</p>
          <p className={styles.enterDetails}>Enter your details below.</p>

          <LabelAndInput
            className={styles.emailField}
            label="Enter email"
            name="email"
            value={email}
            onChange={onChange}
            errorMessage={emailErrorMessage}
          />
          <div className={styles.passwordAndForgotPasswordLink}>
            <Link className={styles.forgotPassword} to={ROUTES.forgotPassword}>
              Forgot your password?
            </Link>

            <LabelAndInput
              className={styles.emailField}
              type="password"
              label="Password"
              name="password"
              value={password}
              onChange={onChange}
              errorMessage={passwordErrorMessage}
            />
          </div>

          {/* <div className="flex w-100 align-items-center justify-content-space-between">
            <p className={styles.enterPassword}>Password</p>
          </div>
          <Input
            className={styles.input}
            name="password"
            type="password"
            value={password}
            onChange={onChange}
          /> */}
          <Button
            className={styles.loginBtn}
            btnName="Sign in"
            variant={ButtonVariants.MediumStd}
            onClick={loginHandler}
            isLoading={isLoading}
          />
          <TempCreds />
        </div>
      </section>
      {/* <Chat /> */}
    </div>
  );
}

export default Login;
