import { getContentsByCompany, searchContent } from 'apis';
import AddFolderModal from 'components/add-folder-modal/addFolderModal';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import UploadContentModal from 'components/upload-content-modal/uploadContentModal';
import UploadContentWebpageModal from 'components/upload-content-webpage-modal/uploadContentWebpageModal';
import { toaster } from 'helpers';
// import Loader from 'components/loader/loader';
import SideDrawer from 'components/side-drawer/sideDrawer';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { contentPageActions, layoutActions } from 'store/actions';
import { iContentData, iState } from 'types';
import ContentList from './components/content-list/contentList';
import styles from './content.module.scss';

function Content(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { homePage, contentPage } = state || {};
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(contentPageActions.setContents([]));
  }, []);

  const [searchContentData, setSearchContentData] =
    useState<Array<iContentData> | null>(null);
  const {
    contents,
    canFetchContentsData,
    searchContentKey,
    contentActiveFolder,
  } = contentPage || {};
  const { companyData } = homePage || {};
  const { id: companyId } = companyData || {};
  const { id: folderId } = contentActiveFolder || {};

  const getContents = useCallback(async () => {
    if (!companyId) return;
    const response = await getContentsByCompany(
      {
        company: companyId,
        folder: folderId || '',
      },
      setIsLoading
    );
    if (response) {
      dispatch(contentPageActions.setContents(response));
      dispatch(contentPageActions.fetchContentsData(false));
    }
  }, [companyId, folderId, dispatch]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  useEffect(() => {
    if (canFetchContentsData) getContents();
  }, [canFetchContentsData, getContents]);

  const searchContentHandler = useCallback(async () => {
    if (!companyId) {
      toaster.error('Company data not found');
      return;
    }
    const response = await searchContent(
      {
        name: searchContentKey,
        company: companyId,
        folder: folderId || null,
      },
      setIsLoading
    );
    if (response) {
      setSearchContentData(response);
    } else {
      setSearchContentData(null);
    }
  }, [companyId, searchContentKey, folderId]);

  useEffect(() => {
    if (searchContentKey !== '') searchContentHandler();
    else setSearchContentData(null);
  }, [searchContentKey, searchContentHandler, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(layoutActions.toggleSideDrawer());
    };
  }, [dispatch]);

  // const data = searchContentData?.length > 0 ? searchContentData : contents;

  const data =
    Array.isArray(searchContentData) && searchContentData.length > 0
      ? searchContentData
      : contents;

  return (
    <Layout variant={LayoutVariants.layoutTwo}>
      <>
        <section className={styles.contentWrapper}>
          {/* {isLoading && <Loader />} */}
          {searchContentKey !== '' && !Array.isArray(searchContentData) && (
            <p className={styles.notFound}>Result not found</p>
          )}
          <ContentList data={data} isLoading={isLoading} />
          <AddFolderModal />
          <UploadContentModal />
          <UploadContentWebpageModal />
        </section>
        <SideDrawer />
      </>
    </Layout>
  );
}

export default Content;
