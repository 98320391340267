import { LayoutViews, UserRoles, UserSettingsView, TwoFAType } from 'enums';
import { iState } from 'types';

export const initialState: iState = {
  user: {
    role: UserRoles.SuperAdmin,
    allRoles: null,
    isLoggedIn: false,
    loggedInData: null,
    resetPasswordData: null,
    userSettings: {
      activeView: UserSettingsView.Profile,
    },
    removeTeammateData: null,
    twoFAVerificationData: {
      id: '',
      twoFA: TwoFAType.DISABLED,
    },
  },
  layout: {
    assignModalContentFolders: [],
    assignModalContentsFolderHierarchy: [],
    assignModalContentActiveFolder: null,
    windowWidth: 0,
    chosenView: LayoutViews.Screens,
    emptyStates: [],
    isShowAddContent: false,
    isShowAddWebContent: false,
    isShowContentPreview: false,
    isShowPlaylistPreview: false,
    isShowAddPlaylist: false,
    isShowAddFolderForPlaylists: false,
    isShowAddContentToPlaylist: false,
    isShowAddTeammate: false,
    isShowingClientInstance: false,
    isShowAddClient: false,
    isShowAddScreen: false,
    isShowAddFolder: false,
    isShowShareAccess: false,
    isShowDeleteModal: false,
    isShowSetUp2FA: false,
    isShowDisable2FA: false,
    initialSidebarState: false,
    invokeGetAllFolders: false,
    invokeGetFolderById: false,
    isShowAssignContentOrPlaylist: false,
  },
  homePage: {
    screensFolders: [],
    screensActiveFolder: null,
    superUserClients: [],
    superUserResellers: [],
    resellerClients: [],
    screens: [],
    companies: [],
    foldersAndSubFolders: [],
    activeFolder: null,
    folderHierarchy: [],
    screensFolderHierarchy: [],
    openedFolders: [],
    activeClientInstanceData: null,
    screenzId: '',
    screenData: null,
    companyData: null,
    canFetchScreensData: false,
    searchCompanyKey: '',
    searchScreenKey: '',
    contentToAssignScreen: '',
    playlistToAssignScreen: '',
    invokeGetScreenById: false,
    invokeUpdateScreen: false,
    invokeGetCompanies: false,
  },
  contentPage: {
    contentFolders: [],
    contentsFolderHierarchy: [],
    contentActiveFolder: null,
    files: [],
    contents: [],
    uploadingFiles: [],
    emptyState: '',
    showCancelAndSaveBtns: false,
    contentView: null,
    canFetchContentsData: false,
    searchContentKey: '',
    addWebContentData: null,
  },
  playlistsPage: {
    playlistFolders: [],
    playlistActiveFolder: null,
    playlistsFolderHierarchy: [],
    playlists: [],
    playlistData: null,
    contentToAddToPlaylist: [],
    canFetchPlaylistData: false,
    canUpdatePlaylist: false,
    searchPlaylistKey: '',
    contentView: null,
    playlistPreview: null,
    isPlaylistSaveAndCloseBtnDisabled: true,
  },
};
