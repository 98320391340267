import React from 'react';
import styles from './input.module.scss';

interface iProps {
  id?: string;
  className?: string;
  value: string;
  type?: 'text' | 'password';
  isError?: boolean;
  errorMessage?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onKeyUp?: (event: any) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function Input(props: iProps): JSX.Element {
  const {
    id,
    className,
    placeholder,
    value,
    isError = false,
    errorMessage = '',
    name,
    type = 'text',
    disabled,
    onChange,
    onFocus,
    onKeyUp,
  } = props;
  console.log(errorMessage);

  return (
    <input
      id={id}
      name={name}
      type={type}
      className={`${className} ${styles.input} ${isError && styles.error} ${
        disabled && styles.disabled
      }`}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={onChange}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      autoComplete="off"
    />
  );
}

Input.defaultProps = {
  id: '',
  name: '',
  type: 'text',
  isError: false,
  errorMessage: '',
  className: '',
  placeholder: '',
  disabled: false,
  onFocus: () => {
    //
  },
  onKeyUp: () => {},
  onChange: () => {},
};

export default Input;
