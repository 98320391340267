import { activeFolderIcon, folderIcon } from 'constants/index';
import React from 'react';
import styles from './folder.module.scss';

interface iProps {
  className?: string;
  onClick?: () => void;
  folderName: string;
  isActive?: boolean;
}

function Folder(props: iProps): JSX.Element {
  const { className, isActive = false, folderName, onClick } = props;
  return (
    <div className={`${className} ${styles.container}`} onClick={onClick}>
      <img
        className={styles.folderIcon}
        src={isActive ? activeFolderIcon : folderIcon}
        alt=""
      />
      {folderName}
    </div>
  );
}

Folder.defaultProps = {
  className: '',
  isActive: false,
  onClick: () => {},
};

export default Folder;
