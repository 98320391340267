import { gql } from 'apollo-boost';

export enum Companies {
  AddCompany = 'addCompany',
  UpdateCompany = 'updateCompany',
  GetCompanies = 'getCompanies',
  GetCompanyById = 'getCompanybyid',
  SearchCompany = 'searchCompanybyname',
  DeleteCompany = 'deleteCompany',
}

export const addCompanyMutation = gql`
  mutation ${Companies.AddCompany} (
    $email: String!
    $name: String!
    $address: String
    $city: String
    $postal_code: String
    $org_no: String
  ){
    ${Companies.AddCompany} (
      email: $email
      name: $name
      address: $address
      city: $city
      postalCode: $postal_code
      orgNr: $org_no
    ){
      message
      statusCode
      data {
        email
        address
        city
        name
        postal_code
        org_no
        phone_number
        country_code
        id
        created_at
      }
    }
  }
`;

export const updateCompanyMutation = gql`
  mutation ${Companies.UpdateCompany} (
    $id: String!
    $email: String!
    $name: String!
    $address: String
    $city: String
    $postal_code: String
    $org_no: String
  ){
    ${Companies.UpdateCompany} (
      id: $id
      email: $email
      name: $name
      address: $address
      city: $city
      postalCode: $postal_code
      orgNr: $org_no
    ){
      statusCode
      message
      data {
        id
        name
        email
        org_no
        address
        postal_code
        city
        email
      }
    }
  }
`;

export const getCompaniesQuery = gql`
  query ${Companies.GetCompanies}{
    ${Companies.GetCompanies} {
      statusCode
      message
      data {
        id
        email
        address
        city
        name
        postal_code
        org_no
        phone_number
        country_code
      }
    }
  } 
`;

export const getCompaniesByIdQuery = gql`
      query ${Companies.GetCompanyById}($id: String){
        ${Companies.GetCompanyById}(id: $id){
          statusCode
          message
          data{
            id
            name
            org_no
            address
            postal_code
            city
            email
            phone_number
          }
        }
      }`;

export const searchCompanyMutation = gql`
  mutation ${Companies.SearchCompany} (
    $name: String!
  ){
    ${Companies.SearchCompany} (
      name: $name
    ){
      message
      statusCode
      data {
        email
        address
        city
        name
        postal_code
        org_no
        phone_number
        country_code
        id
        created_at
      }
    }
  }
`;

export const deleteCompanyByIdMutation = gql`
mutation ${Companies.DeleteCompany}(
  $id: String!
){
  ${Companies.DeleteCompany}(
    id: $id
  ){
    statusCode
    message
    name
    data {
        email
        address
        city
        name
        postal_code
        org_no
        phone_number
        country_code
        id
        created_at
    }
  }
}
`;
