import React, { useMemo, useEffect } from 'react';
import { appStorage } from '../helpers';

const Store = React.createContext({});
Store.displayName = 'Store';

export const useStore = (): any => React.useContext(Store);

export function StoreProvider({
  children,
  initialState,
  reducer,
}): JSX.Element {
  const localData = appStorage.getItem('context');

  const [globalState, dispatch] = React.useReducer(
    reducer,
    localData || initialState
  );

  // window.addEventListener('beforeunload', () => {
  //   console.log('onbeforeunload');
  //   appStorage.setItem('context', globalState);
  // });

  // window.onbeforeunload = () => {
  //   appStorage.setItem('context', globalState);
  // };

  useEffect(() => {
    return () => {
      appStorage.setItem('context', globalState);
    };
  }, [globalState]);

  // useEffect(() => {
  //   appStorage.removeItem('context');
  // }, []);

  // window.onload = () => {
  //   appStorage.removeItem('context');
  // };

  const state = useMemo(() => [globalState, dispatch], [globalState]);

  return <Store.Provider value={state}>{children}</Store.Provider>;
}
