import { companyIcon, ScreenTabIcon, playlistsIcon } from 'constants/index';

export enum DeleteModalVariants {
  Screen = 'screen',
  Client = 'client',
  Content = 'content',
  Playlist = 'playlist',
  TeamMember = 'teamMember',
  PlaylistContent = 'playlistContent',
}

export enum DeletingTo {
  Screen = 'screen',
  Client = 'client',
  Content = 'content',
  Playlist = 'playlist',
  TeamMember = 'team member',
}

export const Variants = {
  [DeleteModalVariants.Screen]: {
    deletingTo: DeletingTo.Screen,
    heading: 'Delete ',
    description:
      'Are you sure you want to delete this screen? This is Permanant',
    icon: ScreenTabIcon,
  },
  [DeleteModalVariants.Client]: {
    deletingTo: DeletingTo.Client,
    heading: 'Delete ',
    description:
      'Are you sure you want to delete this client? This is Permanant',
    icon: companyIcon,
  },
  [DeleteModalVariants.Content]: {
    deletingTo: DeletingTo.Content,
    heading: 'Delete ',
    description:
      'Are you sure you want to delete this content? This is Permanant',
    icon: companyIcon,
  },
  [DeleteModalVariants.Playlist]: {
    deletingTo: DeletingTo.Playlist,
    heading: 'Delete ',
    description:
      'Are you sure you want to delete this content? This is Permanant',
    icon: playlistsIcon,
  },
  [DeleteModalVariants.TeamMember]: {
    deletingTo: DeletingTo.TeamMember,
    heading: 'Remove ',
    description:
      'Are you sure you want to remove from the team? This is Permanant',
    icon: playlistsIcon,
  },
};
