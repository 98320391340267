import { iState } from 'types';
import { contentPageReducer } from './contentPage.reducer';
import { homePageReducer } from './homePage.reducer';
import { layoutReducer } from './layout.reducer';
import { playlistsPageReducer } from './playlistsPage.reducer';
import { userReducer } from './user.reducer';

export const rootReducer = (state: iState, action): iState => ({
  user: userReducer(state.user, action),
  layout: layoutReducer(state.layout, action),
  homePage: homePageReducer(state.homePage, action),
  contentPage: contentPageReducer(state.contentPage, action),
  playlistsPage: playlistsPageReducer(state.playlistsPage, action),
});
