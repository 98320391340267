import {
  iPlaylistData,
  iFolderData,
  iParentFolders,
  iPlaylistContents,
  iContentData,
} from 'types';
import { PLAYLIST_PAGE } from '../constants';

interface iActions {
  type: string;
  payload?: any;
}

export const playlistPageActions = {
  addPlaylist: (playlistName: string): iActions => ({
    type: PLAYLIST_PAGE.addPlaylist,
    payload: playlistName,
  }),
  disablePlaylistSaveAndCloseBtn: (status: boolean): iActions => ({
    type: PLAYLIST_PAGE.toggleLlaylistSaveAndCloseBtn,
    payload: status,
  }),
  setPlaylist: (data: iPlaylistData[] | []): iActions => ({
    type: PLAYLIST_PAGE.setPlaylists,
    payload: data,
  }),
  addContentToPlaylist: (content: iPlaylistContents): iActions => ({
    type: PLAYLIST_PAGE.addContentToPlaylist,
    payload: content,
  }),
  clearAddContentToPlaylist: (): iActions => ({
    type: PLAYLIST_PAGE.clearAddContentToPlaylist,
  }),
  setPlaylistData: (playlist: iPlaylistData): iActions => ({
    type: PLAYLIST_PAGE.setPlaylistData,
    payload: playlist,
  }),
  fetchPlaylistData: (data: boolean): iActions => ({
    type: PLAYLIST_PAGE.fetchPlaylistsData,
    payload: data,
  }),
  invokeUpdatePlaylist: (data: boolean): iActions => ({
    type: PLAYLIST_PAGE.invokeUpdatePlaylist,
    payload: data,
  }),
  updatePlaylist: (data: any): iActions => ({
    type: PLAYLIST_PAGE.updatePlaylist,
    payload: data,
  }),
  setSearchPlaylistKey: (key: string): iActions => ({
    type: PLAYLIST_PAGE.setSearchPlaylistKey,
    payload: key,
  }),
  setPlaylistsFolders: (folders: iFolderData[] | []): iActions => ({
    type: PLAYLIST_PAGE.setPlaylistsFolders,
    payload: folders,
  }),
  setPlaylistsActiveFolder: (folder: iFolderData | null): iActions => ({
    type: PLAYLIST_PAGE.setPlaylistsActiveFolder,
    payload: folder,
  }),
  setPlaylistsFolderHierarchy: (data: iParentFolders[] | []): iActions => ({
    type: PLAYLIST_PAGE.setPlaylistsFolderHierarchy,
    payload: data,
  }),
  setContentView: (data: iContentData): iActions => ({
    type: PLAYLIST_PAGE.setContentView,
    payload: data,
  }),
  setPlaylistPreview: (data: iPlaylistContents[] | null): iActions => ({
    type: PLAYLIST_PAGE.setPlaylistPreview,
    payload: data,
  }),
  // setAssignModalContentFolders: (folder: iFolderData[] | []): iActions => ({
  //   type: PLAYLIST_PAGE.setAssignModalContentFolders,
  //   payload: folder,
  // }),
  // setAssignModalContentsFolderHierarchy: (
  //   data: iParentFolders[] | []
  // ): iActions => ({
  //   type: PLAYLIST_PAGE.setAssignModalContentsFolderHierarchy,
  //   payload: data,
  // }),
  // setAssignModalContentActiveFolders: (
  //   folder: iFolderData | null
  // ): iActions => ({
  //   type: PLAYLIST_PAGE.setAssignModalContentActiveFolders,
  //   payload: folder,
  // }),
};
