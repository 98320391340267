import { teamMembersTableColumns } from 'constants/index';
import { deleteUser, getUserByCompanyId } from 'apis';
import { iUserData, iState } from 'types';
import { useStore } from 'store';
import Dustbin from 'components/svg/dustbin';
import More from 'components/svg/more';
import { ModalViews, UserSettingsView } from 'enums';
import Popover from 'components/popover/popover';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTable } from 'react-table';
import { layoutActions, userActions } from 'store/actions';
import Avatar from './components/avatar/avatar';
import NotSetup from './components/not-setup/notSetup';
import styles from './teamMember.module.scss';

function TeamMemberList(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [state, dispatch]: [iState, any] = useStore();
  const { homePage } = state || {};
  const { companyData } = homePage || {};
  const [userInfo, setUserInfo] = useState<Array<iUserData>>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>();
  const { id: companyId } = companyData || {};

  const { removeTeammateData } = state?.user || {};
  console.log({ isLoading });
  const columns = useMemo(() => teamMembersTableColumns, []);
  const data = useMemo(() => userInfo, [userInfo]);

  const tableInstance = useTable({
    columns,
    data,
  });

  const changeView = useCallback(() => {
    dispatch(userActions.changeView(UserSettingsView.TeamMemberPermissions));
  }, [dispatch]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  useEffect(() => {
    (async () => {
      if (!companyId) return;
      const response = await getUserByCompanyId(
        { company: companyId },
        setIsLoading
      );
      if (response) {
        console.log({ response });
        const parsed = response
          .filter((i) => i.invitedStatus !== 'INVITED')
          .map((i) => ({
            ...i,
            user: {
              name: i.first_name,
              lastName: i.last_name,
              email: i.email,
              profilePic: i.profile_pic,
            },
          }));
        setUserInfo(parsed);
      }
    })();
  }, [companyId]);

  const openDeleteTeammateModal = useCallback(
    (user: iUserData) => {
      dispatch(userActions.setRemoveTeammate(user));
      dispatch(layoutActions.toggleModal(ModalViews.isShowDeleteModal));
    },
    [dispatch]
  );

  const removeTeammateHandler = useCallback(async () => {
    const { id } = removeTeammateData || {};
    if (!id) return;
    const response = await deleteUser(id, setIsDeleting);
    if (response) {
      dispatch(layoutActions.toggleModal());
    }
  }, [removeTeammateData, dispatch]);

  return (
    <table {...getTableProps()} className={styles.tableHead}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                const { id } = cell.column || {};
                const { role } = cell.row.original || {};
                const userAvatar = id === 'user';
                const notSetup = id === 'fA';
                const lastActive = id === 'lastActive';
                const isShowPermission = id === 'permission';
                const permissions =
                  (role.id === ('1' || '2') && 'Full Permission') ||
                  'Limited Permission';
                const isMore = id === 'more';
                return (
                  <td
                    {...cell.getCellProps()}
                    className={`${id === 'lastActive' && styles.lastActive}`}
                    onClick={changeView}
                  >
                    {notSetup && <NotSetup />}
                    {isShowPermission && permissions}
                    {userAvatar ? <Avatar data={cell.value} /> : cell.value}
                    {lastActive}
                    {isMore && (
                      <Popover
                        className={styles.morePopupContainer}
                        popoverClassName={styles.popover}
                        popoverComponent={
                          <div
                            className={styles.morePopup}
                            onClick={(event) => {
                              event.stopPropagation();
                              openDeleteTeammateModal(cell.row.original);
                            }}
                          >
                            {/* <img src={dustbinIcon} alt="" /> */}
                            <Dustbin className={styles.moreBtn} />
                            <p>Remove Teammate</p>
                          </div>
                        }
                      >
                        <div className={styles.moreBtn}>
                          <More className={styles.icon} />
                        </div>
                      </Popover>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <DeleteModal
        variant={DeleteModalVariants.TeamMember}
        isLoading={isDeleting}
        name={`${removeTeammateData?.first_name}${
          removeTeammateData?.last_name ? removeTeammateData?.last_name : ''
        }`}
        deleteHandler={removeTeammateHandler}
      />
    </table>
  );
}

export default TeamMemberList;
