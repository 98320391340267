import React, { useEffect, useState } from 'react';
import { getUserByCompanyId } from 'apis';
import { iUserData, iState } from 'types';
import { useStore } from 'store';
import NotSetup from '../not-setup/notSetup';
import styles from './teamMembersMobile.module.scss';

interface iProps {
  className: string;
}

function TeamMembersMobileView(props: iProps): JSX.Element {
  const { className } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [state]: [iState, any] = useStore();
  const { homePage } = state || {};
  const [userInfo, setUserInfo] = useState<Array<iUserData>>([]);
  const { companyData } = homePage || {};
  const { id: companyId } = companyData || {};
  console.log({ isLoading });

  useEffect(() => {
    (async () => {
      if (!companyId) return;
      const response = await getUserByCompanyId(
        { company: companyId },
        setIsLoading
      );
      if (response) {
        console.log({ response });
        setUserInfo(response);
      }
    })();
  }, [companyId]);

  return (
    <div className={`${className} ${styles.playlists}`}>
      {userInfo
        .filter((i) => i.invitedStatus !== 'INVITED')
        .map((item) => (
          <section key={item.id}>
            <div className={styles.avatarContainer}>
              <div className={styles.avatarCircle}>
                <div className={styles.avatarText}>
                  {item.profile_pic ? (
                    <img src={item.profile_pic} alt="" />
                  ) : (
                    <span>
                      {(item.first_name && item.first_name[0]) || ''}
                      {(item.last_name && item.last_name[0]) || ''}
                    </span>
                  )}
                </div>
              </div>
              <section>
                <span className={styles.nameCotainer}>
                  <p className={styles.nameDetails}>{item.first_name}</p>
                  <p className={styles.nameDetails}>{item.last_name}</p>
                </span>
                <p className={styles.emailDetails}>{item.email}</p>
              </section>
            </div>
            <div className={styles.dividerRow} />
            <div className={styles.permissionContainer}>
              <div className={styles.fullAccess}>
                <div className={styles.permissionDetails}>
                  <p className={styles.permissionTitle}>Permission</p>
                  <p className={styles.permissionAccess}>
                    {(item?.role?.id === ('1' || '2') && 'Full Permission') ||
                      'Limited Permission'}
                  </p>
                </div>
                <div className={styles.permissionDetails}>
                  <p className={styles.permissionTitle}>Last Active</p>
                  <p className={styles.permissionAccess}>lastActive</p>
                </div>
              </div>
              <div className={styles.notSetupContainer}>
                <p className={styles.fA}>2fa</p>
                <span>
                  <NotSetup />
                </span>
              </div>
            </div>
            <div className={styles.teamMemberDivider} />
          </section>
        ))}
    </div>
  );
}

export default TeamMembersMobileView;
