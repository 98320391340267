import { ROUTES } from 'constants/index';
import { LayoutViews, UserRoles } from 'enums';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'store';
import { iState } from 'types';
import ViewSwitchButton from '../view-switch-button/viewSwitchButton';
import styles from './subheaderTwo.module.scss';

interface iProps {
  className?: string;
}

function SubheaderTwo(props: iProps): JSX.Element {
  const { className } = props;
  const [state]: [iState] = useStore();
  const { user } = state || {};
  const { role } = user || {};
  const { isShowingClientInstance, chosenView } = state.layout || {};
  const { pathname } = useLocation();

  // const isClient = role === UserRoles.Client;
  const isSuperUserAdmin = role === UserRoles.SuperAdmin;
  const isResellerAdmin = role === UserRoles.Reseller;

  return (
    <div
      className={`${className} ${styles.subheaderTwo} ${
        isResellerAdmin && styles.username
      }`}
    >
      {isResellerAdmin && <p className={styles.username}>Client name</p>}
      <ViewSwitchButton
        variant={
          (pathname === ROUTES.playlist && LayoutViews.Playlist) ||
          (pathname === ROUTES.playlists && LayoutViews.Playlists) ||
          (pathname === ROUTES.content && LayoutViews.Content) ||
          (!isShowingClientInstance &&
            pathname === ROUTES.home &&
            chosenView === LayoutViews.SuperAdminClients &&
            LayoutViews.SuperAdminClients) ||
          LayoutViews.Screens
        }
      />
      {(!isSuperUserAdmin || (isSuperUserAdmin && isShowingClientInstance)) && (
        <ViewSwitchButton
          variant={
            (pathname === ROUTES.playlist && LayoutViews.PlaylistDetails) ||
            (pathname === ROUTES.playlists && LayoutViews.PlaylistsDetails) ||
            (pathname === ROUTES.content && LayoutViews.ContentDetails) ||
            // (isSuperUserAdmin && LayoutViews.SuperAdminResellers) ||
            LayoutViews.ScreensDetails
          }
        />
      )}
    </div>
  );
}

SubheaderTwo.defaultProps = {
  className: '',
};

export default SubheaderTwo;
