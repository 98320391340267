import { Mutation, Query } from 'config';
import { toaster } from 'helpers';
import { captureException } from 'helpers/sentry';
import {
  iAddCompanyArgs,
  iAddCompanyByIdArgs,
  iAddCompanyResponse,
  iCompanyData,
  iDeleteCompany,
  iDeleteCompanyByIdArgs,
  iDeleteCompanyResponse,
  iGetCompaniesResponse,
  iGetCompanyByIdResponse,
  iGraphqlResponse,
  iSearchCompaniesResponse,
  iSearchCompanyArgs,
  iUpdateCompanyArgs,
  iUpdateCompanyResponse,
} from 'types';
import {
  addCompanyMutation,
  deleteCompanyByIdMutation,
  getCompaniesByIdQuery,
  getCompaniesQuery,
  searchCompanyMutation,
  updateCompanyMutation,
} from '../graphql';

export const addCompany = async (
  args: iAddCompanyArgs,
  isLoading
): Promise<iCompanyData | boolean | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iAddCompanyResponse>>(
      await Mutation(addCompanyMutation, args)
    );
    isLoading(false);
    if (response.data.addCompany.data) return response.data.addCompany.data;
    return true;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    if (error.message === `${args.email} already registerd as a client`)
      toaster.error(`${args.email} already registerd as a client`);
    return null;
  }
};

export const updateCompany = async (
  args: iUpdateCompanyArgs,
  isLoading
): Promise<iCompanyData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iUpdateCompanyResponse>>(
      await Mutation(updateCompanyMutation, args)
    );
    isLoading(false);
    return response.data.updateCompany.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const searchCompany = async (
  args: iSearchCompanyArgs,
  isLoading
): Promise<Array<iCompanyData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iSearchCompaniesResponse>>(
      await Mutation(searchCompanyMutation, args)
    );
    isLoading(false);
    return response.data.searchCompanybyname.data;
  } catch (error) {
    isLoading(false);
    return null;
  }
};

export const getCompanies = async (
  isLoading
): Promise<Array<iCompanyData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetCompaniesResponse>>(
      await Query(getCompaniesQuery)
    );
    isLoading(false);
    return response.data.getCompanies.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const getCompaniesById = async (
  args: iAddCompanyByIdArgs,
  isLoading
): Promise<iCompanyData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetCompanyByIdResponse>>(
      await Query(getCompaniesByIdQuery, args)
    );
    isLoading(false);
    return response.data.getCompanybyid.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const deleteCompanyById = async (
  args: iDeleteCompanyByIdArgs
): Promise<iDeleteCompany | null> => {
  try {
    const response = <iGraphqlResponse<iDeleteCompanyResponse>>(
      await Mutation(deleteCompanyByIdMutation, args)
    );
    return response.data.deleteCompany;
  } catch (error: any) {
    captureException(error);
    return null;
  }
};
