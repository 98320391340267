import Input from 'components/input/input';
import { cancelIcon, searchIcon } from 'constants/index';
import { UserRoles } from 'enums';
import { currentPage } from 'helpers';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from 'store';
import {
  contentPageActions,
  homePageActions,
  playlistPageActions,
} from 'store/actions';
import { iState } from 'types';
import styles from './search.module.scss';

interface iProps {
  className?: string;
  isOpenState?: boolean;
  isShowClear?: boolean;
  placeholder?: string;
  onChange?: (type: string) => void;
  setKeyword?: any;
  searchKeyword?: string;
}

let timer: any; // Timer identifier
function Search(props: iProps): JSX.Element {
  const {
    className,
    placeholder,
    isOpenState,
    isShowClear,
    onChange,
    setKeyword,
    searchKeyword,
  } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [searchKey, setSearchKey] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(isOpenState || false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const containerRef: any = useRef(null);
  const { role } = state.user || {};
  const { isShowingClientInstance } = state.layout || {};
  const waitTime = 500;

  const { isScreensPage, isContentsPage, isPlaylistPage } = currentPage();
  const { SuperAdmin, Client } = UserRoles;

  const onKeyUp = (e: any): void => {
    const text = e.currentTarget.value;
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (isContentsPage)
        dispatch(contentPageActions.setSearchContentKey(text));

      if (isPlaylistPage)
        dispatch(playlistPageActions.setSearchPlaylistKey(text));

      if (isScreensPage) {
        if (role === SuperAdmin && !isShowingClientInstance)
          dispatch(homePageActions.setSearchCompanyKey(text));

        if (role === Client || (role === SuperAdmin && isShowingClientInstance))
          dispatch(homePageActions.setSearchScreenKey(text));
      }
      if (onChange) onChange(text);
    }, waitTime);
  };

  useEffect(() => {
    if (!searchKeyword) setSearchKey('');
  }, [searchKeyword]);

  const onSearch = useCallback((event): void => {
    setSearchKey(event.target.value);
    setKeyword(event.target.value);
  }, []);

  const onClear = useCallback((): void => {
    setSearchKey('');
    // setKeyword('');
    dispatch(contentPageActions.setSearchContentKey(''));
    dispatch(homePageActions.setSearchCompanyKey(''));
    dispatch(homePageActions.setSearchScreenKey(''));
    dispatch(playlistPageActions.setSearchPlaylistKey(''));
  }, [dispatch]);

  const clickOutSide = useCallback(
    (event: MouseEvent) => {
      if (isOpenState) {
        if (isFocused) setIsFocused(false);
      } else if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        if (isOpen) setIsOpen(false);
        if (isFocused) setIsFocused(false);
        onClear();
      }
    },
    [isOpenState, isOpen, isFocused, onClear]
  );

  useEffect(() => {
    document.addEventListener('click', clickOutSide);
    return () => document.removeEventListener('click', clickOutSide);
  }, [clickOutSide]);

  const onClick = useCallback(() => {
    const inputElement = document.getElementById('searchInput');
    if (inputElement) inputElement.focus();
    setIsOpen(true);
  }, []);

  return (
    <div
      className={`${className} ${styles.search} ${isOpen && styles.open} ${
        isFocused && styles.focused
      }`}
      ref={containerRef}
    >
      <img
        className={`${styles.searchIcon} ${isOpen && styles.open}`}
        src={searchIcon}
        alt=""
        onClick={onClick}
      />
      <Input
        id="searchInput"
        className={`${styles.input} ${isOpen && styles.open}`}
        value={searchKey}
        placeholder={placeholder || 'Search'}
        onChange={onSearch}
        onFocus={() => setIsFocused(true)}
        onKeyUp={onKeyUp}
      />
      {isShowClear && (
        <img
          className={styles.cancelIcon}
          src={cancelIcon}
          alt=""
          onClick={onClear}
        />
      )}
    </div>
  );
}

Search.defaultProps = {
  className: '',
  isOpenState: false,
  isShowClear: false,
  placeholder: '',
  onChange: () => {},
  setKeyword: '',
  searchKeyword: '',
};

export default Search;
