import Popover from 'components/popover/popover';
import {
  expandArrowMore,
  logoutIcon,
  ROUTES,
  settingsIcon,
} from 'constants/index';
import { uuid } from 'helpers';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import Avatar, { AvatarVariants } from 'components/avatar/avatar';
import { commonActions } from 'store/actions';
import { iState } from 'types';
import styles from './profile.module.scss';

interface iProps {
  className?: string;
}

function Profile(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const { firstName, lastName, profile_pic } = state.user.loggedInData || {};
  const navigate = useNavigate();
  const logoutHandler = useCallback(() => {
    window.Intercom('shutdown');
    localStorage.removeItem('isLoggedIn');
    window.Intercom('boot', {
      app_id: 'd7jw43br',
    });
    dispatch(commonActions.reset());
    navigate(ROUTES.login);
  }, [dispatch, navigate]);

  const profileBtnOptions = [
    {
      id: uuid(),
      option: 'Settings',
      icon: settingsIcon,
    },
    {
      id: uuid(),
      option: 'Log Out',
      icon: logoutIcon,
    },
  ];
  return (
    <Popover
      setIsShowing={setIsShowing}
      popoverClassName={styles.logoutPopover}
      popoverComponent={
        // <><div className={styles.logout} onClick={logoutHandler}>
        //   <img src={logoutIcon} alt="" />
        //   <span>Log out</span>
        // </div>
        <div>
          {profileBtnOptions.map((i) => (
            <div
              className={styles.logout}
              onClick={() => {
                if (i.option === 'Log Out') logoutHandler();
                if (i.option === 'Settings') navigate(ROUTES.userSettings);
              }}
            >
              <img src={i.icon} alt="" />
              <span>{i.option}</span>
            </div>
          ))}
        </div>
        // </>
      }
    >
      <div
        className={`${className} ${styles.profile} ${isShowing && styles.show}`}
      >
        <Avatar
          className={styles.profilePic}
          variant={AvatarVariants.Medium}
          firstName={firstName}
          lastName={lastName}
          profilePic={profile_pic}
        />
        <span className={styles.username}>{`${firstName} ${lastName}`}</span>
        <img className={styles.expandArrowDown} src={expandArrowMore} alt="" />
      </div>
    </Popover>
  );
}

Profile.defaultProps = {
  className: '',
};

export default Profile;
