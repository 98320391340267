import { folderIcon } from 'constants/index';
import ShareAccess from 'components/share-access/shareAccess';
import React from 'react';
import PrimaryDetailsSkeleton from 'components/skeletons/primary-details/primaryDetails';
import { iState } from 'types';
import { useStore } from 'store';
import styles from './playlistDetails.module.scss';

interface iProps {
  className?: string;
  isLoading: boolean;
}

function PlaylistDetails(props: iProps): JSX.Element {
  const { className, isLoading } = props;

  const [state]: [iState] = useStore();
  const { playlistActiveFolder } = state.playlistsPage || {};

  return (
    <div className={`${className} ${styles.playlistDetails}`}>
      {isLoading ? (
        <PrimaryDetailsSkeleton />
      ) : (
        <>
          <div className={styles.headingSection}>
            <button type="button">Details</button>
          </div>
          <img className={styles.folderIcon} src={folderIcon} alt="" />
          <p className={styles.paragraphOne}>Your Playlists</p>
          <ShareAccess
            className={styles.sharePlaylist}
            shareType="playlist"
            heading={
              playlistActiveFolder
                ? 'Has access to the folder'
                : 'Has access to all playlists'
            }
          />
        </>
      )}
    </div>
  );
}

PlaylistDetails.defaultProps = {
  className: '',
};

export default PlaylistDetails;
