import { CONTENT_TYPE, videoPlayerIcon, WebIcon } from 'constants/index';
import React from 'react';
import { iContentData } from 'types';
import styles from './contentPreview.module.scss';

interface iProps {
  className?: string;
  data: iContentData;
}

function ContentPreview(props: iProps): JSX.Element {
  const { className, data } = props;

  return (
    <div className={`${styles.previewWrapper} ${className}`}>
      {data.contentType === CONTENT_TYPE.Video && (
        <video src={data.url} preload="none">
          <source src={data.url} type="video/mp4" />
          <track src="captions_en.vtt" kind="captions" />
        </video>
      )}
      {data.contentType === CONTENT_TYPE.Image && <img src={data.url} alt="" />}
      {data.contentType === CONTENT_TYPE.Website && (
        <img src={WebIcon} alt="" />
      )}
      {data.contentType === CONTENT_TYPE.Video && (
        <img className={styles.videoPlayerIcon} src={videoPlayerIcon} alt="" />
      )}
    </div>
  );
}

ContentPreview.defaultProps = {
  className: '',
};

export default ContentPreview;
