import { deleteScreenById, getScreenById } from 'apis';
import AssignContentModal from 'components/assign-content-modal/assignContentModal';
// import Button, { ButtonVariants } from 'components/button/button';
import DeleteModal, {
  DeleteModalVariants,
} from 'components/delete-modal/deleteModal';
import Layout, { LayoutVariants } from 'components/Layouts/layout';
import Remote from 'components/remote/remote';
import UploadContentModal from 'components/upload-content-modal/uploadContentModal';
import { ROUTES } from 'constants/index';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { homePageActions, layoutActions } from 'store/actions';
import { iScreenData, iState } from 'types';
import Details from './components/details/details';
import Header from './components/header/header';
import PingTime from './components/ping-time/pingTime';
import Screenshot from './components/screenshot/screenshot';
import Subheader from './components/subheader/subheader';
import styles from './screenDetailsView.module.scss';

function ScreenDetails(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const { homePage } = state || {};
  const { screenzId, screenData, invokeGetScreenById } = homePage || {};
  const [view, setView] = useState<'Screen' | 'Remote'>('Screen');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteDelayMessage, setDeleteDelayMessage] = useState<string>('');
  const [screenDetails, setScreenDetails] = useState<iScreenData>();

  const navigate = useNavigate();
  useEffect(() => {
    if (!screenData?.id) navigate(ROUTES.home);
  }, [screenData?.id, navigate]);

  const getScreen = useCallback(async () => {
    const response = await getScreenById({ id: screenzId }, setIsLoading);
    if (!response) return;
    dispatch(homePageActions.setScreenData(response));
    dispatch(homePageActions.invokeGetScreenById(false));
    setScreenDetails(response);
  }, [screenzId, dispatch]);

  useEffect(() => {
    if (invokeGetScreenById) getScreen();
    getScreen();
  }, [getScreen, invokeGetScreenById]);

  const deleteItem = async (): Promise<void> => {
    if (screenDetails) {
      // setIsDeleting(true);
      const response = await deleteScreenById({ id: screenDetails?.id });
      if (response?.statusCode === 200) {
        setDeleteDelayMessage(
          'Your screen is being deleted. This will take a minute or two. Please wait!'
        );
        /**
         * Dont change this delay code,  Mohan asked to implement the delay, for some scenario
         * */
        setIsDeleting(false);
        dispatch(layoutActions.toggleModal());
        toaster.success(
          'Your screen is being deleted. This will take a minute or two. Please wait!',
          40000
        );
        setTimeout(() => {
          setDeleteDelayMessage('');
          navigate(ROUTES.home);
          toaster.success('Screen deleted successfully');
        }, 40000);
      } else {
        setIsDeleting(false);
      }
    }
  };

  const screenId = screenData?.id;
  return (
    <Layout variant={LayoutVariants.layoutOne}>
      <div className={styles.screenDetails}>
        <AssignContentModal />
        <UploadContentModal />
        <Header />
        <div className={styles.mainContent}>
          <div className={styles.colOne}>
            <Subheader isLoading={isLoading} view={view} />
            <div className={styles.main}>
              <Screenshot
                src={screenDetails?.screenShotUrl}
                screenShotTime={screenData?.screenShotTiming}
              />
              {screenId && (
                <Remote className={styles.remote} screenId={screenId} />
              )}
            </div>
          </div>
          <Details className={styles.details} />
          <DeleteModal
            variant={DeleteModalVariants.Screen}
            isLoading={isDeleting}
            name={screenDetails?.name || ''}
            deleteHandler={deleteItem}
            message={deleteDelayMessage}
          />
        </div>
        <div className={styles.responsiveView}>
          <Subheader view={view} onClick={setView} isLoading={isLoading} />
          {view === 'Screen' && (
            <>
              <Screenshot
                className={styles.screenshot}
                screenShotTime={screenData?.screenShotTiming}
              />
              <Details variant="mobile" />
              <PingTime isLoading={isLoading} className={styles.pingTime} />
              {/* <Header /> */}
            </>
          )}
          {view === 'Remote' && screenId && (
            <Remote className={styles.remote} screenId={screenId} />
          )}
          {/* <div className={styles.btnsContainer}>
                <Button
                  className={`${styles.headerBtns}`}
                  variant={ButtonVariants.SmallTransp}
                  btnName="Cancel"
                  onClick={() => {
                    navigate(ROUTES.home);
                  }}
                />
                <Button
                  className={`${styles.headerBtns}`}
                  variant={ButtonVariants.SmallStd}
                  btnName="Save and close"
                />
              </div> */}
          {/* <DeleteModal
            header={screenDetails?.name || ''}
            modalType={LayoutViews.Screen}
            deleteItem={deleteItem}
            message={deleteDelayMessage}
          /> */}
        </div>
      </div>
    </Layout>
  );
}

ScreenDetails.defaultProps = {};
export default ScreenDetails;
