import { addContent } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import FileUploadProgress, {
  FileUploadProgressVariants,
} from 'components/file-upload-progress/fileUploadProgress';
import Modal from 'components/modal/modal';
import {
  cloudUpload2Icon,
  CONTENT_TYPE,
  uploadContentIcon,
} from 'constants/index';
import { s3Operations, toaster, uuid } from 'helpers';
// import { LayoutViews, ModalViews } from 'enums';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import {
  contentPageActions,
  // homePageActions,
  layoutActions,
} from 'store/actions';
import { iState, iUploadingFile } from 'types';
import styles from './uploadContentModal.module.scss';

// import upload from 'components/svg/upload';

function UploadContent(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { layout, contentPage, homePage, user } = state || {};
  const { isShowAddContent } = layout || {};
  const { uploadingFiles, contentActiveFolder } = contentPage || {};
  const { companyData } = homePage || {};

  // const addClient = useCallback(() => {
  //   if (layout.chosenView === LayoutViews.Screens)
  //     dispatch(homePageActions.addScreen());
  //   dispatch(layoutActions.toggleModal(ModalViews.isShowAddScreen));
  // }, [dispatch, layout.chosenView]);

  useEffect(() => {
    dispatch(contentPageActions.clearUploadingFiles());
  }, []);

  // const videoResolution = (file: File): any => {
  //   return new Promise((resolve) => {
  //     if (file.type.includes('image')) {
  //     }
  //     if (file.type.includes('video')) {
  //       const url = URL.createObjectURL(file);
  //       const video = document.createElement('video');
  //       video.src = url;
  //       video.addEventListener('loadedmetadata', function () {
  //         console.log('width:', video.videoWidth);
  //         console.log('height:', video.videoHeight);
  //         resolve(`${video.videoWidth}*${video.videoHeight}`);
  //       });
  //     }
  //   });
  // };

  const getDimention = (file): Promise<any> => {
    return new Promise((resolve) => {
      if (file.type.includes('image')) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        img.onload = () => {
          resolve(`${img.width}*${img.height}`);
          URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
      }
      if (file.type.includes('video')) {
        const url = URL.createObjectURL(file);
        const video = document.createElement('video');
        video.src = url;
        video.addEventListener('loadedmetadata', () => {
          resolve(`${video.videoWidth}*${video.videoHeight}`);
        });
      }
    });
  };

  const onFileChoose = useCallback(
    async (event) => {
      const { files = [] } = event.target;
      const filesWithDimensions = await Promise.all(
        [...files].map(async (i: File) => ({
          file: i,
          dimension: await getDimention(i),
        }))
      );

      dispatch(
        contentPageActions.uploadFiles(
          filesWithDimensions.map((i) => {
            return {
              id: uuid(),
              dimension: i.dimension,
              progress: 0,
              file: i.file,
              isUploaded: false,
              url: '',
            };
          })
        )
      );
    },
    [dispatch]
  );

  const addContentHandler = useCallback(
    async (
      contentsToAdd: iUploadingFile,
      index: number
    ): Promise<null | undefined> => {
      const { id: companyId = '' } = companyData || {};
      const contentType =
        (contentsToAdd.file.type.includes('image') && CONTENT_TYPE.Image) ||
        (contentsToAdd.file.type.includes('video') && CONTENT_TYPE.Video) ||
        '';
      const response = await addContent(
        {
          name: contentsToAdd.file.name,
          url: contentsToAdd.url,
          contentType,
          size: `${contentsToAdd.file.size}`,
          dimension: contentsToAdd.dimension,
          folder: contentActiveFolder?.id || '',
          thumbnailPath: '',
          company: companyId,
          user: user.loggedInData ? user.loggedInData?.id : '',
        },
        () => {}
      );

      if (response && index === uploadingFiles.length - 1) {
        dispatch(layoutActions.toggleModal());
        dispatch(contentPageActions.clearUploadingFiles());
        setIsLoading(false);
        toaster.success('Content added successfully');
        dispatch(contentPageActions.fetchContentsData(true));
      }

      return null;
    },
    [
      companyData,
      dispatch,
      uploadingFiles?.length,
      user.loggedInData,
      contentActiveFolder?.id,
    ]
  );

  const uploadFileHandler = useCallback(async () => {
    const { id: companyId = '' } = companyData || {};
    if (!companyId) {
      toaster.error('Company details not found');
      return;
    }

    setIsLoading(true);
    let count = 0;
    // eslint-disable-next-line
    for (let i of uploadingFiles) {
      if (!i.isUploaded) {
        const response: any = await s3Operations.upload({
          params: {
            Key: i.file.name,
            Body: i.file,
          },
          companyId: companyData?.id,
          uploadType: 'content',
          progress: (progress) => {
            dispatch(
              contentPageActions.updateUploadStatus({
                ...i,
                progress,
              })
            );
          },
        });

        if (response) {
          dispatch(
            contentPageActions.updateUploadStatus({
              ...i,
              progress: 100,
              url: response.Location,
              isUploaded: true,
            })
          );
          await addContentHandler({ ...i, url: response.Location }, count);
          count += 1;
        }
      }
    }
  }, [uploadingFiles, dispatch, companyData, addContentHandler]);

  const removeFileFromUpload = useCallback(
    (index) => {
      dispatch(contentPageActions.removeFile(index));
    },
    [dispatch]
  );

  return (
    <Modal
      isOpen={isShowAddContent}
      heading="Upload content"
      btnName="Upload"
      isLoading={isLoading}
      headingIcon={cloudUpload2Icon}
      isBtnDisabled={uploadingFiles.length === 0}
      onClose={() => dispatch(layoutActions.toggleModal())}
      submit={uploadFileHandler}
    >
      <div className={styles.uploadContent}>
        <div
          className={`${styles.fileUploadArea} ${
            uploadingFiles?.length === 0 && styles.initialState
          }`}
        >
          <input
            className={uploadingFiles?.length === 0 && styles.initialState}
            type="file"
            accept="image/png, image/jpg, image/jpeg, video/mp4, video/WebM"
            multiple
            onChange={onFileChoose}
          />
          <img
            className={styles.uploadContentIcon}
            src={uploadContentIcon}
            alt=""
          />

          <p className={styles.description}>
            Drag your photos or video here to start uploading,
          </p>
          <Button
            className={styles.browseButton}
            variant={ButtonVariants.MediumStd}
            btnName="Browse files"
          />
        </div>
        {uploadingFiles?.length > 0 && (
          <p className={styles.uploadingMessage}>
            <img
              className={styles.cloudUpload2Icon}
              src={cloudUpload2Icon}
              alt=""
            />
            <span>The following files are uploading</span>
          </p>
        )}
        <ul
          className={`${styles.filesUploading} ${
            uploadingFiles?.length > 0 && styles.postInitialState
          }`}
        >
          {uploadingFiles.map((i, index): JSX.Element => {
            // if (!i.isUploaded) return <div />;
            return (
              <FileUploadProgress
                variant={FileUploadProgressVariants.InModal}
                cancel={() => removeFileFromUpload(index)}
                data={i}
              />
            );
          })}
        </ul>
      </div>
    </Modal>
  );
}

export default UploadContent;
