import Button, { ButtonVariants } from 'components/button/button';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import { ModalViews, TwoFAType } from 'enums';
import DisableTwoFaModal from './disable-two-fa-modal/disableTwoFaModal';
import TwoFactorAuthModal from './two-fator-auth-modal/twoFactorAuthModal';
import SectionHeading from '../section-heading/sectionHeading';
import styles from './twoFactorAuthentication.module.scss';

interface iProps {
  className?: string;
}

function TwoFactorAuthentication(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { twoFAVerificationData } = state.user || {};
  const { twoFA } = twoFAVerificationData || {};

  const twoFAToggler = useCallback(() => {
    if (twoFA !== TwoFAType.DISABLED) {
      dispatch(layoutActions.toggleModal(ModalViews.isShowDisable2FA));
    } else {
      dispatch(layoutActions.toggleModal(ModalViews.isShowSetUp2FA));
    }
  }, [dispatch, twoFA]);

  return (
    <div className={`${styles.passwordWrapper} ${className}`}>
      <SectionHeading
        className={styles.sectionHeading}
        heading="2 Factor Authentication"
      />
      <p className={styles.heading}>Enhanced Security</p>
      <p className={styles.description}>
        Add a second level with 2 factor authentication to your account, the
        developers must come up with a solution here.
      </p>
      <Button
        className={styles.turnOnBtn}
        btnName={twoFA !== TwoFAType.DISABLED ? 'Turn off 2FA' : 'Turn on 2FA'}
        variant={ButtonVariants.SmallStd}
        onClick={twoFAToggler}
      />
      <TwoFactorAuthModal />
      <DisableTwoFaModal />
    </div>
  );
}

TwoFactorAuthentication.defaultProps = {
  className: '',
};

export default TwoFactorAuthentication;
