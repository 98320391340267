import { ROUTES } from 'constants/index';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStore } from 'store';
import { iState } from 'types';

function ProtectedRoute({
  component: Component,
}: {
  component: any;
}): JSX.Element {
  const [state]: [iState] = useStore();
  const { user } = state || {};
  const { isLoggedIn = false } = user || {};
  return isLoggedIn ? Component : <Navigate to={ROUTES.login} />;
  // return Component;
}

export default ProtectedRoute;
