import { getCompaniesById, updateCompany } from 'apis';
import Button, { ButtonVariants } from 'components/button/button';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { iState, iUpdateCompanyArgs } from 'types';
import SectionHeading from '../section-heading/sectionHeading';
import styles from './companyInfo.module.scss';

interface iProps {
  className?: string;
}

const companyInitialValues = {
  id: '',
  name: '',
  org_no: '',
  address: '',
  postal_code: '',
  email: ``,
  city: '',
  phone_number: '',
  country_code: '',
};

function CompanyInformation(props: iProps): JSX.Element {
  const { className } = props;
  const [companyInfo, setCompanyInfo] =
    useState<iUpdateCompanyArgs>(companyInitialValues);
  const [state]: [iState] = useStore();
  const { companyData } = state.homePage || {};
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  console.log({ isLoading });

  const handleChange = useCallback(
    (event): void => {
      const initialValue = { ...companyInfo };
      initialValue[event.target.name] = event.target.value;
      setCompanyInfo(initialValue);
      setDisabled(false);
    },
    [companyInfo]
  );

  const handleUpdateCompanyInfo = useCallback(() => {
    if (!companyData) return;
    (async () => {
      const response = await updateCompany(
        {
          id: companyData.id,
          email: companyInfo.email || companyData.email,
          name: companyInfo.name || companyData.name,
          address: companyInfo.address || companyData.address,
          city: companyInfo.city || companyData.city,
          postal_code: companyInfo.postal_code || companyData.postal_code,
          org_no: companyInfo.org_no || companyData.org_no,
        },
        setIsLoading
      );
      if (response) {
        console.log({ response });
        setCompanyInfo(response);
        // toaster.success('Updated profile successfully');
        toaster.success('Client updated successfully');
      }
    })();
  }, [companyData, companyInfo]);

  useEffect(() => {
    if (!companyData) return;
    (async () => {
      const response = await getCompaniesById(
        { id: companyData.id },
        setIsLoading
      );
      if (response !== null) {
        console.log({ response });
        setCompanyInfo(response);
      }
    })();
  }, [companyData]);

  return (
    <div className={`${styles.companyInfoWrapper} ${className}`}>
      <SectionHeading
        className={styles.sectionHeading}
        heading="Company information"
      />
      <LabelAndInput
        className={styles.labelAndInput}
        label="Company name"
        name="name"
        value={companyInfo.name}
        onChange={handleChange}
      />
      <LabelAndInput
        className={styles.labelAndInput}
        label="Org. nr."
        name="org_no"
        value={companyInfo.org_no}
        onChange={handleChange}
      />
      <LabelAndInput
        className={styles.labelAndInput}
        label="Address"
        name="address"
        value={companyInfo.address}
        onChange={handleChange}
      />
      <div className={styles.inputContainer}>
        <LabelAndInput
          className={`${styles.labelAndInput} ${styles.postCodeInput}`}
          label="Postcode"
          name="postal_code"
          value={companyInfo.postal_code}
          onChange={handleChange}
        />
        <LabelAndInput
          className={`${styles.labelAndInput} ${styles.cityInput}`}
          label="City"
          name="city"
          value={companyInfo.city}
          onChange={handleChange}
        />
      </div>
      <div className={styles.btnsContainer}>
        <Button
          className={styles.saveChangeBtn}
          btnName="Save changes"
          isDisabled={disabled}
          variant={ButtonVariants.SmallStd}
          onClick={handleUpdateCompanyInfo}
        />
      </div>
    </div>
  );
}

CompanyInformation.defaultProps = {
  className: '',
};

export default CompanyInformation;
