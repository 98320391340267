const onBoarding = {
  login: '/login',
  joinYourTeam: '/join-your-team',
  forgotPassword: '/forgot-password',
  verifyEmail: '/verify-email',
  passwordResetInstructions: '/password-reset-instructions',
  setNewPassword: '/set-new-password',
  resetPassword: '/reset-password',
  home: '/',
  superUser: '/super-user',
  screenDetails: '/screen-details',
  userSettings: '/user-settings',
  content: '/content',
  playlists: '/playlists',
  playlist: '/playlist',
  emailTeamInvite: '/emailTeamInvite',
  emptyStates: '/empty-states',
  chooseTeam: '/choose-team',
  verify2fa: '/verify-2fa',
  addWebpage: '/add-web-content',
  roles: '/roles',
};

export const ROUTES = {
  ...onBoarding,
};

export const PUBLIC_ROUTES = [
  ROUTES.login,
  ROUTES.joinYourTeam,
  ROUTES.forgotPassword,
  ROUTES.resetPassword,
  ROUTES.chooseTeam,
];

export const PROTECTED_ROUTES = [];
