import { Mutation, Query } from 'config';
import {
  iGraphqlResponse,
  iAddPlaylistArgs,
  iPlaylistData,
  iAddPlaylistResponse,
  iGetPlayListByCompanyResponse,
  iGetPlaylistsArgs,
  iGetPlaylistByIdArgs,
  iGetPlaylistByIdResponse,
  iUpdatePlaylistArgs,
  iUpdatePlaylistResponse,
  iSearchPlaylistArgs,
  iSearchPlaylistResponse,
  iDeletePlaylistArgs,
  iDeletePlaylistResponse,
} from 'types';
import { captureException } from 'helpers/sentry';
import { ScreenType } from 'enums';
import {
  addPlaylistMutation,
  getPlayListByCompanyQuery,
  getPlaylistByIdQuery,
  updatePlaylistMutation,
  searchPlaylistMutation,
  deletePlaylistMutation,
} from '../graphql';

export const addPlaylist = async (
  args: iAddPlaylistArgs,
  isLoading
): Promise<iPlaylistData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iAddPlaylistResponse>>(
      await Mutation(addPlaylistMutation, {
        ...args,
        duration: '00:00:00',
        screenType: ScreenType.HORIZONTAL,
      })
    );
    isLoading(false);
    return response.data.addPlaylist.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const getAllPlaylists = async (
  args: iGetPlaylistsArgs,
  isLoading
): Promise<Array<iPlaylistData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetPlayListByCompanyResponse>>(
      await Query(getPlayListByCompanyQuery, args)
    );
    isLoading(false);
    return response.data.getPlayListByCompany.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const getPlaylistsById = async (
  args: iGetPlaylistByIdArgs,
  isLoading
): Promise<iPlaylistData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iGetPlaylistByIdResponse>>(
      await Query(getPlaylistByIdQuery, args)
    );
    isLoading(false);
    return response.data.getplaylistbyid.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const updatePlaylist = async (
  args: iUpdatePlaylistArgs,
  isLoading
): Promise<iPlaylistData | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iUpdatePlaylistResponse>>(
      await Mutation(updatePlaylistMutation, args)
    );
    isLoading(false);
    return response.data.updatePlaylist.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const searchPlaylist = async (
  args: iSearchPlaylistArgs,
  isLoading
): Promise<Array<iPlaylistData> | null> => {
  try {
    isLoading(true);
    const response = <iGraphqlResponse<iSearchPlaylistResponse>>(
      await Mutation(searchPlaylistMutation, args)
    );
    isLoading(false);
    return response.data.searchPlaylistbyname.data;
  } catch (error: any) {
    isLoading(false);
    captureException(error);
    return null;
  }
};

export const deletePlaylist = async (
  args: iDeletePlaylistArgs,
  isLoading?: (_: boolean) => void
): Promise<string | null> => {
  try {
    if (isLoading) isLoading(true);
    const response = <iGraphqlResponse<iDeletePlaylistResponse>>(
      await Mutation(deletePlaylistMutation, {
        ...args,
      })
    );
    if (isLoading) isLoading(false);
    if (response) {
      if (
        response.data.deletePlaylist.message ===
        'This playlist is not mapped to any screen'
      ) {
        const deleteResponse = await deletePlaylist({ ...args, is_data: true });
        console.log('deleteResponse', deleteResponse);
        if (deleteResponse) return 'DELETED';
      }
      if (
        response.data.deletePlaylist.message ===
        'playlist mapped to few screens'
      )
        return 'USED_SOMEWHERE';
      if (response.data.deletePlaylist.message === 'Result') return 'DELETED';
    }
    return null;
  } catch (error: any) {
    if (isLoading) isLoading(false);
    captureException(error);
    return null;
  }
};
