import React from 'react';
import { AvatarVariants, Variants } from './variants';
import styles from './avatar.module.scss';

interface iProps {
  className?: string;
  variant: AvatarVariants;
  firstName?: string;
  lastName?: string;
  profilePic?: string;
  onClick?: () => void;
  style?: any;
  avatarCount?: number;
}

function Avatar(props: iProps): JSX.Element {
  const {
    className,
    variant = '',
    firstName = '',
    lastName = '',
    profilePic = '',
    style,
    avatarCount = 0,
    onClick,
  } = props;

  const { showAvatar, showAvatarWithName } = Variants[variant];
  console.log(variant);

  return (
    <div
      className={`${styles.avatar} ${styles[variant]}  ${className}`}
      style={style}
      onClick={onClick}
    >
      {avatarCount > 0 && (
        <p className={`${styles.initials} ${styles[variant]}`}>
          +{avatarCount}
        </p>
      )}

      {avatarCount === 0 &&
        (showAvatar && profilePic ? (
          <img
            className={`${styles.profilePic} ${styles[variant]}`}
            src={profilePic}
            alt=""
          />
        ) : (
          showAvatarWithName && (
            <p className={`${styles.initials} ${styles[variant]}`}>
              {(firstName && firstName[0]) || ''}
              {(lastName && lastName[0]) || ''}
            </p>
          )
        ))}
    </div>
  );
}

Avatar.defaultProps = {
  className: '',
  firstName: '',
  lastName: '',
  profilePic: '',
  style: null,
  onClick: () => {},
  avatarCount: 0,
};

export { AvatarVariants };
export default Avatar;
