import React, { useState, useCallback } from 'react';
import CheckMark from 'components/svg/checkMark';
import { twoFaAuthicon } from 'constants/icons';
import styles from './authTypes.module.scss';

interface iProps {
  className?: string;
  onClick: (_: any) => void;
}

enum AuthenticateBy {
  App = 'App',
  Email = 'Email',
}

// enum AuthentionTypes {
//   AuthenticatorApp = 'AuthenticatorApp',
//   Email = 'Email',
// }

function AuthTypes(props: iProps): JSX.Element {
  const { className, onClick } = props;

  const [authenticationType, setAuthenticationType] =
    useState<AuthenticateBy>();

  const isAuthenticatorApp = authenticationType === AuthenticateBy.App;
  const isEmail = authenticationType === AuthenticateBy.Email;

  const onClickAuthCard = useCallback(
    (authBy) => {
      setAuthenticationType(authBy);
      onClick(authBy);
    },
    [onClick]
  );

  return (
    <div className={`${styles.authTypeWrapper} ${className}`}>
      <div className={styles.sectionHeading}>
        <img className={styles.icon} src={twoFaAuthicon} alt="" />
        <div className={styles.sectionDescription}>
          <p className={styles.heading}>Add an extra layer of security</p>
          <p className={styles.description}>
            Keep your account safe and prevent anyone from accessing your
            screens or data by adding two-facor authentication.
          </p>
        </div>
      </div>
      <div className={styles.authenticationTypes}>
        <div
          className={`${styles.authenticationCard} ${
            isAuthenticatorApp && styles.active
          }`}
          onClick={() => onClickAuthCard(AuthenticateBy.App)}
        >
          <div className={styles.cardHeading}>
            <p className={styles.authenticateType}>Authenticator App</p>
            <p className={styles.recommendedTag}>Recommended</p>
          </div>
          <div className={styles.cardBody}>
            Use an authentication app to generate a time-based token (for
            example Google Authenticator, 1Password, or Authy)
          </div>
          <div
            className={`${styles.cardFooter} ${
              isAuthenticatorApp && styles.active
            }`}
          >
            <div
              className={`${styles.checkbox} ${
                isAuthenticatorApp && styles.active
              }`}
            >
              <CheckMark className={styles.checkIcon} />
            </div>
          </div>
        </div>

        <div
          className={`${styles.authenticationCard} ${isEmail && styles.active}`}
          onClick={() => onClickAuthCard(AuthenticateBy.Email)}
        >
          <div className={styles.cardHeading}>
            <p className={styles.authenticateType}>Email</p>
          </div>
          <div className={styles.cardBody}>
            Receive an email with a time-based token. **Do we need more text to
            understand this?
          </div>
          <div className={`${styles.cardFooter} ${isEmail && styles.active}`}>
            <div className={`${styles.checkbox} ${isEmail && styles.active}`}>
              <CheckMark className={styles.checkIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AuthTypes.defaultProps = {
  className: '',
};

export default AuthTypes;
