import { getCompaniesById } from 'apis';
import { iCompanyData, iState } from 'types';
import Button, { ButtonVariants } from 'components/button/button';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import SectionHeading from '../section-heading/sectionHeading';
import styles from './contactInfo.module.scss';

interface iProps {
  className?: string;
}

function ContactInformation(props: iProps): JSX.Element {
  const { className } = props;
  const [state]: [iState] = useStore();
  const { companyData } = state.homePage || {};
  const [companyEmail, setCompanyEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [disable, setDisable] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyInfo, setCompanyInfo] = useState<iCompanyData | null>(
    companyData
  );
  console.log({ isLoading });

  useEffect(() => {
    if (!companyData) return;
    (async () => {
      const response = await getCompaniesById(
        { id: companyData.id },
        setIsLoading
      );
      if (response !== null) {
        console.log({ response });
        setCompanyInfo(response);
      }
    })();
  }, [companyData]);

  useEffect(() => {
    if (companyEmail !== '' || phoneNumber !== '') {
      setDisable(false);
    }
  }, [companyEmail, phoneNumber]);

  return (
    <div className={`${styles.contactInfoWrapper} ${className}`}>
      <SectionHeading
        className={styles.sectionHeading}
        heading="Contact information"
      />
      <LabelAndInput
        className={styles.labelAndInput}
        label="Company email"
        placeholder={companyInfo?.email || ''}
        value={companyEmail}
        onChange={(e) => setCompanyEmail(e.target.value)}
        disabled
      />
      <LabelAndInput
        className={styles.labelAndInput}
        label="Company Phone number"
        placeholder={companyInfo?.phone_number || ''}
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        disabled
      />
      <div className={styles.btnsContainer}>
        <Button
          className={styles.saveChangeBtn}
          btnName="Save changes"
          variant={ButtonVariants.SmallStd}
          onClick={() => {}}
          isDisabled={disable}
        />
      </div>
    </div>
  );
}

ContactInformation.defaultProps = {
  className: '',
};

export default ContactInformation;
