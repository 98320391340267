export const validators = {
  isValidPhoneNumber: (number) => {
    return number.toString()?.length === 10;
  },
  isEmail: (email: string) => {
    const VALID_EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return VALID_EMAIL_REGEX.test(email);
  },
  isValidCountryCode: (countryCod: string) => {
    return countryCod === '+91' || countryCod === '+1';
  },
  isValidEnum: (Enum, value) => {
    return value.toUpperCase() in Enum;
  },
  validatePassword: (password: string) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/.test(
      password
    );
  },
};
