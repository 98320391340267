import React, { useState, useCallback } from 'react';
import Button, { ButtonVariants } from 'components/button/button';
import Screen from 'components/svg/screenz';
import { iState } from 'types';
import { toaster } from 'helpers';
import { useStore } from 'store';
import { contentPageActions } from 'store/actions';
import Popover from 'components/popover/popover';
import PortraitScren from 'components/svg/portraitScren';
import { caretFilledSmallDown } from 'constants/index';
import styles from './sidebar.module.scss';

interface iProps {
  className?: string;
  openWebSite: () => void;
}

enum Orientations {
  Landscap = 'Landscap',
  Portrait = 'Portrait',
}

const refreshIntervals = [
  { id: '1', role: 'Do Not Reload' },
  { id: '2', role: 'Every 5 minutes' },
  { id: '3', role: 'Every 30 minutes' },
  { id: '4', role: 'Every hour' },
  { id: '3', role: 'Every 6 hours' },
  { id: '3', role: 'Every 12 hours' },
];

const expression =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
const regex = new RegExp(expression);

function Sidebar(props: iProps): JSX.Element {
  const { className, openWebSite } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { addWebContentData } = state.contentPage;
  const [selectedOrientation, setSelectedOrientation] = useState<Orientations>(
    Orientations.Landscap
  );

  const { url, webSiteName } = addWebContentData || {};
  const isLandscape = selectedOrientation === Orientations.Landscap;
  const isPortrait = selectedOrientation === Orientations.Portrait;

  const switchOrientation = useCallback(() => {
    if (selectedOrientation === Orientations.Landscap)
      setSelectedOrientation(Orientations.Portrait);
    if (selectedOrientation === Orientations.Portrait)
      setSelectedOrientation(Orientations.Landscap);
  }, [selectedOrientation]);

  const onClickOpenWebite = useCallback(() => {
    if (url) {
      if (!url.match(regex)) {
        toaster.error(`${url} is not a valid url`);
        return;
      }
      openWebSite();
    }
  }, [url, openWebSite]);

  const onChange = useCallback(
    (event) => {
      const data = addWebContentData || { url: '', webSiteName: '' };
      console.log({ name: event.target.name, value: event.target.value, data });
      if (data) {
        data[event.target.name] = event.target.value;
        dispatch(
          contentPageActions.setAddWebContentData({
            ...data,
            isLoadableInIFrame: false,
          })
        );
      }
    },
    [addWebContentData, dispatch]
  );

  return (
    <div className={`${styles.addWebContentSidebar} ${className}`}>
      <div className={styles.sidebarHeader}>Settings</div>
      <div className={styles.sidebarMainSection}>
        <div className={styles.primaryDetailsContainer}>
          <p className={styles.urlLabel}>URL</p>
          <input
            className={styles.urlInput}
            name="url"
            placeholder="Enter website URL"
            type="text"
            value={url}
            onChange={onChange}
          />
          <Button
            className={styles.openWebsiteBtn}
            btnName="Open website"
            variant={ButtonVariants.SmallStd}
            onClick={onClickOpenWebite}
          />

          <p className={styles.urlLabel}>Give the website a name</p>
          <input
            className={styles.urlInput}
            name="webSiteName"
            placeholder="Name"
            type="text"
            value={webSiteName}
            onChange={onChange}
          />
        </div>
        <hr />
        <div className={styles.sizeAndScreenType}>
          <p className={styles.sectionHeading}>Size</p>

          <div className={styles.dropdownContainer}>
            <p className={styles.choosedOption}>Landscape HD Screen</p>
            <img
              className={styles.dropdownIcon}
              src={caretFilledSmallDown}
              alt=""
            />
          </div>

          <div className={styles.resolutionContainer}>
            <div className={styles.widthContainer}>
              <p>W</p>
              <input
                className={styles.resolutionInput}
                placeholder=""
                type="text"
                value="1920"
              />
            </div>
            <div className={styles.heightContainer}>
              <p>H</p>
              <input
                className={styles.resolutionInput}
                placeholder=""
                type="text"
                value="1080"
              />
            </div>
            <div className={styles.deviceTypes} onClick={switchOrientation}>
              <span
                className={`${styles.activeIndicator} ${
                  isLandscape && styles.landscape
                } ${isPortrait && styles.portrait}`}
              />
              <Screen
                className={`${styles.screen} ${isLandscape && styles.active}`}
              />
              <PortraitScren
                className={`${styles.portrait} ${isPortrait && styles.active}`}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className={styles.refreshContainer}>
          <p className={styles.refreshHeading}>Automatic Refresh</p>
          <Popover
            popoverClassName={styles.refreshPopover}
            popoverComponent={
              <div className={styles.options}>
                {refreshIntervals.map((i) => (
                  <p className={styles.option}>{i.role}</p>
                ))}
              </div>
            }
          >
            <div className={styles.dropdownContainer}>
              <p className={styles.choosedOption}>Do not refresh</p>
              <img
                className={styles.dropdownIcon}
                src={caretFilledSmallDown}
                alt=""
              />
            </div>
          </Popover>
        </div>
        <hr />
      </div>
    </div>
  );
}

Sidebar.defaultProps = {
  className: '',
};

export default Sidebar;
