import Button, { ButtonVariants } from 'components/button/button';
import ClientNameAndFolderPath from 'components/client-name-and-folder-path/clientNameAndFolderPath';
import Popover from 'components/popover/popover';
import Search from 'components/search/search';
import {
  cloudUpload2Icon,
  greyAddIcon,
  RESPONSIVE_WINDOW_WIDTH,
  ROUTES,
  WebIcon,
  whiteAddIcon,
} from 'constants/index';
import { LayoutViews, ModalViews, UserRoles } from 'enums';
import { uuid } from 'helpers';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import ViewSwitchButton from '../view-switch-button/viewSwitchButton';
import styles from './subHeader.module.scss';

interface iProps {
  className?: string;
}

const CONTENT_ADD_OPTIONS = [
  {
    id: uuid(),
    icon: WebIcon,
    option: 'Add webpage',
    action: 'addWebPage',
  },
  {
    id: uuid(),
    icon: cloudUpload2Icon,
    option: 'Upload Files',
    action: 'addFiles',
  },
];

function SubHeader(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { layout, user, homePage } = state || {};
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isShowingClientInstance } = layout || {};
  const { companyData } = homePage || {};

  const onClick = useCallback(() => {
    if (pathname === ROUTES.playlists) {
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddPlaylist));
      return;
    }
    if (
      pathname === ROUTES.home &&
      user.role === UserRoles.SuperAdmin &&
      !isShowingClientInstance
    )
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddClient));
    if (pathname === ROUTES.home && user.role === UserRoles.Reseller)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddClient));
    if (
      (pathname === ROUTES.home && user.role === UserRoles.Client) ||
      isShowingClientInstance
    )
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddScreen));
  }, [dispatch, user, pathname, isShowingClientInstance]);

  const addFolder = useCallback(() => {
    dispatch(layoutActions.toggleModal(ModalViews.isShowAddFolder));
  }, [dispatch]);

  const addWebPage = useCallback(() => {
    if (pathname === ROUTES.content)
      // dispatch(layoutActions.toggleModal(ModalViews.isShowAddWebContent));
      navigate(ROUTES.addWebpage);
    // dispatch(layoutActions.toggleModal(ModalViews.isShowAddContent));
  }, [pathname, navigate]);

  const addFiles = useCallback(() => {
    if (pathname === ROUTES.content)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddContent));
  }, [dispatch, pathname]);

  const addButtons = (pathname === ROUTES.content && CONTENT_ADD_OPTIONS) || [];

  const isSuperUserAdmin = user.role === UserRoles.SuperAdmin;
  const isClient = user.role === UserRoles.Client;
  const isResellerAdmin = user.role === UserRoles.Reseller;

  const username =
    companyData?.name ||
    (pathname === ROUTES.playlists && 'Client Name') ||
    (pathname === ROUTES.content && 'Bykle') ||
    (isClient && 'Client Name') ||
    (isResellerAdmin && 'Welcome Reseller Name') ||
    'Client Name';

  // const openFolder = useCallback(
  //   (folder) => {
  //     dispatch(homePageActions.setActiveFolder(folder));
  //   },
  //   [dispatch]
  // );

  const isShowAddFolder =
    isClient ||
    isShowingClientInstance ||
    (isSuperUserAdmin && pathname !== ROUTES.home) ||
    false;

  return (
    <div className={`${className} ${styles.subheader}`}>
      {layout.windowWidth > RESPONSIVE_WINDOW_WIDTH ? (
        <>
          <div className={styles.left}>
            {(isSuperUserAdmin && !isShowingClientInstance && (
              <>
                <ViewSwitchButton variant={LayoutViews.SuperAdminClients} />
                {/* <ViewSwitchButton variant={LayoutViews.SuperAdminResellers} /> */}
              </>
            )) ||
              (username && <ClientNameAndFolderPath username={username} />)}
          </div>
          <div className={styles.right}>
            <Search className={styles.searchField} isShowClear />
            {isShowAddFolder && (
              <Button
                className={styles.folderBtn}
                variant={ButtonVariants.SmallTransp}
                btnName="Folder"
                icon={greyAddIcon}
                onClick={addFolder}
              />
            )}
            {pathname === ROUTES.content ? (
              <Popover
                popoverClassName={styles.popoverWrapper}
                popoverComponent={
                  <div className={`${styles.addDropdown} ${className}`}>
                    {addButtons.map((i) => (
                      <div
                        className={styles.option}
                        onClick={() => {
                          if (i.action === 'addWebPage') addWebPage();
                          if (i.action === 'addFiles') addFiles();
                        }}
                      >
                        <img src={i.icon} alt="" />
                        <span>{i.option}</span>
                      </div>
                    ))}
                  </div>
                }
              >
                <Button
                  className={`${styles.DropdownBtn}`}
                  variant={ButtonVariants.SmallStd}
                  btnName="Content"
                  icon={whiteAddIcon}
                  iconPosition="left"
                />
              </Popover>
            ) : (
              <Button
                className={styles.screenzBtn}
                variant={ButtonVariants.SmallStd}
                btnName={
                  (pathname === ROUTES.home &&
                    isShowingClientInstance &&
                    'Screen') ||
                  (pathname === ROUTES.playlists && 'Playlist') ||
                  (pathname === ROUTES.content && 'Content') ||
                  (isSuperUserAdmin && 'Client') ||
                  (isClient && 'Screen') ||
                  (isResellerAdmin && 'Client') ||
                  ''
                }
                icon={whiteAddIcon}
                onClick={onClick}
              />
            )}
          </div>
        </>
      ) : (
        <Search isOpenState className={styles.searchField} />
      )}
    </div>
  );
}

SubHeader.defaultProps = {
  className: '',
};

export default SubHeader;
