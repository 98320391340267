export const ON_BOARDING = {
  loginData: 'loginData',
  set2FAData: 'set2FAData',
};

export const LAYOUT = {
  setWindowWidth: 'setWindowWidth',
  invokeGetAllFolders: 'invokeGetAllFolders',
  invokeGetFolderById: 'invokeGetFolderById',
  setHomePageView: 'setHomePageView',
  openAddContentModal: 'openAddContentModal',
  openFilterContentModal: 'openFilterContentModal',
  openAddWebContentModal: 'openAddWebContentModal',
  toggleSideDrawer: 'toggleSideDrawer',
  initialSidebarState: 'initialSidebarState',
  setShowClientsInstance: 'setShowClientsInstance',
  toggleModal: 'toggleModal',
  setAssignModalContentFolders: 'setAssignModalContentFolders',
  setAssignModalContentActiveFolders: 'setAssignModalContentActiveFolders',
  setAssignModalPlaylistActiveFolder: 'setAssignModalPlaylistActiveFolder',
  setAssignModalContentsFolderHierarchy:
    'setAssignModalContentsFolderHierarchy',
  setEmptyStates: 'setEmptyStates',
};

export const HOME_PAGE = {
  setScreenData: 'setScreenData',
  updateScreenData: 'updateScreenData',
  addScreen: 'addScreen',
  fetchScreensData: 'fetchScreensData',
  setScreensFolderHierarchy: 'setScreensFolderHierarchy',
  addFolder: 'addFolder',
  invokeGetCompanies: 'invokeGetCompanies',
  screenzId: 'screenzId',
  setCompanies: 'setCompanies',
  searchCompanyKey: 'searchCompanyKey',
  searchScreenKey: 'searchScreenKey',
  setCompanyData: 'setCompanyData',
  screenOrderChange: 'screenOrderChange',
  addSuperUserClient: 'addSuperUserClient',
  addSuperUserResellerClient: 'addSuperUserResellerClient',
  addResellerClient: 'addResellerClient',
  setActiveFolder: 'setActiveFolder',
  setActiveClientInstanceData: 'setActiveClientInstanceData',
  setScreensFolders: 'setScreensFolders',
  setScreensActiveFolder: 'setScreensActiveFolder',
  setContentToAssignToScreen: 'setContentToAssignToScreen',
  setPlaylistToAssignToScreen: 'setPlaylistToAssignToScreen',
  invokeUpdateScreen: 'invokeUpdateScreen',
  invokeGetScreenById: 'invokeGetScreenById',
};

export const CONTENT_PAGE = {
  setUploadingFiles: 'setUploadingFiles',
  setContentView: 'setContentView',
  setContents: 'setContents',
  fetchContentsData: 'fetchContentsData',
  updateUploadingStatus: 'updateUploadingStatus',
  removeFile: 'removeFile',
  setAddWebContentData: 'setAddWebContentData',
  clearUploadingFiles: 'clearUploadingFiles',
  emptyState: 'emptyState',
  setContentFolderHierarchy: 'setContentFolderHierarchy',
  showCancelAndSaveBtns: 'showCancelAndSaveBtns',
  resetEmptyAndBtns: 'resetEmptyAndBtns',
  setSearchContentKey: 'setSearchContentKey',
  setContentFolders: 'setContentFolders',
  setContentActiveFolder: 'setContentActiveFolder',
};
export const PLAYLIST_PAGE = {
  addPlaylist: 'addPlaylist',
  setPlaylists: 'setPlaylists',
  setPlaylistData: 'setPlaylistData',
  addContentToPlaylist: 'addContentToPlaylist',
  clearAddContentToPlaylist: 'clearAddContentToPlaylist',
  fetchPlaylistsData: 'fetchPlaylistsData',
  invokeUpdatePlaylist: 'invokeUpdatePlaylist',
  updatePlaylist: 'updatePlaylist',
  setSearchPlaylistKey: 'setSearchPlaylistKey',
  setPlaylistsFolders: 'setPlaylistsFolders',
  setPlaylistsActiveFolder: 'setPlaylistsActiveFolder',
  setPlaylistsFolderHierarchy: 'setPlaylistsFolderHierarchy',
  setContentView: 'setContentView',
  setPlaylistPreview: 'setPlaylistPreview',
  toggleLlaylistSaveAndCloseBtn: 'toggleLlaylistSaveAndCloseBtn',
};

export const COMMON = {
  reset: 'reset',
};

export const USER = {
  setLoginData: 'setLoginData',
  changeView: 'changeView',
  setAllRoles: 'setAllRoles',
  setResetPasswordData: 'setResetPasswordData',
  updateLoggedInUserData: 'updateLoggedInUserData',
  setRemoveTeammate: 'setRemoveTeammate',
  set2FAData: 'set2FAData',
};
