// import screenDetailScreenShot from 'assets/images/screen-detail-screenshot.png';
// import screenDetailPortaitScreenShot from 'assets/images/screen-details-portrai-screenshot.png';
import { cameraIcon } from 'constants/index';
import React, { useCallback } from 'react';
import styles from './screenshot.module.scss';

interface iProps {
  className?: string;
  screenShotTime?: string;
  src?: string;
}

function Index(props: iProps): JSX.Element {
  const { className, src, screenShotTime } = props;

  const timeSince = useCallback((date) => {
    const currentDate: any = new Date();
    const pastDate: any = new Date(date);
    const seconds: number = Math.floor((currentDate - pastDate) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
      return `${Math.floor(interval)} years`;
    }
    interval = seconds / 2592000;

    if (interval > 1) {
      return `${Math.floor(interval)} months`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return `${Math.floor(interval)} days`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return `${Math.floor(interval)} hours`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return `${Math.floor(interval)} minutes`;
    }
    return `${Math.floor(seconds)} seconds`;
  }, []);

  return (
    <div className={`${styles.screenshotWrapper} ${className}`}>
      <div className={styles.nameAndTime}>
        <img src={cameraIcon} alt="" />
        <p>Screenshot</p>
        {screenShotTime && (
          <p>{`Player output ${timeSince(screenShotTime)} ago`}</p>
        )}
      </div>
      <img className={styles.screenshot} src={src || ''} alt="" />
    </div>
  );
}

Index.defaultProps = {
  className: '',
  src: '',
  screenShotTime: '',
};

export default Index;
